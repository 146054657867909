.patientProfileDiv1 {
  background-image: url("../../../assets/img/newDashBoard.svg");
  background-position: center;

  background-repeat: no-repeat;

  max-width: 1280px;
  height: 220px;
  margin-bottom: 30px;
  margin-top: 40px;
}

.patient_heading {
  margin-top: 32px;
  margin-left: 90px;
  font-family: "Roboto";
  color: #3d446f;
  font-weight: 700;
}

.userIconProfile {
  width: 160px;
  margin-left: 30px;
  padding-top: 20px;
}

.userIconProfileImg {
  width: 120px;
  margin-left: 30px;
  padding-top: 20px;
  border-radius: 50%;
}

.intoIcon {
  margin-top: 32px;
  margin-right: 85px;
}

.patientProfileID {
  color: #6a768f;
  font-size: 12px;
  font-family: "Roboto";
}

.patientName {
  color: #3d446f;
  font-size: 16px;
  margin-top: 40px;
  font-family: "Roboto";
  font-weight: 800;
}

.MCdiv {
  color: #6a768f;
  font-size: 12px;
  font-family: "Roboto";
  margin-left: 16px;
  align-self: center;
  cursor: pointer;
  font-weight: 700;
}

.PIdiv {
  color: #6a768f;
  font-size: 12px;
  font-family: "Roboto";
  margin-left: 16px;
  align-self: center;
  cursor: pointer;
  font-weight: 700;
}

.adheranceScoreDiv {
  color: #3d446f;
  font-size: 18px;
  font-family: "Roboto";
  font-weight: 800;
  padding-top: 16px;
  margin-left: 80px;
}

.adheranceScoreDiv1 {
  color: #3d446f;
  font-size: 18px;
  font-family: "Roboto";
  font-weight: 800;
  padding-top: 16px;
}

.adheranceScoreDiv2 {
  margin-top: 40px;
  background-color: white;
  background-repeat: no-repeat;
  margin-bottom: 30px;
  position: relative;
  border-radius: 10px;
}

.dateDiv {
  color: #f26930;
  font-family: "Roboto";
  font-size: 12px;
  font-weight: 800;
  text-align: center;
}

.todayScore {
  color: #6a768f;
  font-size: 24px;
  font-family: "Roboto";
  font-weight: 800;
  text-align: center;
  position: relative;
  top: -47px;
  margin-bottom: 16px;
  margin-right: 37px;
}

.weeks {
  display: flex;
  justify-content: space-between;
}

.adherance-score-history {
  color: #3d446f;
  font-size: 18px;
  font-family: "Roboto";
  font-weight: 800;
  margin-left: 80px;
  margin-top: 32px;
}

.select-duration {
  color: #3d446f;
  font-size: 16px;
  font-family: "Roboto";
  font-weight: 800;
  margin-left: 80px;
  margin-top: 16px;
}

.active-inactive-content {
  color: #3d446f;
  font-size: 16px;
  font-family: "Roboto";
  font-weight: 800;
}

.active-content {
  color: #c5c82f;
  font-size: 16px;
  font-family: "Roboto";
  font-weight: 800;
}

.setButton {
  padding: 10px 20px !important;
  background: #fff !important;
  outline: none !important;
  box-shadow: #6a768f80 0px 3px 6px;
}

.setButton i {
  font-size: 12px;
  border: 2px solid #3d446f;
  padding: 6px 4px;
  border-radius: 50%;
}

.setButton span {
  font-weight: 500;
  font-size: 16px;
  color: #3d446f;
  margin-left: 10px;
  font-family: "Roboto";
  text-transform: initial;
}

.activeTheorpyCard {
  background-color: #fff;
  margin: 24px auto;
  height: 285px;
  border-radius: 5px;
  box-shadow: #6a768f80 0px 3px 6px;
  position: relative;
}

.headingCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tophedingMiddleText {
  position: absolute;
  top: -28px;
  left: 27%;
}

.present h1 {
  font-size: 32px;
  margin-top: 40px;
  color: #f26930;
  font-weight: 600;
  font-family: "Roboto";
}

.present p {
  font-size: 24px;
  color: #3d446f;
  font-weight: 500;
}

.present span {
  color: #6a768f;
  font-size: 16px;
}

.theorpyPerDay {
  position: absolute;
  left: 68%;
  top: 77px;
}

.theorpyPerDay input {
  width: 100px;
  text-align: center;
  background: #f2f4f8;
  border-radius: 7px;
  font-size: 20px;
  color: #3d446f;
  font-weight: 500;
  border: none;
  margin: 0px 10px;
}

.theorpyPerDay label {
  font-size: 16px;
  color: #6a768f;
  font-weight: 500;
}

.middleCard {
  position: absolute;
  top: 126px;
  border-top: 1px solid #d5d5d5;
}

.bottomCard {
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 20px;
  background-color: #6a768f38;
  text-align: center;
}

.bottomCard p {
  color: #6a768f;
  font-size: 16px;
  font-family: "Roboto";
}

.activeTop {
  position: absolute;
  top: 31px;
  left: 20px;
}

.setTheorypFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  box-shadow: #6a768f80 0px 3px 6px;
  padding: 10px 18px 10px 18px;
  border-radius: 5px;
}

.download-adherencescore-histroy-wrapper {
  display: flex;
  align-items: center;
}

.download-adherencescore-histroy-wrapper > span {
  padding-right: 15px;
  color: rgb(117 117 120);
  font-weight: 600;
}

.download-adherencescore-histroy-wrapper .pdfimage {
  width: 21px;
  margin-right: 5px;
  cursor: pointer;
}

.download-adherencescore-histroy-wrapper .excelIcon {
  width: 30px;
  cursor: pointer;
}

.Msg {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.Msg p {
  text-transform: capitalize;
  margin-left: 10px;
  color: rgb(117 117 120);
  font-weight: 600;
}

.notes p {
  color: rgb(117 117 120);
  font-weight: 600;
  text-transform: capitalize;
  margin-left: 10px;
  /*  color: #36434b; */
}

.footerIcon {
  font-size: 10px;
  padding: 5px;
  background-color: #fff;
  border-radius: 7px;
  box-shadow: #6a768f80 0px 3px 6px;
}

.notes {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.css-1knalkq {
  width: 31px !important;
  height: 30px !important;
}

.area-graph {
  background-color: white;
  margin-left: 72px;
  box-shadow: #6a768f80 0px 3px 6px;
  border-radius: 5px;
  margin-top: 24px;
  margin-right: 80px;
  margin-bottom: 32px;
}
.area-graph1 {
  background-color: white;
  margin-left: 42px;
  box-shadow: #6a768f80 0px 3px 6px;
  border-radius: 5px;
  margin-top: 24px;
  margin-right: 32px;
  margin-bottom: 32px;
}

.area-graph-innerdiv {
  color: #3d446f;
  font-size: 16px;
  font-family: "Roboto";
  font-weight: 800;
  margin-left: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
}

.hmr-graph-heading {
  color: #3d446f;
  font-size: 18px;
  font-family: "Roboto";
  font-weight: 800;
  margin-left: 80px;
  margin-top: 24px;
}

.inactiveImgDiv {
  background-image: url("../../../assets/img/patient_profile/therapygoal.png");
  width: 218px;
  height: 173px;
  top: -32px;
  left: -0px;
  border-radius: 5px;
  background-position: center;
  background-size: cover;
  position: absolute;
  background-repeat: no-repeat;
}

.inactivTherapyCard {
  background-color: #fff;
  margin: 24px auto;
  height: 285px;
  border-radius: 5px;
  box-shadow: #6a768f80 0px 3px 6px;
  position: relative;
}

.activecontainer {
  width: 98.1%;
  position: relative;
  margin: 2px auto;
  background: transparent;
  text-align: left;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  vertical-align: middle;
}

.activecard {
  display: inline-block;
  width: 98.1%;
  height: 241px;
  margin: 0.5em;
  padding: 3px;
  white-space: normal;
  text-align: center;
  background-color: white;
  box-shadow: #6a768f80 0px 3px 6px;
  border-radius: 5px;
}

.activediv2 {
  display: inline-block;
  width: 100%;
  height: 200px;
  margin: 0.5em;
  padding: 3px;
  white-space: normal;
  text-align: center;
  background-color: white;
  box-shadow: #6a768f80 0px 3px 6px;
  border-radius: 5px;
}

.borderInTherapycard {
  border-top: 1px solid #aaaaac2b;
}

.ActivetherapyDate {
  font-size: 24px;
  font-family: "Roboto";
  font-weight: 800;
  color: #f26c33;
  text-align: left;
  margin-top: 10px;
}

.inActiveTherapyName {
  font-size: 16px;
  font-family: "Roboto";
  font-weight: 800;
  text-align: left;
  margin-top: 16px;
  color: #5c6286;
}

.ActiveTheropyName {
  font-size: 20px;
  font-family: "Roboto";
  font-weight: 800;
  text-align: left;
  margin-top: 16px;
  color: #5c6286;
}

.inActiveTherapyID {
  font-family: "Roboto";
  font-size: 8px;
  font-weight: 800;
  color: #5c6286;
  margin-top: 8px;
}

.activeTheorpyId {
  font-family: "Roboto";
  font-size: 10px;
  font-weight: 800;
  color: #6a768f;
  margin-top: 8px;
}

.inActiveTherapyNum {
  font-family: "Roboto";
  padding: 5px 8px 5px 8px;
  font-size: 12px;
  color: #5c6286;
  align-self: center;
  background-color: #f2f4f8;
  border-radius: 5px;
  font-weight: 800;
  box-shadow: #6a768f80 0px 3px 6px;
}

.ActiveTherapyNum {
  font-family: "Roboto";
  padding: 6px 20px 6px 20px;
  font-size: 18px;
  color: #5c6286;
  align-self: center;
  background-color: #f2f4f8;
  border-radius: 5px;
  font-weight: 800;
  box-shadow: #6a768f80 0px 3px 6px;
}

.ActiveTherapyGoals {
  font-family: "Roboto";
  font-size: 14px;
  align-self: center;
  color: #929bae;
  font-weight: 500;
  margin-left: 8px;
}

.weeksInactiveDiv {
  display: flex;
  justify-content: space-between;
  margin-left: 16px;
  margin-right: 16px;
}

.weeksactiveDiv {
  display: flex;
  justify-content: space-between;
  margin-left: 16px;
  margin-right: 16px;
  margin-bottom: 16px;
}

.inActiveWeeks {
  font-size: 12px;
  font-family: "Roboto";
  font-weight: 800;
  color: #667177;
}

.ActiveBackGround {
  background-image: url("../../../assets//img/patient_profile//therapygoal.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 133px;
  width: 250px;
  margin-left: -4px;
  position: relative;
}

.inActiveBackGround {
  background-image: url("../../../assets/img/active.svg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100px;
  width: 200px;
}

.activeIcon {
  position: relative;
  left: 16px;
  top: -5px;
}

.inactiveIcon {
  position: relative;
  left: 16px;
}

.therapyGoalHistory {
  color: #3d446f;
  font-size: 18px;
  font-family: "Roboto";
  font-weight: 800;
  margin-left: 80px;
}

.activeText {
  position: absolute;
  font-size: 24px;
  font-family: "Roboto";
  font-weight: 800;
  color: #000;
  top: 50px;
  background: #fff;
  width: 100px;
  height: 29px;
  left: 47px;
  text-align: center;
  align-items: center;
}

.inactiveText {
  font-size: 24px;
  font-family: "Roboto";
  font-weight: 800;
  border-radius: 10px;
  color: #bfc1d0;
}

.area-graph {
  background-color: white;
  margin-left: 72px;
  box-shadow: #6a768f80 0px 3px 6px;
  border-radius: 5px;
  margin-top: 24px;
  margin-right: 69px;
}

.area-graph-innerdiv {
  color: #3d446f;
  font-size: 16px;
  font-family: "Roboto";
  font-weight: 800;
  margin-left: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
}

.trend-graph-heading {
  color: #3d446f;
  font-size: 16px;
  font-family: "Roboto";
  font-weight: 700;
  margin-left: 20px;
  margin-top: 24px;
}
.therapy_details_graph_heading_part3 {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 0px 20px 0px 20px;
  overflow-x: scroll;
  }

.inactiveImgDiv {
  background-image: url("../../../assets/img/inactive.svg");
  width: 218px;
  height: 173px;
  top: -32px;
  left: -0px;
  border-radius: 5px;
  background-position: center;
  background-size: cover;
  position: absolute;
  background-repeat: no-repeat;
}

.inactivTherapyCard {
  background-color: #fff;
  margin: 24px auto;
  height: 285px;
  border-radius: 5px;
  box-shadow: #6a768f80 0px 3px 6px;
  position: relative;
}

.activecontainer {
  position: relative;
  margin: 2px auto;
  background: transparent;
  text-align: left;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  vertical-align: middle;
}

.activediv2 {
  display: inline-block;
  width: 500px;
  height: 200px;
  margin: 0.5em;
  padding: 3px;
  white-space: normal;
  text-align: center;
  background-color: white;
  box-shadow: #6a768f80 0px 3px 6px;
  border-radius: 5px;
}

.borderInTherapycard {
  border-top: 1px solid #aaaaac2b;
}

.inActivetherapyDate {
  font-size: 22px;
  font-family: "Roboto";
  font-weight: 800;
  color: #f26c33;
  text-align: left;
  margin-top: 10px;
}

.inActiveTherapyName {
  font-size: 16px;
  font-family: "Roboto";
  font-weight: 800;
  text-align: left;
  margin-top: 16px;
  color: #5c6286;
}

.inActiveTherapyID {
  font-family: "Roboto";
  font-size: 8px;
  font-weight: 800;
  color: #5c6286;
  margin-top: 8px;
  text-overflow: ellipsis;
  max-width: 60%;
  white-space: nowrap;
  overflow: hidden;
  text-align: left;
}

.inActiveTherapyNum {
  font-family: "Roboto";
  padding: 5px 8px 5px 8px;
  font-size: 12px;
  color: #5c6286;
  align-self: center;
  background-color: #f2f4f8;
  border-radius: 5px;
  font-weight: 800;
  box-shadow: #6a768f80 0px 3px 6px;
}

.inActiveTherapyGoals {
  font-family: "Roboto";
  font-size: 12px;
  align-self: center;
  color: #929bae;
  margin-left: 8px;
}

.weeksInactiveDiv {
  display: flex;
  justify-content: space-between;
  margin-left: 16px;
  margin-right: 16px;
}

.inActiveWeeks {
  font-size: 12px;
  font-family: "Roboto";
  font-weight: 800;
  color: #667177;
}

.inActiveBackGround {
  background-image: url("../../../assets/img/patient_profile/therapygoal.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100px;
  width: 200px;
}

.inactiveIcon {
  /* margin-left: 16px; */
  position: relative;
  left: 16px;
}

.therapyGoalHistory {
  color: #3d446f;
  font-size: 18px;
  font-family: "Roboto";
  font-weight: 800;
  margin-left: 80px;
}

.inactiveText {
  font-size: 24px;
  font-family: "Roboto";
  font-weight: 800;
  border-radius: 10px;
  color: #bfc1d0;
}

.inActivebottomCard {
  font-size: 12px;
  font-family: "Roboto";
  margin-top: 8px;
  background-color: #f2f4f8;
  color: #555b81;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding-top: 8px;
  padding-bottom: 5px;
  font-weight: bold;
}

.area-graph-lastdiv {
  color: #3d446f;
  font-size: 16px;
  font-family: "Roboto";
  font-weight: 800;
  padding-top: 8px;
  padding-bottom: 8px;
  text-align: right;
  margin-right: 16px;
}

@media screen and (max-width: 768px) {
  .patient_heading {
    margin-top: 180px;
  }
}

.AdherenceElementWeeksNew-Wrapper {
  margin-left: 24px;
  margin-right: 24px;
  /* padding-bottom: 16px; */
  /* overflow-x: auto; */
  position: relative;
  bottom: 30px;
  /* overflow-x: auto; */
}

.editiconWrapper {
  margin-left: 16px;
  cursor: pointer;
}

.patientDetailsWrapper {
  margin-left: 40px;
}

.PatientProfileFooterWrapper {
  display: flex;
  padding-top: 16px;
  background-color: white;
  padding-bottom: 16px;
}

.personalinfoWrapper {
  display: flex;
  justify-content: flex-end;
  margin-right: 56px;
}

.MIIcon {
  display: flex;
  justify-content: flex-end;
}

.switchButtonWrapper {
  margin-top: 36px;
}

.activeCardWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media screen and (max-width: 880px) {
  .userIconProfile {
    width: 100px;
  }

  .patientProfileDiv1 {
    height: 176px;
  }

  .activeCardWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .activeTheorpyId {
    margin-left: -113px;
    margin-bottom: 5px;
  }
}

@media screen and (max-width: 600px) {
  .MIIcon {
    width: 203px;
  }

  .personalinfoWrapper {
    width: 200px;
  }
}

@media screen and (max-width: 593px) {
  .PatientProfileFooterWrapper {
    display: flex;
    flex-direction: column;
  }

  .MIIcon {
    width: 200px;
  }

  .personalinfoWrapper {
    width: 200px;
    margin-left: 18px;
    margin-top: 8px;
  }

  .patientProfileDiv1 {
    height: 180px;
  }
}

@media screen and (max-width: 570px) {
  .MIIcon {
    width: 187px;
  }

  .personalinfoWrapper {
    width: 170px;
    margin-left: 35px;
    margin-top: 8px;
  }

  .activeText {
    left: 28px;
  }
}

@media screen and (max-width: 520px) {
  .userIconProfile {
    width: 62px;
  }

  .patientProfileDiv1 {
    height: 150px;
  }

  .editiconWrapper {
    margin-left: 9px;
  }

  .MIIcon {
    width: 155px;
  }

  .personalinfoWrapper {
    width: 160px;
    margin-left: 13px;
    margin-top: 8px;
  }

  .ActiveBackGround {
    height: 206px;
  }

  .activeText {
    left: 20px;
  }
}

@media screen and (max-width: 510px) {
  .setTheorypFooter {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .footerIcon {
    margin-top: 14px;
  }

  .notes {
    margin-left: -25px;
    margin-top: 8px;
  }
}

@media screen and (max-width: 500px) {
  .userIconProfile {
    width: 58px;
  }

  .patientProfileDiv1 {
    height: 145px;
  }

  .editiconWrapper {
    margin-left: 9px;
  }

  .patientDetailsWrapper {
    margin-left: 16px;
  }
}

@media screen and (max-width: 449px) {
  .userIconProfile {
    width: 50px;
  }

  .patientProfileDiv1 {
    height: 145px;
  }

  .editiconWrapper {
    margin-left: 9px;
  }

  .patientDetailsWrapper {
    margin-left: 12px;
  }

  .patientName {
    font-size: 12px;
  }
}

@media screen and (max-width: 460px) {
  .userIconProfile {
    width: 50px;
  }

  .patientProfileDiv1 {
    height: 145px;
  }

  .editiconWrapper {
    margin-left: 9px;
  }

  .patientDetailsWrapper {
    margin-left: 12px;
  }

  .patientName {
    font-size: 12px;
    margin-top: 24px;
  }

  .patientProfileID {
    font-size: 10px;
  }
}

@media screen and (max-width: 430px) {
  .userIconProfile {
    width: 40px;
  }

  .patientProfileDiv1 {
    height: 145px;
  }

  .editiconWrapper {
    margin-left: 9px;
  }

  .patientDetailsWrapper {
    margin-left: 12px;
  }

  .patientName {
    font-size: 12px;
    margin-top: 24px;
  }

  .patientProfileID {
    font-size: 10px;
  }
}

@media screen and (max-width: 415px) {
  .userIconProfile {
    width: 35px;
  }

  .patientProfileDiv1 {
    height: 145px;
  }

  .editiconWrapper {
    margin-left: 4px;
  }

  .patientDetailsWrapper {
    margin-left: 12px;
  }

  .patientName {
    font-size: 12px;
    margin-top: 24px;
  }

  .patientProfileID {
    font-size: 10px;
  }
}

@media screen and (max-width: 398px) {
  .userIconProfile {
    width: 30px;
  }

  .patientProfileDiv1 {
    height: 145px;
  }

  .editiconWrapper {
    margin-left: 4px;
  }

  .patientDetailsWrapper {
    margin-left: 12px;
  }

  .patientName {
    font-size: 10px;
    margin-top: 24px;
  }

  .patientProfileID {
    font-size: 8px;
  }

  .switchButtonWrapper {
    margin-top: 28px;
  }
}

@media screen and (max-width: 364px) {
  .userIconProfile {
    width: 26px;
  }

  .patientProfileDiv1 {
    height: 145px;
  }

  .editiconWrapper {
    margin-left: 1px;
  }

  .patientDetailsWrapper {
    margin-left: 12px;
  }

  .patientName {
    font-size: 10px;
    margin-top: 24px;
  }

  .patientProfileID {
    font-size: 8px;
  }

  .switchButtonWrapper {
    margin-top: 18px;
    margin-left: -8px;
  }
}

@media screen and (max-width: 350px) {
  .userIconProfile {
    width: 24px;
  }

  .patientProfileDiv1 {
    height: 145px;
  }

  .editiconWrapper {
    margin-left: 4px;
  }

  .patientDetailsWrapper {
    margin-left: 2px;
  }

  .patientName {
    font-size: 8px;
    margin-top: 20px;
  }

  .patientProfileID {
    font-size: 8px;
  }

  .switchButtonWrapper {
    margin-top: 16px;
    margin-left: -10px;
  }
}

@media screen and (max-width: 336px) {
  .userIconProfile {
    width: 22px;
  }

  .patientProfileDiv1 {
    height: 145px;
  }

  .editiconWrapper {
    margin-left: 4px;
  }

  .patientDetailsWrapper {
    margin-left: 1px;
  }

  .patientName {
    font-size: 7px;
    margin-top: 20px;
  }

  .patientProfileID {
    font-size: 7px;
  }

  .switchButtonWrapper {
    margin-top: 4px;
    margin-left: -12px;
  }

  .MIIcon {
    width: 155px;
    margin-left: -12px;
  }

  .personalinfoWrapper {
    width: 159px;
    margin-left: 2px;
    margin-top: 8px;
  }
}

@media screen and (max-width: 325px) {
  .userIconProfile {
    width: 20px;
  }

  .patientProfileDiv1 {
    height: 145px;
  }

  .editiconWrapper {
    margin-left: 4px;
  }

  .patientDetailsWrapper {
    margin-left: 1px;
  }

  .patientName {
    font-size: 7px;
    margin-top: 20px;
  }

  .patientProfileID {
    font-size: 7px;
  }

  .switchButtonWrapper {
    margin-top: 4px;
    margin-left: -14px;
  }

  .MIIcon {
    width: 155px;
    margin-left: -12px;
  }

  .personalinfoWrapper {
    width: 159px;
    margin-left: 2px;
    margin-top: 8px;
  }
}

/* New styles done by poovarasan */

/* Section 1 */
.section1_part_1 {
  background-image: url("../../../assets/img/patient_profile/section1.png");
  height: 250px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  background-color: white;
  margin: 0px 80px 60px 80px;
}

.section1_part_2 {
  display: flex;
  width: 80%;
}

.section1_part_3 {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 20%;
}

.section1_part_3_item {
  margin: 0px 0px 10px 0px;
}

.section1_part_2_img {
  margin: 35px 30px 15px 30px;
}

.section1_part_2_img .avt_img {
  width: 180px;
  height: 180px;
  border-radius: 50%;
}

.flex_display {
  display: flex;
}

.section1_part_2_names {
  margin: 40px 0px 40px 0px;
}

.section1_part_2_name {
  font-size: 22px;
  font-weight: bold;
  font-family: "Roboto";
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide overflowing content */
  text-overflow: ellipsis;
  width: 300px;
}

.section1_part_2_name_2 {
  font-size: 14px;
  font-family: "Roboto";
  color: #6a768f;
  padding: 20px 0px 0px 0px;
  font-weight: bold;
}

.section1_part_2_name_3 {
  font-size: 14px;
  font-weight: bold;
  font-family: "Roboto";
  color: #6a768f;
  padding: 6px 0px 0px 10px;
  cursor: pointer;
}

.icon_condition {
  padding: 20px 0px 0px 0px;
}

.adheranceScoreDiv2_part1 {
  padding: 20px;
  cursor: pointer;
}

.adheranceScoreDiv2_part2 {
  padding: 20px;
}

.therapy_details {
  margin: 0px 80px 60px 80px;
}

.therapy_details_heading {
  font-size: 18px;
  font-family: "Roboto";
  font-weight: 800;
  margin: 30px 0px;
}

.therapy_details_graph {
  background-color: white;
}

.therapy_details_graph_heading {
  display: flex;
  padding: 20px 0px 20px 20px;
}
.therapy_details_graph_heading_withoutscroll {
  display: flex;
  padding: 20px 0px 20px 20px;
}
.therapy_details_graph_heading ::-webkit-scrollbar {
  width: 5px !important;
  height: 10px;
}

/* Track */
.therapy_details_graph_heading ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

/* Handle */
.therapy_details_graph_heading ::-webkit-scrollbar-thumb {
  background: rgba(160, 159, 159, 0.3);
  border-radius: 10px;
}

.therapy_details_graph_heading_part1 {
  width: 50%;
  font-weight: bold;
  font-family: "Roboto";
}

.therapy_details_graph_heading_part2 {
  width: 100%;
  display: flex;
  /* justify-content: flex-end; */
  padding: 0px 20px 0px 20px;
  overflow-x: scroll;
}

.therapy_details_graph_heading_top {
  display: flex;
  justify-content: space-between;
  padding: 20px 20px 0px 0px;
}

.therapy_details_graph_heading_top_heading {
  display: flex;
}

.therapy_details_graph_heading_top_heading_content {
  padding-left: 20px;
  font-weight: bold;
  font-size: 14px;
  font-family: "Roboto";
  /*  color: #6a768f38; */
}

.therapy_details_graph_heading_top_heading_date_display {
  font-weight: bold;
  font-family: "Roboto";
  text-align: center;
}

.therapy_goal_section_heading {
  display: flex;
  justify-content: space-between;
  margin: 0px 80px 0px 80px;
}

.activeTextToggle {
  position: relative;
  left: 10px;
  top: 4px;
  font-weight: 600;
  font-family: "Roboto";
}

.InactiveTextToggle {
  position: relative;
  right: 10px;
  left: -7px;
  top: 4px;
  font-weight: 600;
  font-family: "Roboto";
}

.patient-profile-patient-id-wrapper {
  padding-top: 6px;
  text-overflow: ellipsis;
  /* width: 100%; */
  white-space: nowrap;
  overflow: hidden;
}
.Therapygoalhistorydata ::-webkit-scrollbar {
  width: 5px !important;
  height: 10px;
}

/* Track */
.Therapygoalhistorydata ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

/* Handle */
.Therapygoalhistorydata ::-webkit-scrollbar-thumb {
  background: rgba(160, 159, 159, 0.3);
  border-radius: 10px;
}
.PatienProfileWrapper {
  margin-top: 0px;
}

@media screen and (max-width: 768px) {
  .PatienProfileWrapper {
    padding-top: 130px;
  }
}
