.caresitedetailsPopupWrapper .MuiDialog-paperWidthMd {
  width: 100%;
  background-color: #f2f7f8;
}

.caresitedetailsHeading.MuiTypography-h6 {
  font-family: "Roboto";
  font-weight: 600;
  /* line-height: 1.6;
    letter-spacing: 0.0075em; */
  background: #f2f7f8;
  color: #3d446f;
  padding-left: 50px;
  padding-right: 0px;
}
.caresitedetailsContentWrapper {
  background: #f2f7f8;
  background-image: url("../../assets//img/caresiteBgImage.png");
  background-repeat: no-repeat;
  background-position: right top;
  background-size: 500px 500px;
}
.caresitedetailsformBackground {
  background: transparent;
}

.abmaccount {
  margin-top: -15px;
}

.inputHeadingWraper {
  font-family: "Roboto";
  text-align: left;
  color: #36434b;
  padding-top: 8px;
  font-weight: 500;
}

.inputHeadingWraper2 {
  font-family: "Roboto";
  text-align: left;
  color: #36434b;
  padding-top: 8px;
  font-weight: 500;
  padding: 5px 50px;
}

.inputFieldforcaresitePopup {
  font-family: "Roboto";
  border: none;
  width: 100%;
  border-bottom: 1px solid #8a8e95;
  outline: none !important;
  background-color: transparent;
  box-shadow: none;
  outline-offset: unset;
  font-weight: 400;
  /* font-size: 19px; */
}
.inputFieldforcaresitePopupi {
  font-family: "Roboto";
  border: none;
  width: 100%;
  border-bottom: 1px solid #8a8e95;padding: 5px 0px 5px 1px;
  outline: none !important;
  background-color: transparent;
  box-shadow: none;
  outline-offset: unset;
  font-weight: 400;
  /* font-size: 19px; */
}
.inputFieldforcaresitePopup1 {
  border: none;
  border-bottom: 1px solid #8a8e95;
  padding: 5px 0px 5px 1px;
  /* margin-left: 48px; */
  outline: none !important;
  background-color: transparent;
  width: 88%;
  box-shadow: none;
  outline-offset: unset;
  margin-bottom: 10px;
  font-weight: 400;
}

.lastnameinputHeadingWraper2 {
  border: none;
  border-bottom: 1px solid #8a8e95;
  padding: 0px 0px 8px 0px;
  margin-left: 48px;
  outline: none !important;
  background-color: transparent;
  width: 88%;
  box-shadow: none;
  outline-offset: unset;
  margin-bottom: 10px;
}
.inputHeadingWraper2forstate {
  border: none;
  /* border-bottom: 1px solid #8a8e95; */
  padding: 0px 0px 8px 0px;
  margin-left: 48px;
  outline: none !important;
  background-color: transparent;
  width: 88%;
  box-shadow: none;
  outline-offset: unset;
  margin-bottom: 10px;
}
@media screen and (max-width: 1024px) {
  .lastnameinputHeadingWraper2 {
    margin-left: 8px;
    width: 100%;
  }
  .inputHeadingWraper2forstate {
    margin-left: 8px;
    width: 100%;
  }
  .inputHeadingWraper2 {
    padding: 5px 0px;
  }
  .inputFieldforcaresitePopup1 {
    width: 100%;
  }
  .inputFieldforcountry {
    width: 100% !important;
    margin-left: 0px !important;
  }
  .countryinputHeadingWraper {
    margin-left: 0px !important;
  }
  .inputFieldforzip {
    width: 100% !important;
    margin-left: 0px !important;
  }
  .zipinputHeadingWraper {
    margin-left: 0px !important;
  }
  .inputFieldforcity {
    width: 100% !important;
  }
  .inputFieldforstate {
    width: 100% !important;
  }
}
.stateinputHeadingWraper {
  font-family: "Roboto";
  text-align: left;
  color: #36434b;
  padding-top: 8px;
  font-weight: 500;
}
.inputFieldforcity {
  border: none;
  border-bottom: 1px solid #8a8e95;
  padding: 5px 0px 5px 1px;
  outline: none !important;
  background-color: transparent;
  width: 72%;
  box-shadow: none;
  outline-offset: unset;
  margin-bottom: 10px;
  font-weight: 400;
  /* margin-left: 50px; */
}
.inputFieldforstate {
  border: none;
  border-bottom: 1px solid #8a8e95;
  padding: 5px 0px 5px 1px;
  outline: none !important;
  background-color: transparent;
  width: 72%;
  box-shadow: none;
  outline-offset: unset;
  margin-bottom: 10px;
  font-weight: 400;
}
.profilepicuploadWrapper1 {
  display: flex;
  justify-content: center;
}
.profilepicuploadWrapper {
  display: flex;
  justify-content: center;
  position: absolute;
}
.zipinputHeadingWraper {
  font-family: "Roboto";
  text-align: left;
  color: #36434b;
  padding-top: 8px;
  font-weight: 500;
  /* margin-left: 50px; */
}

.inputFieldforzip {
  border: none;
  border-bottom: 1px solid #8a8e95;
  padding: 5px 0px 5px 1px;
  outline: none !important;
  background-color: transparent;
  width: 72%;
  box-shadow: none;
  outline-offset: unset;
  margin-bottom: 10px;
  /* margin-left: 50px; */
  font-weight: 400;
}

.countryinputHeadingWraper {
  font-family: "Roboto";
  text-align: left;
  color: #36434b;
  padding-top: 8px;
  font-weight: 500;
  /* margin-left: 50px; */
}
.inputFieldforcountry {
  border: none;
  border-bottom: 1px solid #8a8e95;
  padding: 5px 0px 5px 1px;
  outline: none !important;
  background-color: transparent;
  width: 72%;
  box-shadow: none;
  outline-offset: unset;
  margin-bottom: 10px;
  /* margin-left: 50px; */
  font-weight: 400;
}

.submitBtnforCaresitePopup {
  font-family: "Roboto";
  border: none;
  width: 200px;
  height: 40px;
  box-shadow: 0px 0px 4px 0px #8a8e95;
  border-radius: 4px !important;
  background-color: #ffffff !important;
  text-transform: capitalize !important;
  font-weight: 500 !important;
  outline: none !important;
  color: #3d446f;
  padding: 3px 0px 0px 0px;
}
.updatebtnCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  box-shadow: 1px 2px 0px 5 10px #0000;
}
.editiconWrapperForcaresite {
  border: 1px solid #f26930;
  background-color: #f26930;
  border-radius: 25px;
  width: 22px;
  height: 21px;
  position: relative;
  top: 171px;
  right: 59px;
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .profilepicuploadImageWrapper {
    border-radius: 97px;
    width: 80px;
    height: 80px;
  }
  .editImageUploadWrapper {
    width: 15px;
    height: 15px;
  }

  .editiconWrapperForcaresite {
    border: 1px solid #f26930;
    background-color: #f26930;
    border-radius: 25px;
    width: 25px;
    height: 25px;
    position: relative;
    padding-left: 4px;
    padding-top: 4px;
    top: 45px;
    right: 24px;
    cursor: pointer;
  }
}

@media screen and (min-width: 1024px) {
  .profilepicuploadImageWrapper {
    border-radius: 97px;
    width: 108px;
    height: 108px;
  }
  .editImageUploadWrapper {
    width: 17px;
    height: 17px;
  }

  .editiconWrapperForcaresite {
    border: 1px solid #f26930;
    background-color: #f26930;
    border-radius: 25px;
    width: 30px;
    height: 30px;
    position: relative;
    padding-left: 5px;
    padding-top: 5px;
    top: 70px;
    right: 36px;
    cursor: pointer;
  }
}

@media screen and (min-width: 1280px) {
  .profilepicuploadImageWrapper {
    border-radius: 97px;
    width: 136px;
    height: 136px;
  }
  .editImageUploadWrapper {
    width: 22px;
    height: 22px;
  }

  .editiconWrapperForcaresite {
    border: 1px solid #f26930;
    background-color: #f26930;
    border-radius: 25px;
    width: 35px;
    height: 35px;
    position: relative;
    padding-left: 5px;
    padding-top: 5px;
    top: 96px;
    right: 49px;
    cursor: pointer;
  }
}
@media screen and (min-width: 1440px) {
  .submitBtnforCaresitePopup {
    width: 260px;
    height: 50px;
  }

  .profilepicuploadImageWrapper {
    border-radius: 97px;
    width: 153px;
    height: 153px;
  }
  .editImageUploadWrapper {
    width: 24.8px;
    height: 24.8px;
  }

  .editiconWrapperForcaresite {
    border: 1px solid #f26930;
    background-color: #f26930;
    border-radius: 25px;
    width: 38px;
    height: 38px;
    position: relative;
    padding-left: 5px;
    padding-top: 5px;
    top: 109px;
    right: 62px;
    cursor: pointer;
  }
}

@media screen and (min-width: 1920px) {
  .submitBtnforCaresitePopup {
    width: 320px;
    height: 64px;
  }
  .profilepicuploadImageWrapper {
    border-radius: 97px;
    width: 204px;
    height: 204px;
  }
  .editImageUploadWrapper {
    width: 33px;
    height: 33px;
  }

  .editiconWrapperForcaresite {
    border: 1px solid #f26930;
    background-color: #f26930;
    border-radius: 25px;
    width: 50px;
    height: 50px;
    position: relative;
    padding-left: 8px;
    padding-top: 8px;
    top: 124px;
    right: 64px;
    cursor: pointer;
  }
}

.caresiteicon {
  position: absolute;
  width: 300px;
  top: 30%;
  left: 10%;
}
.caresitedetailsPopupWrapper .MuiDialogTitle-root {
  padding: 0px;
}
.caresitedetailsContentWrapper::-webkit-scrollbar {
  width: 15px;
}

/* Track */
.caresitedetailsContentWrapper::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

/* Handle */
.caresitedetailsContentWrapper::-webkit-scrollbar-thumb {
  background: rgba(160, 159, 159, 0.3);
  border-radius: 10px;
}

.caresitedetailsPopupWrapper .MuiDialogTitle-root {
  padding: 0px;
}
