.dashboardcardwapper{
    margin-top: 40px;
    border: 1px solid transparent;
    /* background-color: #F2F8F2; */
    box-shadow: 0px 3px 40px #6A768F38;
    border-radius: 8px;
    width: 395px;
    height: 225px;
    margin-left: 64px;
    box-shadow: 0px 3px 40px #6A768F38;
}

.imageWrapper{
   margin-left: 40px;
    align-self : center;
    width: 98px;
    height: 99px;
}

.enrolledRoleWrapper{
    display: flex;
    justify-content:space-evenly;
    padding-top: 35.37px;
    font-size: 25px;
    font-weight: 500;
}
.enrolledWrapper{
    font-size: 25px;
/* font: normal normal medium 32px/38px Roboto; */
color: #6A768F;
}

.enrolledCountWrapper{
    font-size: 30px;
/* font: normal normal medium 56px/67px Roboto; */
color: #F26930;
}

.viewButtonWrapper{
    box-shadow: 0px 3px 10px #6A768F38;
    border: 1px solid #F2F8F2;
    background: #F2F8F2;
    color: #3D446F;
    font-weight: 700;
    border-radius: 8px;
    width: 275px;
    height: 56px;
    display: flex;
    justify-content: center;
    margin-left: 55px;
    margin-top: 29px;
    font-size: 22px;
    cursor: pointer;
    padding-top: 8px;
}
.viewButtonWrapper:hover{
    /* width: 280px;
    height: 59px; */
    margin-left: 48px;
    /* transition-delay:1s; */
}

.inviteButtonWrapper{
    box-shadow: 0px 3px 10px #6A768F38;
    border: 1px solid #f3fcfe;
    background: #f3fcfe;
    color: #3D446F;
    font-weight: 700;
    border-radius: 8px;
    width: 275px;
    height: 56px;
    display: flex;
    justify-content: center;
    margin-left: 55px;
    margin-top: 24.5px;
    font-size: 22px;
    cursor: pointer;
    padding-top: 8px;
}

.inviteButtonWrapper:hover{
    width: 310px;
    height: 63px;
    transition-delay:1s ;
}

.cardDescription{
    color: #3D446F;
    font-family: 'Roboto';
    display: flex;
    justify-content: center;
    /* font-size: 20px; */
    cursor:pointer;
    /* width: 336px; */
    margin-top: 25px;
    margin-bottom: 24px;
    font-weight: 800;
    margin-left: 24px;
    margin-right: 24px;
}

.leftarrowimgWrapper{
   width: 41px;
   height: 41px; 
   align-self: baseline;
   /* margin-left: 70px; */
   /* margin-top: 16px; */
    /* margin-right: 16.66px; */
}
.Imagewrapper{
    display: flex;
    justify-content: center;
}

.dashboardImageWrapper{
    display: flex;
    justify-content:space-evenly;
    padding-top: 12px;
    /* margin : auto; */
}

