.RegisterDataContainer {
  background-color: #f0f2f4;
  width: 100%;
  height: 100%;
}
.ImageData {
  width: 100%;
  height: 50%;
  padding: 40px;
}
/* .ImageData img{
  margin-top: -65px;
} */
.RegisterHeading {
  color: #3d446f;
  text-align: center;
  font-size: 32px;
  font-weight: 800;
  font-family: 'Roboto';
  padding-top: 0px;
}
.InputFieldforRightSideContainer {
  display: flex;
  background: #fff;
  justify-content: center;
  align-items: center;
  /*   position: fixed;
    width: 50%; */
  text-align: center;
  z-index: 1;
  align-items: center;
}
.RightSideHeadingTitle {
  font-size: 52px;
  text-align: left;
  font-weight: bold;
  align-items: center;
  color: #6a768f;
  font-family: 'Roboto';
  padding-top: 5px;
}
.IconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.IconContainer img {
  width:150px;
  height: 150px;
  margin-top: 20px;
  border-radius: 50%;
}
/* .DataContainerForInputInResgister{ */
/* display: flex;
  justify-content: center;
  align-items: center; */
/* } */
.labelsfor {
  text-align: center;
  padding-top: 25px;
}
.labaleforResgiter {
  font-size: 16px;
  color: #6a768f;
  position: relative;
  font-family: "Roboto";
  font-weight: 800;
  bottom: 16px;
  right: 200px;
}
.labaleforResgiterCpass {
  font-size: 16px;
  color: #6a768f;
  position: relative;
  bottom: 16px;
  right: 170px;
  font-family: "Roboto";
  font-weight: 800;
}
.labaleforResgiterfname {
  font-size: 16px;
  color: #6a768f;
  position: relative;
  bottom: 16px;
  right: 200px;
  font-family: "Roboto";
  font-weight: 800;
}
.labaleforResgiterPnumber {
  font-size: 16px;
  color: #6a768f;
  position: relative;
  bottom: 16px;
  right: 180px;
  font-family: "Roboto";
  font-weight: 800;
}
.labaleforResgiteremail {
  font-size: 16px;
  color: #6a768f;
  position: relative;
  bottom: 16px;
  right: 175px;
  font-family: "Roboto";
  font-weight: 800;
}
.SubmitForRegister {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 10px;
}
.innersubmitForRegister {
  width: 320px;
  outline: none !important;
  background-color:	#F2F8F2 !important;
  text-transform: capitalize !important;
  padding: 5px;
  color: #3d446f !important;
  /* color: green !important; */
  font-size: 18px !important;
  box-shadow: #6a768f80 0px 3px 6px;
  font-family: "Roboto" !important;
  font-weight: 800 !important;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  border: 2px solid transparent !important;
  /* transition: border 0.3s ease; */
}
.customButton:hover{

  border: 2px solid #9A9C23 !important;
}
.innersubmitForRegisterpleaselogin {
  width: 320px;
  outline: none !important;
  background-color: #F2F8F2  !important;
  text-transform: capitalize !important;
  padding: 5px;
  color: #3d446f !important;
  /* color: green !important; */
  font-size: 18px !important;
  box-shadow: #6a768f80 0px 3px 6px;
  font-family: "Roboto" !important;
  font-weight: 800 !important;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  border: 2px solid transparent !important;
}
.innersubmitForRegisterphoneverify {
  width: 100%;
  height: 37px;
  outline: none !important;
  background-color:			#F2F8F2 !important;
  text-transform:none  !important ;
  padding: 5px;
  color: #3d446f !important;
  /* color: green !important; */
  font-size: 18px !important;
  box-shadow: #6a768f80 0px 3px 6px;
  font-family: "Roboto" !important;
  font-weight: 800 !important;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  border: 2px solid transparent !important;
  /* transition: border 0.3s ease; */
}
.innersubmitForRegister1 {
  width: 320px;
  outline: none !important;
  background-color: white !important;
  text-transform: capitalize !important;
  padding: 5px;
  color: #3d446f !important;
  font-size: 15px !important;
  box-shadow: #6a768f80 0px 3px 6px;
  font-family: "Roboto" !important;
  font-weight: 800 !important;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}
.rightsidecontainer {
  overflow: hidden;
}

.pwdEyeOff{
  display: flex;
  /* justify-content: space-between; */
  margin-right: 24px;
  background-color: white;
  
  box-shadow: #6a768f80 0px 3px 6px;
}
.labaleforResgiterRole{
  font-size: 16px;
  color: #6a768f;
  position: relative;
  bottom: 16px;
  right: 210px;
  font-family: "Roboto";
  font-weight: 800;
}
/*   .InputFieldforRightSideContainer{
@media screen and (max-width: 1000px) {
  /*   .InputFieldforRightSideContainer{
    display: flex;
    background: #fff;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    text-align: center;
    z-index: 1;
    align-items: center;
  } */
  .RightSideHeadingTitle {
    font-size: 32px;
  }
  .RegisterHeading {
    font-size: 24px;
    padding-top: 10px !important;
  }

.footerregister {
  margin-top: -311px;
}

input:-webkit-autofill {
  -webkit-box-shadow:0 0 0 50px #F2F4F8 inset; /* Change the color to your own background color */
  -webkit-text-fill-color: #333;
}

input:-webkit-autofill:focus {

  -webkit-box-shadow: 0 0 0 50px red inset;/*your box-shadow*/
  -webkit-text-fill-color: #333;
} 

