.footerWrapper .footer-wrapper{
    display: flex; 
    justify-content: space-evenly;
     padding-left: 655px !important;
}
.footer-wrapper1 {
  display: flex; 
justify-content: space-evenly;
  /* padding-right: 30px; */
}
@media only screen and (max-width: 1115px) {
    .footer-wrapper {
        display: flex; 
    justify-content: space-between;
        padding-left: 50%;
    }
    .footer-wrapper1 {
      display: flex; 
  justify-content: space-evenly;
      /* margin-right: 10px;
      padding-right: 30px; */
      margin-left: 29px;
  }
  }

@media only screen and (max-width: 1023px) {
    .footer-wrapper {
        padding-left: 10px;
    }
  }

  @media  screen and (max-width: 768px) {
    .footer-wrapper {
        display: flex; 
        padding-left: 1px;
    }
  }  

  @media only screen and (max-width: 521px) {
    .footer-wrapper {
        display: flex; 
    justify-content: space-evenly;
     padding-left: 29px;
     padding-bottom: 10px;
    }
    .footer-wrapper1 {
      display: flex !important; 
  justify-content: space-evenly;
  }
  }  

  .subfooter-1{
      padding-left: 0px;
      margin-right: 0px;
  }
  .subfooter-3{
    padding-left: 0px;
    margin-right: 15px;
}
  .subfooter-2{
      padding-left: 0px;
      margin-right: 15px;
  }

  @media only screen and (max-width: 521px) {
    .subfooter-1{
       margin-left: -24px;
    }
 
     
  }  

  @media only screen and (max-width: 521px) {
    .subfooter-2{
        /* padding-top: 20px; */
        padding-left: 0px;
        margin-left: -9px;
    }
  }  

 
  @media   (max-width: 1920px) and (min-width: 1500px) {
    .footer-wrapper {
      display: flex; 
      justify-content: space-between;
        padding-left: 50%;
        padding-top: 10px;
        padding-bottom: 10px;
    }
  }
  @media   (max-width: 1500px) and (min-width: 1100px) {
    .footer-wrapper {
      display: flex; 
      justify-content: space-between;
        padding-left:50%;
    }
  }