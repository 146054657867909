.sliderWidth{
    margin-top:56px;
     width:'100%';
}

@media screen and (max-width: 1115px ){
    .sliderWrapper{
        width:735px;  
    }
  }

  @media screen and (max-width: 1046px ){
    .sliderWrapper{
        width:660px;
    }
  }

  @media screen and (max-width: 1037px ){
    .sliderWrapper{
        width:656px;
    }
  }

  @media screen and (max-width: 966px ){
    .sliderWrapper{
        width:590px;
    }
  }

  @media screen and (max-width: 906px ){
    .sliderWrapper{
        width:510px;
    }
  }
  @media screen and (max-width: 823px ){
    .sliderWrapper{
        width:450px;
    }
  }

  @media screen and (max-width: 765px ){
    .sliderWrapper{
        width:645px;
    }
  }

  @media screen and (max-width: 700px ){
    .sliderWrapper{
        width:578px;
    }
  }

  @media screen and (max-width: 635px ){
    .sliderWrapper{
        width:508px;
    }
  }

  @media screen and (max-width: 564px ){
    .sliderWrapper{
        width:445px;
    }
  }

  @media screen and (max-width: 500px ){
    .sliderWrapper{
        width:375px;
    }
  }

  @media screen and (max-width: 433px ){
    .sliderWrapper{
        width:315px;
    }
  }

  @media screen and (max-width: 372px ){
    .sliderWrapper{
        width: 260px;
    }
  }