.session-expiry-dialog-wrapper{
    width: 100%;
}

.session-expiry-content{
    display: flex;
    align-self: center;
    align-items: center;
    flex-direction: column;
    margin: auto;
    text-align: center;
}