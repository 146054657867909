:root {
  --background-image-url: url("https://images.unsplash.com/photo-1519681393784-d120267933ba?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1124&q=100");
}

/* Glassmorphism card effect */
.card {
  backdrop-filter: blur(16px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  background-color: rgba(17, 25, 40, 0.75);
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.125);
}

/* Generated by https://generator.ui.glass/ */
/* body {
  background: var(--background-image-url) no-repeat center center fixed;
  background-size: cover;
*/

.table-container {
  width: 98%;
  overflow-x: auto;
  margin: 20px;
  justify-content: center;
  align-items: center;
  padding: 20px;
  /* display: flex; */
  /* padding-top: 26px; */
  /* padding-left: 50px; */
}
/* Background styles */
.background {
  background-image: var(--background-image-url);
  /* background-image: url("https://i.pinimg.com/736x/b7/08/53/b70853405123116e827cbbd38934395e.jpg") ; */
  background-position: inherit;
  /* border-radius: 25px; */
  background-repeat: no-repeat;
  background-size: cover;
  padding: 5px;
  height: 100vh;
}

/* Glassmorphism card effect */
.card-container {
  backdrop-filter: blur(16px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  background-color: rgba(17, 25, 40, 0.75);
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.125);
}

/* Generated by https://generator.ui.glass/ */
.password-input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* height: 100vh; Set to 100% of the viewport height */
  /* background-color: #f4f4f4; Add a grey background color */
  padding: 20px;
}
.data-table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  border: 1px solid #ddd;
  padding: 15px; /* Increase padding for better spacing */
  text-align: left;
}

.search-bar {
  margin-bottom: 10px;
}

/* New styles for better responsiveness */

.flex-container {
  display: flex;
  flex-wrap: wrap;
}

.flex-item {
  flex: 1;
  margin: 10px;
}

@media (max-width: 768px) {
  .flex-item {
    flex: 100%; /* Make each item take the full width on smaller screens */
  }
}
/* Add this CSS to your stylesheet */

.card-container {
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin: 20px;
}

.card-header {
  padding: 10px;
  background-color: #f0f0f0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
  color: white;
  backdrop-filter: blur(16px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  background-color: rgba(17, 25, 40, 0.75);
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.125);
  overflow: auto;
}

.card-header:focus {
  color: white;
}
.card-header label {
  margin-right: 10px;
}

.card-table {
  overflow: auto;
  max-height: 60vh;
}

.data-table {
  width: 100%;
  border-collapse: collapse;
}

.data-table th,
.data-table td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
  color: white;
}

.card-footer {
  padding: 10px;
  /* background-color: #f0f0f0; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #ddd;
  color: white;
}

.card-footer select,
.card-footer input {
  margin-left: 5px;
}
.input-field {
  border-radius: 25px;
  color: white !important;
}
.input-field:focus {
  background-color: rgb(34 43 61 / 75%) !important;
}
.password-input:focus {
  background-color: white !important;
}
.table-container{
/* width */
::-webkit-scrollbar {
  width: 10px;
  background: transparent; 
  height: 10px;/* Set a transparent background */
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
  backdrop-filter: blur(
    10px
  ); /* Apply a blur effect to create glass morphism */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
  backdrop-filter: blur(
    10px
  ); /* Apply a blur effect to create glass morphism */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
}