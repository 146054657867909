.chartWrapper {
    position: relative;
}

.chartWrapper>canvas {
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
}

.chartAreaWrapper {
    width: 600px;
    overflow-x: scroll;
}