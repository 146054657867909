.cardsetting {
  margin-left: 30px;
  margin-right: 30px;
}

.disabledinput{
  cursor:no-drop;
}

.fname {
  border: none;
  border-bottom: 1px solid;
  outline: none !important;

  font-size: large;
  margin-right: 7px;
  width: 85%;
  padding: 5px !important;
  border-color: #aaaaac;

  box-shadow: none;
  background-color: transparent !important;
  outline-offset: unset;
}
.classfortextfieldinuserdetailsleft {
  border: none;
  border-bottom: 1px solid;
  outline: none !important;

  font-size: large;
  margin-right: 7px;
  width: 100%;
  padding: 5px !important;
  border-color: #aaaaac;

  box-shadow: none;
  background-color: transparent !important;
  outline-offset: unset;
}
.editPencilProfileIocn {
  position: relative;
  left: 100px;
  top: -20px;
  width: 26px;
  height: 26px;
  cursor: pointer;
  background-color: #f26930;
  border-radius: 50%;
}
.sname {
  border: none;

  border-bottom: 1px solid;

  outline: none;

  font-size: large;

  width: 70%;
  padding: 5px;
  padding-right: 30px;
  border-color: #aaaaac;

  box-shadow: none;
  background-color: transparent;
  outline-offset: unset;
}

.uname {
  border: none;

  border-bottom: 1px solid;
  outline: none !important;

  font-size: large;
  margin-right: 10px !important;
  width: 100%;
  padding: 5px;
  border-color: #aaaaac;

  box-shadow: none;
  background-color: transparent !important;
  outline-offset: unset;
}

.lname {
  border: none;

  border-bottom: 1px solid;
  padding: 5px ;

  outline: none !important;

  font-size: large;
  padding-right: 90px;
  width: 100%;
  padding-left: -20px !important;
  border-color: #aaaaac;

  box-shadow: none;
  background-color: transparent !important;
  outline-offset: unset;
}

.fname:focus {
  outline: none !important;
}

.containers {
  padding-right: 40px;
  overflow-x: hidden !important;
  background-size: 800px 750px;
  background-position: right top;
  background-repeat: no-repeat;
  background-image: url("../../assets/img/userprofilediv/newBlueDesign.png"); /*//backgroundimg the circle one */
}

.selectdropdwn {
  background-color: #d5d5d8 !important;
  outline: none !important;
  box-shadow: 0px 0px 0px !important;
  border: none !important;
  border-bottom: 0px !important;
  width: 100%;
  border-color: #434344;
}
.UserprofileUpdated {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  align-items: center;
}
.UserprofileUpdated .userProfile-image {
  width: 150px !important;
  height: 150px !important;
}
.UserprofileUpdated .userProfile-image {
  width: 150px !important;

  height: 150px !important;
}
.buttonforuserUpdate {
  margin-bottom: 30px !important;
  border: none;
  margin-top: 15px !important;
  width: 40%;
  height: 60px;
  box-shadow: #6a768f80 0px 3px 6px;
  border-radius: 9px !important;
  background-color: #fff !important;
  text-transform: capitalize !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  outline: none !important;
}
.piccontroller2 {
  position: relative !important;
  /* right: -30px !important; */
  width: 150px;
  height: 150px;
  border-radius: 50%;
  z-index: 1;
  top: -5px !important;
  right: -20px !important;
  /* margin-top: 15px;
    margin-bottom: 40px; */
}
.piccontroller1 {
  position: absolute !important;
  top: -25px;

  /* left: 100px !important; */

  z-index: 1;
  justify-content: center !important;

  /* margin-top: 15px;
    margin-bottom: 40px;
     */
}
.editprofile_setting {
  position: relative;
  top: 0px;
  left: 0px;
}
.editprofile_setting .header_editpic {
  /* padding-top: 50px; */
  padding-left: 35px;
  font-weight: 600 !important;
  font-size: 30px;
  /* margin-top: -10px; */
  position: relative;
}
.editprofile_setting .closeIcon {
  position: absolute;
  top: 25%;
  left: 94%;
}
.userProfile_paasswordandsecurity {
  color: #126ac4;
  font-family: "Roboto-Medium";
  font-size: 16px;
  text-align: center;
  justify-content: center;
  /*  margin-top: -4%; */
  margin-bottom: 64px;
  text-decoration: underline;
  font-weight: bold;
  margin-bottom: 82px;
}
.MuiAvatar-root .MuiAvatar-img {
  object-fit: fill;
}
@media screen and (max-width: 1000px) {
  .uname {
    width: 100%;
  }
  .fname {
    width: 100%;
  }
  .sname {
    width: 100%;
  }
  .buttonforuserUpdate {
    width: 30%;
  }
}
@media screen and (max-width: 1000px) {
  .editprofile_setting {
    padding-top: 30px;
  }
  .editprofile_setting .closeIcon {
    top: 70%;
  }
}
@media screen and (max-width: 382px) {
  .buttonforuserUpdate {
    width: 70%;
  }
}
@media screen and (max-width: 282px) {
  .editprofile_setting .header_editpic {
    font-size: 20px;
  }
}

@media screen and (max-width: 768px) {
  .header_editpic {
    padding-top: 160px;
    /* margin-left: 83px; */
    /* margin-right: 64px; */
  }
  .UserprofileUpdated {
    margin-top: 96px;
  }
}

.PhoneinputForUserProfile {
  width: 32% !important;
  margin-right: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
