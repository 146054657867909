input:focus {
  box-shadow: #6A768F80 0px 0px 0px !important;
  border: none !important;
  border-bottom: 1px solid !important;
  background-color:  white!important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  transition: background-color 5000s ease-in-out 0s;
  color: #4f557c !important;
}