.helpCenter {
  margin-left: 80px;
  color: #3d446f;
  font-weight: 600;
  font-family: "Roboto";
}

.helpCenterCLoseIcon {
  margin-right: 80px;
}

.helpCenterBackground {
  background-image: url("../../assets/img/userprofilediv/newBlueDesign.png");
  background-repeat: no-repeat;
  background-size: 650px 650px;
  background-position: right top;
}

.subjectHeading {
  margin-left: 80px;
  margin-top: 40px;
  color: #3d446f;
  font-weight: 600;
  font-family: "Roboto";
}

.subjectInputName {
  border: none;
  border-bottom: 3px solid #c1c5d3;
  outline: none;
  font-size: 16px;
  font-family: "Roboto";
  color: #3d446f;
  font-weight: 450;
  width: 100%;
  background-color: transparent;
}

.subjectInputField {
  margin-right: 80px;
  margin-left: 80px;
}

.howCanWeHelp {
  margin-top: 40px;
  color: #3d446f;
  font-weight: 600;
  font-family: "Roboto";
  margin-right: 80px;
  margin-left: 80px;
}

.helpCenterTextArea {
  margin-right: 80px;
  margin-left: 80px;
  margin-top: 40px;
}

.textAreaHelpCenter {
  width: 100%;
  height: 180px;
  background-color: transparent;
  border: 2px solid #c1c5d3;
}

.submitSection {
  color: #3d446f;
  font-weight: 600;
  font-family: "Roboto";
  text-align: center;
  background-color: white;
  /* padding: 32px 80px 32px 80px; */
  border-radius: 8px;
  box-shadow: #6a768f80 0px 3px 6px;
  width: 200px;
  height: 65px;
  outline: none !important;
}

.submitSegment {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 40px;
}

.ABMLogo {
  height: 230px;
  width: 230px;
}

.bottomContainer {
  background-image: linear-gradient(
    to right,
    #f3fcfe,
    #f2f7f8,
    #fef4f0,
    #f2f8f2
  );
  box-shadow: #6a768f80 0px 3px 6px;
  margin-right: 100px;
  margin-left: 100px;
  margin-top: 40px;
  border-radius: 10px;
}

.bottomContainerHeading {
  color: #3d446f;
  font-weight: 600;
  font-family: "Roboto";
}

.bottomSectionContainer1 {
  display: flex;
  margin-top: 30px;
}

.EmailIcon {
  background-color: white;
  padding: 5px;
  border-radius: 5px;
  margin-right: 30px;
  box-shadow: #6a768f80 0px 3px 6px;
}

.customservice {
  align-self: center;
  color: #3d446f;
  font-family: "Roboto";
}

.websiteIcon {
  background-color: white;
  padding: 3px;
  border-radius: 5px;
  margin-right: 30px;
  box-shadow: #6a768f80 0px 3px 6px;
}

.ABMLogocontainer {
  margin: 34px 0 32px 56px;
}

.ABMWordingsContainer {
  margin: 64px 0px 0px 130px;
}

.textAreaVlidation {
  margin-top: 10px;
  font-size: 12px;
  margin-left: 80px;
  color: red;
  font-family: "Roboto";
}
.subjectValidation {
  margin-top: 10px;
  font-size: 12px;
  color: red;
  font-family: "Roboto";
}

@media only screen and (max-width: 1920px) and (min-width: 1750px) {
  .bottomContainer {
    margin-right: 360px;
    margin-left: 360px;
  }

  .ABMWordingsContainer {
    margin: 112px 0px 0px 154px;
  }

  .ABMLogo {
    height: 330px;
    width: 330px;
  }
}

@media only screen and (max-width: 1750px) and (min-width: 1650px) {
  .bottomContainer {
    margin-right: 260px;
    margin-left: 260px;
  }

  .ABMWordingsContainer {
    margin: 96px 0px 0px 154px;
  }

  .ABMLogo {
    height: 300px;
    width: 300px;
  }
}

@media only screen and (max-width: 1650px) and (min-width: 1550px) {
  .bottomContainer {
    margin-right: 220px;
    margin-left: 220px;
  }

  .ABMWordingsContainer {
    margin: 88px 0px 0px 154px;
  }

  .ABMLogo {
    height: 280px;
    width: 280px;
  }
}

@media only screen and (max-width: 1550px) and (min-width: 1450px) {
  .bottomContainer {
    margin-right: 180px;
    margin-left: 180px;
  }

  .ABMWordingsContainer {
    margin: 80px 0px 0px 154px;
  }

  .ABMLogo {
    height: 280px;
    width: 280px;
  }
}

@media only screen and (max-width: 1450px) and (min-width: 1350px) {
  .bottomContainer {
    margin-right: 160px;
    margin-left: 160px;
  }

  .ABMWordingsContainer {
    margin: 64px 0px 0px 154px;
  }

  .ABMLogo {
    height: 280px;
    width: 280px;
  }
}

@media only screen and (max-width: 1350px) and (min-width: 1194px) {
  .bottomContainer {
    margin-right: 100px;
    margin-left: 100px;
  }

  .ABMWordingsContainer {
    margin: 64px 0px 0px 154px;
  }

  .ABMLogo {
    height: 230px;
    width: 230px;
  }
}

@media screen and (max-width: 739px) {
  .bottomContainer {
    justify-content: center;
  }

  .ABMWordingsContainer {
    justify-content: center;
  }

  .AbmContainer {
    width: 100%;
  }
}

@media screen and (max-width: 825px) {
  .ABMWordingsContainer {
    margin: 40px 0px 0px 33px;
  }
}

@media screen and (max-width: 1024px) {
  .ABMWordingsContainer {
    margin: 74px 0px 0px 38px;
  }
}

@media only screen and (max-width: 834px) {
  .ABMWordingsContainer {
    margin: 74px 0px 0px 38px;
  }
}
