.select {
    min-height: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
    position: relative;
    background-color:white;
    box-shadow : #6A768F80 0px 3px 6px;
    border-radius: 4px;
    outline: none !important;
  }

  
  .select__label {
    display: block;
    height: 51px;
    justify-self: flex-start;
    font: inherit;
    background: none;
    border: 0;
    width: 100%;
    text-align: left;
    padding: 10px;

  }
  
  .select__label::after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-width: 8px;
    border-style: solid;
    border-color: black transparent transparent transparent;
    position: absolute;
    right: 20px;
    top: 20px;
  }
  
  ul.select__options {
    /* margin: 0; */
    margin-top: 10px;
    padding: 0;
    list-style: none;
    position: absolute;
    left: 0;
    top: 40px;
    width: 100%;
    border: 1px solid #eee;
    border-radius: 5px;
    background-color: #F2F4F8;
    z-index: 1;
    box-shadow : #6A768F80 0px 3px 6px;
  }
  
  ul.select__options > li + li {
    border-top: 1px solid white;
    
  }
  
  ul.select__options > li > button {
    /* background: white; */
    width: 100%;
    border: 0;
    padding: 10px 20px;
    text-align: left;
    font: inherit;
    /* background-color: red; */
  }
/* .select__options:hover {
    background-color: white;
    color: black;
  } */
  .listImage{
    margin-left: 40px;
    /* background-color: #F2F4F8; */
    width: 30px;
    height: 30px;
  }
  ul.select__options > li > button:hover {
    background-color: white; 
     width: 230px; 
     /* height: 55px; */
     transition-delay:1s; 
   } 
  ul.select__options > li:hover{
    background-color: white;
     /* width: 900px; */
     /* height: 60px;  */
  
  }
  
  
  
  