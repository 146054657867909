.pswd-container{
    /* height: 100%; */
    min-height: 100vh;
margin-left: 20px;
  padding-bottom: 30px;
  overflow: hidden;
  background-image: url("../../assets/img/black_back.png");
  background-repeat: no-repeat;
  background-position: right top;
  background-size: 550px 550px;
}
.header-password{
    color: #3D446F;
    font-family: "Roboto";
    font-size: 24px;
    font-weight: bold;
    position: relative;
    margin-bottom: 30px;
}
.pswd-close{
    position: absolute;
  right: 5px;
  margin-right: 40px;
 margin-top: -55px;
 margin-bottom: 30px;
 cursor: pointer;
}
.pswdsecurity-img{
    margin-left: 30px;
}
.pswd-mobile{
    color: #3D446F;
    font-family: "Roboto";
    font-size: 24px;
    font-weight: bold;
    position: relative;
    margin-bottom: 30px;
}
.psd-icon{
    margin-right: 20px;
}
.psd-icon-mobile{
    margin-right: 20px; 
}
.fullpswd-security{
 display: flex;
 justify-content: space-between;
 align-items: center;
 /* padding-top: 20px;
 padding-bottom: 25px; */
}
.pswd-email{
    color: #6A768F;
    font-family: "Roboto";
    font-size: 16px;
    font-weight: bold;
    /* position: relative;
    margin-bottom: 30px; */
}
.pswd-create{
    color: #3D446F;
    font-family: "Roboto";
    font-size: 24px;
    font-weight: bold;
    position: relative;
    margin-bottom: 30px;
}
/* .pswd-edit{
    position: absolute;
   right: 5px;
  margin-right: 45px;
 margin-top: -33px; 
}*/
.picture-password{
    margin-right: 85px;
    width: 24px;
    cursor: pointer;
}
.edit-psswd{
    margin-left: 50px;
    margin-top: -23px;
    text-decoration: underline;
    font-size: 16px;
    font-weight: bolder;
    color: #6A768F;
    cursor: pointer;
  
}
.close-psswd{
    margin-left: -70px;
    /* margin-top: -27px; */
    text-decoration: underline;
    font-size: 16px;
    font-weight: bold;
    color: #6A768F;
    cursor: pointer;
}

@media screen and (max-width: 768px ){
    .header-password{
       padding-top: 160px;
        /* margin-left: 83px; */
        /* margin-right: 64px; */
    }
  }
@media screen and (min-width: 1020px) and (max-width: 1151px)  {
    .responsive-text{
       font-size: 10px;
       display: flex;
       justify-content: center;
       align-items: center;
       text-align: center;
       flex-direction: column;
    }
}