.roboto-family{
    font-family: "Roboto";
  }

  @media screen and (min-width: 1024px ){
    .footerHeightWrapper{
      padding: 8px;
    }
  }
  @media screen and (min-width: 1280px ){
    .footerHeightWrapper{
      padding: 12px;
    }
  }

  @media screen and (min-width: 1440px ){
    .footerHeightWrapper{
      padding: 16px;
    }
  }
  @media screen and (min-width: 1920px ){
    .footerHeightWrapper{
      padding: 20px;
    }
  }