.caresiteWrapper{
    /* font-family:  Roboto; */
    font-weight: bold;
    text-align: left;
    color: #3D446F;
    opacity: 1;
    margin-left: 67px;
    font-size: 27px;
    margin-bottom: 40px;
}
.caresitesettingdropdownWrapper{
    margin-left: 83px;
    margin-right: 64px;
}
#careSiteDetailBTN:hover{
background-color: #FFA500;
}

/* .caresitesettingdropdownWrapper .MuiSelect-select.MuiSelect-select {
    width: 855px;
    padding: 18px 650px 17px 64px;
    color: #6A768F;
    background-color: white; 
    font-weight: 500;
    overflow: hidden;
} */

.MuiPaper-root .MuiMenu-paper .MuiPopover-paper .MuiPaper-elevation8 .MuiPaper-rounded {
    background: rgb(242, 244, 248);
    opacity: 1;
    transform: none;
    min-width: 1291px;
    max-height: 224px;
    width: 250px;
    transition: opacity 280ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 186ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    top: 192px;
    left: 339px;
    transform-origin: 159px 62px;
}

.caresiteSettingCardsWrapper{
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    /* margin-right: 30px;
    margin-left: 30px; */
    /* width: 100%; */
}
.selectImage{
    background-image: url("../../../assets/img/dropInvitation.svg");
    border : none;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 20px 20px;
    /* position: relative; */
  }

 @media screen and (max-width: 1024px) {
    .caresiteSettingCardsWrapper {
    flex-direction: column;
    justify-content: center;
    }
  } 
.caresitePatientslistHeadingWrapper{
      /* font-family:  Roboto; */
      font-weight: bold;
      text-align: left;
      color: #3D446F;
      opacity: 1;
      margin-left: 82px;
      font-size: 22px;
      margin-top: 40px;
      font-weight: 700;
}
.caresitesearchboxWrapper{
    margin-top: 40.05px;
    /* margin-left: 80px; */
    width: 1281px;
    height: 64px;
}

.searchbar-report_caresite_settings{
    margin-left: 65px;
    padding: 20px;
    display: flex;
    justify-content: flex-start;
    position: relative; 
}

.report_search_icon_caresite_settings{
    position: absolute;
 z-index: 1;
 top: 35%;
 padding-left: 15px;
}

.report_search_caresite_settings{
    width: 50%;
    position: relative;
    top: 0px;
    left: 0px;
    text-align:left;
    border-radius:8px;
    border: none;
    box-shadow: #6A768F80 0px 3px 6px;
    
}
.report_search_caresite_settings::placeholder{
    padding-left:5% ;
}

.caresiteDashboardbottomCard{
    width: 100%;
}


.mainTable {
    width: 100%;
    border : 1px solid #6A768F80; 
    border-collapse: collapse;
    overflow-x: auto;
}
.tableRow {    
    border : 1px solid #6A768F80; 
    border-collapse: collapse;
    overflow-x: auto;
}
.tabelHeadingImg {
    width : 10%;
    border : 1px solid #6A768F80; 
    border-collapse: collapse;
    text-align: center;
}
.tabelHeading{
    width : 27.3%;
    border : 1px solid #6A768F80; 
    border-collapse: collapse;
    text-align: center;
}
.tabelDescAndRowAfterMapping{
    /* width : 100%; */
    border : 1px solid #6A768F80; 
    border-collapse: collapse;
}
.tableDesc{
    text-align: center;
    font-family: 'Roboto';
    font-weight: 500;
}
.tabelHeadingcsdcl{
    width : 12%;
    border : 1px solid #6A768F80; 
    border-collapse: collapse;
    text-align: center;
}
.tabelHeadingcsdpl{
    width : 15%;
    border : 1px solid #6A768F80; 
    border-collapse: collapse;
    text-align: center;

}

.careSiteDetailsButn {
    box-shadow: #6A768F80 0px 3px 6px ;
    cursor : pointer ;
    color: #36434B ;
    /* text-transform: capitalize ; */
    margin-top: 30px ;
    background-color: white ;
    /* width: ; */
    margin-right : 44px;
    padding: 10px;
    border-radius: 5px;
}
 @media screen and (max-width: 768px ){
    .caresitesettingdropdownWrapper{
       padding-top: 160px;
    }
  }


  @media screen and (min-width: 1421px) and (max-width: 1620px) {
    .careSiteDetailsButn{
        margin-right : 22px;
    }
    }

    @media screen and (min-width: 1621px) and (max-width: 1820px) {
        .careSiteDetailsButn{
            margin-right : 15px;
        }
        }

    @media screen and (min-width: 1821px) and (max-width: 1920px) {
        .careSiteDetailsButn{
            margin-right : -15px;
        }
        }
    @media screen and (min-width: 900px) and (max-width: 1120px) {
        .careSiteDetailsButn{
            margin-left : 64px;
            margin-right: 0px;
            padding: 16px;
            margin-top: 23px;
        }
        }

        .inActiveHedingTitle{
            color: #6a768f;
            text-align: center;
            position: relative;
        }
        .bottomContainerForAlert{
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            border-top: 2px solid #dbdbdd;
        }
        .vl{
            border-left: 2px solid #dbdbdd;
            height : 50px;
        }
        .cancelButtonRevokeAlert{
            color: #3d446f !important;
            font-size : 24px !important;
            text-transform: capitalize !important;
            font-family: "Roboto" !important;
            font-weight: 600 !important;
            border-right: 5px solid #0000;
            margin-bottom: 10px !important;
            margin-top: 10px !important;
            outline: none !important;
        }
        
