.care-site-details .CareSiteLogo {
  height: 230px;
  width: 230px;
}
.care-site-details .bottomContainerInFooterCard {
  display: flex;
  background-color: white;
  box-shadow: #6a768f80 0px 3px 6px;
  /* margin-right: 40px; */
  margin-left: 0px;
  margin-top: 40px;
  margin-bottom: 100px;
  border-radius: 10px;
  background-image: url("../../assets/icons/helpcenter/blueDesign.png");
  background-repeat: no-repeat;
  /* background-size: 350px 350px; */
  background-position: right top;
  width: 100%;
}
.care-site-details .link_helpInFooterCard {
  color: #f26930;
}
.care-site-details .ClinicNameHeading {
  color: #6a768f;
  font-weight: 600;
  font-family: "Roboto";
}
.care-site-details .bottomSectionContainer1InFooterCard {
  display: flex;
  margin-top: 14px;
}
.care-site-details .EmailIconInFooterCard {
  background-color: white;
  padding: 4px;
  border-radius: 5px;
  margin-right: 15px;
  box-shadow: #6a768f80 0px 3px 6px;
}
.care-site-details .customservice {
  align-self: center;
  color: #3d446f;
  font-family: "Roboto";
}
.care-site-details .linkservice {
  align-self: center;
  color: #3d446f;
  font-family: "Roboto";
  margin-bottom: 12px;
}
.care-site-details .websiteIconInFooterIcon {
  background-color: white;
  padding: 3px;
  border-radius: 5px;
  margin-right: 15px;
  box-shadow: #6a768f80 0px 3px 6px;
}
.care-site-details .ABMLogocontainerInFooterCard {
  width: 40%;
  /* margin: 34px 0 32px 56px; */
}
.care-site-details .CareSiteDetailsContainer {
  width: 60%;
  margin: 27px 0px 0px 0px;
  padding-bottom: 40px;
}
.care-site-details .addressContainer {
  color: #3d446f;
  font-family: "Roboto";
  margin-top: 4px;
}
.care-site-details .phoneIconInFooterCard {
  background-color: white;
  padding: 3px;
  border-radius: 5px;
  margin-right: 15px;
  box-shadow: #6a768f80 0px 3px 6px;
}
.care-site-details .LinkIcon {
  background-color: white;
  padding: 3px;
  border-radius: 5px;
  margin-right: 15px;
  box-shadow: #6a768f80 0px 3px 6px;
  margin-bottom: 12px;
}
