  .section2 {
    color: white;
    padding-right: 20px;
    
  }
  .section3 {
    color : white;
  margin-left: 650px;
  padding-left: 20px;
  
    
  }
  .roboto-family{
    font-family: "Roboto";
  }

  .navbarWrapper{
    width : 100%;
    margin-left:250px;
  }

  @media screen and (max-width: 767px ){
    .navbarWrapper{
      margin-left:100px;
    }
  }



 



