.fnt48P {
  color: #3d446f;
  font-family: Roboto;
  font-size: 32px;
}
.fnt32P {
  font-size: 16px;
}
.fnt28P {
  font-size: 16px;
}
.fnt24P {
  font-size: 16px;
}
.fnt21P {
  font-size: 10px;
}
.fnt8P {
  font-size: 8px;
}

@media screen and (min-width: 1024px) {
  .fnt116P {
    font-size: 61px;
  }
  .fnt64P {
    font-size: 29.16px;
  }
  .fnt48P {
    font-size: 25.59px;
  }

  .fnt40P {
    font-size: 17.59px;
  }

  .fnt32P {
    font-size: 17.06px;
  }

  .fnt28P {
    font-size: 14.924px;
  }

  .fnt24P {
    font-size: 12.792px;
  }

  .fnt21P {
    font-size: 11.2px;
  }
  .fnt22P {
    font-size: 11px;
  }
  .fnt8P {
    font-size: 8px;
  }
}

@media screen and (min-width: 1280px) {
  .fnt116P {
    font-size: 77px;
  }
  .fnt64P {
    font-size: 42.88px;
  }
  .fnt48P {
    font-size: 31.68px;
  }

  .fnt40P {
    font-size: 23.68px;
  }


  .fnt32P {
    font-size: 21.12px;
  }

  .fnt28P {
    font-size: 18.48px;
  }

  .fnt24P {
    font-size: 15.84px;
  }

  .fnt21P {
    font-size: 13.86px;
  }
  .fnt22P {
    font-size: 14px;
  }
  .fnt8P {
    font-size: 8px;
  }
}

@media screen and (min-width: 1440px) {
  .fnt116P {
    font-size: 87px;
  }
  .fnt64P {
    font-size: 48px;
  }
  .fnt48P {
    font-size: 36px;
  }
  .fnt40P {
    font-size: 28px;
  }

  .fnt32P {
    font-size: 24px;
  }

  .fnt28P {
    font-size: 21px;
  }

  .fnt24P {
    font-size: 18px;
  }

  .fnt21P {
    font-size: 15.75px;
  }
  .fnt22P {
    font-size: 16px;
  }
  .fnt8P {
    font-size: 8px;
  }
}

@media screen and (min-width: 1920px) {
  .fnt116P {
    font-size: 116px;
  }
  .fnt64P {
    font-size: 64px;
  }
  .fnt48P {
    font-size: 48px;
  }
  .fnt40P {
    font-size: 40px;
  }
  .fnt32P {
    font-size: 32px;
  }

  .fnt28P {
    font-size: 28px;
  }

  .fnt24P {
    font-size: 24px;
  }

  .fnt21P {
    font-size: 21px;
  }
  .fnt22P {
    font-size: 22px;
  }
  .fnt8P {
    font-size: 8px;
  }
}
