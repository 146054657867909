.card-wrapper-line {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.clinic-card-wrapper {
  border: 1px solid lightgray;
  width: 100%;
  margin-right: 2%;
  margin-left: 2%;
  height: 200px;
  background: white;
  border-radius: 8px;
}
/* .tableContainer{
    background-color: red !important;
} */
.patient-card-wrapper {
  border: 1px solid lightgray;
  width: 100%;
  /* margin-right: 2%;
    margin-left: 2%; */
  height: 200px;
  background: white;
  border-radius: 8px;
}

.clinician-card-wrapper {
  border: 1px solid lightgray;
  width: 100%;
  margin-right: 2%;
  margin-left: 2%;
  height: 200px;
  background: white;
  border-radius: 8px;
}
.cliniTable-wrapper {
  border: 1px solid lightgray;
  width: 96%;
  height: 100%;
  margin-right: 2%;
  margin-top: 2%;
  margin-left: 2%;
  background: white;
  border-radius: 4px;
}
.refresh-button-wrapper {
  border: 1px solid gray;
  border-radius: 27px;
  width: 55px;
  height: 42px;
  padding: 7px 12px 5px 15px;
  margin-right: 8px;
}

/* .refresh-button-wrapper :hover {
    border: 1px solid rgb(128, 118, 118);
    border-radius: 27px;
   
    background: gray;
    color: white;
    margin-right:-20px;
} */

.list-button-wrapper {
  border: 1px solid gray;
  border-radius: 27px;
  width: 55px;
  height: 42px;
  padding: 7px 12px 5px 15px;
  margin-right: 8px;
}

.tablehover {
  background: white;
}

.tablehover :hover {
  background: lightgray;
}

.caresiteWrapper {
  /* font-family:  Roboto; */
  font-weight: bold;
  text-align: left;
  color: #3d446f;
  opacity: 1;
  margin-left: 67px;
  font-size: 27px;
  margin-bottom: 40px;
}
.dropdownWrapper {
  margin-left: 82px;
  margin-right: 81px;
}

.dropdownWrapper .MuiSelect-select.MuiSelect-select {
  width: 347px;
  /* padding: 18px 880px 17px 64px; */
  color: #6a768f;
  background-color: white;
  font-weight: 500;
}

.MuiPaper-root
  .MuiMenu-paper
  .MuiPopover-paper
  .MuiPaper-elevation8
  .MuiPaper-rounded {
  background: rgb(242, 244, 248);
  opacity: 1;
  transform: none;
  min-width: 1291px;
  max-height: 224px;
  width: 250px;
  transition: opacity 280ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    transform 186ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  top: 192px;
  left: 339px;
  transform-origin: 159px 62px;
}

.CardsWrapper {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  /* margin-right: 30px;
    margin-left: 30px; */
  /* width: 100%; */
}

@media screen and (max-width: 1026px) {
  .CardsWrapper {
    flex-direction: column;
    justify-content: center;
  }
}
.PatientslistHeadingWrapper {
  font-family:  'Roboto';
  text-align: left;
  color: #3d446f;
  opacity: 1;
  margin-left: 82px;
  padding-bottom: 16px;
  font-weight: 600;
  /* background-color: white; */
}

.searchbarWrapperSuperadmin{
  padding-right: 10px;
    width: 50%;
    margin-left: 48px;
    background: white;
    padding-bottom: 16px
}


/* .addclinic {
  background-color: #6a768f;
} */
.inputwrapper {
  border: none;

  border-bottom: 1px solid;

  margin-bottom: 20px;

  outline: none !important;

  font-size: large;
  margin-right: 10px !important;
  width: 100%;
  padding: 20px;
  border-color: #aaaaac;

  box-shadow: none;
  background-color: #f4f4fc !important;
  outline-offset: unset;
}
.careSiteHeading {
  display: flex;
  justify-content: flex-start;
  margin-left: 64px;
}

.searchbar-report_caresite_settings {
  margin-left: 65px;
  padding: 20px;
  display: flex;
  justify-content: flex-start;
  position: relative;
}

.report_search_icon_caresite_settings {
  position: absolute;
  z-index: 1;
  top: 35%;
  padding-left: 15px;
}

.report_search_caresite_settings {
  width: 95%;
  position: relative;
  top: 0px;
  left: 0px;
  text-align: left;
  border-radius: 8px;
  border: none;
  box-shadow: #6a768f80 0px 3px 6px;
}
.report_search_caresite_settings::placeholder {
  padding-left: 5%;
}

.careprofileLabel {
  /* width: 188px;
  height: 29px; */
  text-align: left;
  letter-spacing: 0px;
  color: #6a768f;
  opacity: 1; 
  
  padding-top: 8px;
  font-size: 17px;
}
.careprofileLabel2 {
  /* width: 188px;
  height: 29px; */
  text-align: left;
  letter-spacing: 0px;
  color: #6a768f;
  opacity: 1; 
  padding: 5px;
 
  font-size: 17px;
}
.clinicProfileTitle {
  background: #f2f4f8;
  margin-bottom: 20px;
  margin-left: 30px;
  text-align: left;
}
.clinicProfileWebsiteLabel {
  width: 188px;
  height: 29px;
  text-align: left;
  letter-spacing: 0px;
  color: #6a768f;
  opacity: 1;
  outline: none;
  margin-top: -8px;
  font-size: 17px;
}
.formBackground {
  background: url("../../../assets/img/caresiteBgImage.png");
  /* width: 570px; */
  padding-right: 90px;

}
.inputfeildsforallProfile {
  border: none;
  width: 100%;
  border-bottom: 1px solid #8a8e95;
  /* padding: 5px 10px; */
  outline: none !important;
  background-color: transparent;
 
  box-shadow: none;
  outline-offset: unset;
 
  font-size: 19px;
}
.inputfeildsforallcareProfile1:focus {
  outline: transparent !important;
}
.inputfeildsforallcareProfile2:focus {
  outline: transparent !important;
}
.inputfeildsforallcare1 {
  border: none;
  border-bottom: 1px solid #8a8e95;
  padding: 5px 0px 5px 0px;
  
  outline: none !important;
  background-color: transparent;
  width: 100%;
  box-shadow: none;
  outline-offset: unset;

}


.inputfeildsforallcarephone{
  width:32% !important;
  margin-right: 20px;
  display: flex;
flex-direction: row;
justify-content: center;


}

.inputfeildsforallcarecountry {
  border: none;
  padding: 5px 0px 5px 0px;
  outline: none !important;
  /* background-color: #f2f4f8; */
 
  width: 100%;
  box-shadow: none;
  outline-offset: unset;
  margin-bottom: 10px;
}
.inputfeildsforallcare2 {
  border: none;
  border-bottom: 1px solid #8a8e95;
  padding: 5px 0px 5px 0px;
  /* margin-left:56px ; */
  outline: none !important;
  background-color: transparent;
  width: 100%;
  box-shadow: none;
  outline-offset: unset;
  /* margin-bottom: 10px; */
}
.clinicprofileWebsiteInputFeilds {
  width: 100%;
  height: 173px;
  background-color: #f2f4f8;
  padding: 0px 15px;
  border-color: #8a8e95;
  margin-bottom: 10px;
}
.updatebuttonCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  box-shadow: 1px 2px 0px 5 10px #0000;
}
.buttonforUpdate {
  border: none;
  height: 70px;
  width: 50%;
  box-shadow: 0px 0px 4px 0px #8a8e95;
  border-radius: 7px !important;
  background-color: #ffffff !important;
  text-transform: capitalize !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  outline: none !important;
  color: #3d446f;
}
InputField:focus {
  outline: none;
}
.nextButtonnnn00 {
  top: 706px;
  left: 1151px;
  width: 470px;
  height: 71px;
  background: white 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 10px #6a768f38;
  border-radius: 8px;
  opacity: 1;
  /* margin: 64px 579px 1151pz 706px; */
  margin-top: 0px;
  margin-left: 0px;
  margin-right: 0px;
}

.dialogwrapper .MuiDialog-paperWidthMd {
 width: 100%;
 height: 76%;
  background-color: #f2f4f8;
  
}

.dialogHeadingwrapper .MuiTypography-h6 {
  font-size: 26px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.0075em;
  background:#f2f4f8 ;
  color: #3D446F;
  padding-left: 50px;
  padding-right: 0px;
}
/* .dialogHeadingwrapper{
 
} */
.close-addcaresite{
  position:absolute;
  right: 60px;
}
.abmaccount {
  margin-top: -15px;
}
.abmaccount1 {
  margin-top: -14px;
}
@media screen and (max-width:1024px) {
  .formBackground {
  justify-content: center;
  align-items: center;
  padding-right: 0px;
  padding-left: 30px;
  
  }
  .inputfeildsforallcare2{
    width: 100%;
    padding: 0px 0px;
    margin-left:-2px ;
  }
  .inputfeildsforallcarecountry{
    width: 100%;
    padding: 0px 0px;
    margin-left:-2px ;
  }
  .careprofileLabel2 {
   
    padding: 5px 2px;
   

  }
  .inputfeildsforallcare1 {
   
    width: 100%;

  
  }
  .inputfeildsforallcarephone{
    width:100% !important;
    margin-right: 20px;
    display: flex;
  flex-direction: row;
  justify-content: center;
  
  
  }
  .inputfeildsforallProfile {
    border: none;
    width: 100%;
    border-bottom: 1px solid #8a8e95;
    /* padding: 5px 10px; */
    outline: none !important;
    background-color: transparent;
    background: url("../../../assets/img/caresiteBgImage.png");
    box-shadow: none;
    outline-offset: unset;
   
    font-size: 19px;
  }
  .inputfeildsforallcarephone{
    width: 100% !important;
  }
}
@media screen and (max-width: 768px ){
  .careSiteHeading{
     padding-top: 160px;
      /* margin-left: 83px; */
      /* margin-right: 64px; */
  }
}

.MuiTableCell-root.MuiTableCell-head.abmDashboardImageHeading{
    background-color: #6a768f;
    /* color: white; */
    height: 92px;
    font-size: 22px;
    font-weight: 400;
}

.MuiTableCell-root.MuiTableCell-head.abmDashboardSiteName{
  background: #6a768f !important;
  color: white;
  height: 92px;
  padding-right: 150px;
  margin-right: 20px;
  font-size:22px;
  font-weight:400;
}

.MuiTableCell-root.MuiTableCell-head.abmDashboardcity{
  background-color: #6a768f;
  color: white;
  height: 92px;
  padding-right: 80px;
  font-size: 22px;
  font-weight: 400;
}
.MuiTableCell-root.MuiTableCell-head.abmDashboardState{
    background-color: #6a768f;
    color: white;
    height: 92px;
    padding-right: 80px;
    font-size: 22px;
    font-weight: 400;
}

.MuiTableCell-root.MuiTableCell-head.abmDashboardZip{
    background-color: #6a768f;
    color: white;
    height: 92px;
    font-size: 22px;
    font-weight: 400;
}

.abmdashboardImageList{
  padding-left: 85px;
}
.abmdashboardClinicList{
     color: #3D446F; 
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
}
.abmdashboardcityList{
    color: #3D446F;
    font-size: 16px;
    font-weight:500;
}

.MuiTableCell-root {
  display: table-cell;
  padding: 14px;
  font-size: 0.875rem;
  text-align: left;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.43;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  letter-spacing: 0.01071em;
  vertical-align: inherit;
}

@media screen and (max-width: 1058px ) {

.MuiTableCell-root.MuiTableCell-head.abmDashboardSiteName{
  background: #6a768f !important;
  color: white;
  height: 92px;
  padding-right: 150px;
  margin-right: 20px;
  font-size:20px;
  font-weight:400;
}

.MuiTableCell-root.MuiTableCell-head.abmDashboardcity{
  background-color: #6a768f;
  color: white;
  height: 92px;
  padding-right: 80px;
  font-size: 20px;
  font-weight: 400;
}
.MuiTableCell-root.MuiTableCell-head.abmDashboardState{
    background-color: #6a768f;
    color: white;
    height: 92px;
    padding-right: 80px;
    font-size: 20px;
    font-weight: 400;
}

.MuiTableCell-root.MuiTableCell-head.abmDashboardZip{
    background-color: #6a768f;
    color: white;
    height: 92px;
    font-size: 20px;
    font-weight: 400;
}

.abmdashboardClinicList{
  color: #3D446F; 
 cursor: pointer;
 font-size: 15;
 font-weight: 500;
}
.abmdashboardcityList{
 color: #3D446F;
 font-size: 15px;
 font-weight:500;
}
}

@media screen and (max-width: 1040px ) {

  .MuiTableCell-root.MuiTableCell-head.abmDashboardSiteName{
    background: #6a768f !important;
    color: white;
    height: 92px;
    padding-right: 150px;
    margin-right: 20px;
    font-size:18px;
    font-weight:400;
  }
  
  .MuiTableCell-root.MuiTableCell-head.abmDashboardcity{
    background-color: #6a768f;
    color: white;
    height: 92px;
    padding-right: 80px;
    font-size: 18px;
    font-weight: 400;
  }
  .MuiTableCell-root.MuiTableCell-head.abmDashboardState{
      background-color: #6a768f;
      color: white;
      height: 92px;
      padding-right: 80px;
      font-size: 18px;
      font-weight: 400;
  }
  
  .MuiTableCell-root.MuiTableCell-head.abmDashboardZip{
      background-color: #6a768f;
      color: white;
      height: 92px;
      font-size: 18px;
      font-weight: 400;
  }

  .abmdashboardClinicList{
    color: #3D446F; 
   cursor: pointer;
   font-size: 14px;
   font-weight: 500;
}
.abmdashboardcityList{
   color: #3D446F;
   font-size: 14px;
   font-weight:500;
}
  }

@media screen and (max-width: 1024px ) {

    .MuiTableCell-root.MuiTableCell-head.abmDashboardSiteName{
      background: #6a768f !important;
      color: white;
      height: 92px;
      padding-right: 150px;
      margin-right: 20px;
      font-size:17px;
      font-weight:400;
    }
    
    .MuiTableCell-root.MuiTableCell-head.abmDashboardcity{
      background-color: #6a768f;
      color: white;
      height: 92px;
      padding-right: 80px;
      font-size: 17px;
      font-weight: 400;
    }
    .MuiTableCell-root.MuiTableCell-head.abmDashboardState{
        background-color: #6a768f;
        color: white;
        height: 92px;
        padding-right: 80px;
        font-size: 17px;
        font-weight: 400;
    }
    
    .MuiTableCell-root.MuiTableCell-head.abmDashboardZip{
        background-color: #6a768f;
        color: white;
        height: 92px;
        font-size: 17px;
        font-weight: 400;
    }
  
    .abmdashboardClinicList{
      color: #3D446F; 
     cursor: pointer;
     font-size: 13px;
     font-weight: 500;
  }
  .abmdashboardcityList{
     color: #3D446F;
     font-size: 13px;
     font-weight:500;
  }
    }
@media screen and (max-width: 1014px ) {

      .MuiTableCell-root.MuiTableCell-head.abmDashboardSiteName{
        background: #6a768f !important;
        color: white;
        height: 92px;
        padding-right: 150px;
        margin-right: 20px;
        font-size:16px;
        font-weight:400;
      }
      
      .MuiTableCell-root.MuiTableCell-head.abmDashboardcity{
        background-color: #6a768f;
        color: white;
        height: 92px;
        padding-right: 80px;
        font-size: 16px;
        font-weight: 400;
      }
      .MuiTableCell-root.MuiTableCell-head.abmDashboardState{
          background-color: #6a768f;
          color: white;
          height: 92px;
          padding-right: 80px;
          font-size: 16px;
          font-weight: 400;
      }
      
      .MuiTableCell-root.MuiTableCell-head.abmDashboardZip{
          background-color: #6a768f;
          color: white;
          height: 92px;
          font-size: 16px;
          font-weight: 400;
      }
    
      .abmdashboardClinicList{
        color: #3D446F; 
       cursor: pointer;
       font-size: 12px;
       font-weight: 500;
    }
    .abmdashboardcityList{
       color: #3D446F;
       font-size: 12px;
       font-weight:500;
    }
      }
@media screen and (max-width: 1004px ) {

        .MuiTableCell-root.MuiTableCell-head.abmDashboardSiteName{
          background: #6a768f !important;
          color: white;
          height: 92px;
          padding-right: 150px;
          margin-right: 20px;
          font-size:15px;
          font-weight:400;
        }
        
        .MuiTableCell-root.MuiTableCell-head.abmDashboardcity{
          background-color: #6a768f;
          color: white;
          height: 92px;
          padding-right: 80px;
          font-size: 15px;
          font-weight: 400;
        }
        .MuiTableCell-root.MuiTableCell-head.abmDashboardState{
            background-color: #6a768f;
            color: white;
            height: 92px;
            padding-right: 80px;
            font-size: 15px;
            font-weight: 400;
        }
        
        .MuiTableCell-root.MuiTableCell-head.abmDashboardZip{
            background-color: #6a768f;
            color: white;
            height: 92px;
            font-size: 15px;
            font-weight: 400;
        }
      
        .abmdashboardClinicList{
          color: #3D446F; 
         cursor: pointer;
         font-size: 11px;
         font-weight: 500;
      }
      .abmdashboardcityList{
         color: #3D446F;
         font-size: 11px;
         font-weight:500;
      }
 } 
 
 @media screen and (max-width: 994px ) {

  .MuiTableCell-root.MuiTableCell-head.abmDashboardSiteName{
    background: #6a768f !important;
    color: white;
    height: 92px;
    padding-right: 135px;
    margin-right: 20px;
    font-size:14px;
    font-weight:400;
  }
  
  .MuiTableCell-root.MuiTableCell-head.abmDashboardcity{
    background-color: #6a768f;
    color: white;
    height: 92px;
    padding-right: 76px;
    font-size: 14px;
    font-weight: 400;
  }
  .MuiTableCell-root.MuiTableCell-head.abmDashboardState{
      background-color: #6a768f;
      color: white;
      height: 92px;
      padding-right: 76px;
      font-size: 14px;
      font-weight: 400;
  }
  
  .MuiTableCell-root.MuiTableCell-head.abmDashboardZip{
      background-color: #6a768f;
      color: white;
      height: 92px;
      font-size: 14px;
      font-weight: 400;
  }

  .abmdashboardClinicList{
    color: #3D446F; 
   cursor: pointer;
   font-size: 10px;
   font-weight: 500;
}
.abmdashboardcityList{
   color: #3D446F;
   font-size: 10px;
   font-weight:500;
}
} 

@media screen and (max-width: 970px ) {

  .MuiTableCell-root.MuiTableCell-head.abmDashboardSiteName{
    background: #6a768f !important;
    color: white;
    height: 92px;
    padding-right: 135px;
    margin-right: 20px;
    font-size:14px;
    font-weight:400;
  }
  
  .MuiTableCell-root.MuiTableCell-head.abmDashboardcity{
    background-color: #6a768f;
    color: white;
    height: 92px;
    padding-right: 74px;
    font-size: 14px;
    font-weight: 400;
  }
  .MuiTableCell-root.MuiTableCell-head.abmDashboardState{
      background-color: #6a768f;
      color: white;
      height: 92px;
      padding-right: 74px;
      font-size: 14px;
      font-weight: 400;
  }
  
  .MuiTableCell-root.MuiTableCell-head.abmDashboardZip{
      background-color: #6a768f;
      color: white;
      height: 92px;
      font-size: 14px;
      font-weight: 400;
  }

  .abmdashboardClinicList{
    color: #3D446F; 
   cursor: pointer;
   font-size: 11px;
   font-weight: 500;
}
.abmdashboardcityList{
   color: #3D446F;
   font-size: 11px;
   font-weight:500;
}
} 

@media screen and (max-width: 960px ) {

  .MuiTableCell-root.MuiTableCell-head.abmDashboardSiteName{
    background: #6a768f !important;
    color: white;
    height: 92px;
    padding-right: 126px;
    margin-right: 20px;
    font-size:14px;
    font-weight:400;
  }
  
  .MuiTableCell-root.MuiTableCell-head.abmDashboardcity{
    background-color: #6a768f;
    color: white;
    height: 92px;
    padding-right: 74px;
    font-size: 14px;
    font-weight: 400;
  }
  .MuiTableCell-root.MuiTableCell-head.abmDashboardState{
      background-color: #6a768f;
      color: white;
      height: 92px;
      padding-right: 74px;
      font-size: 14px;
      font-weight: 400;
  }
  
  .MuiTableCell-root.MuiTableCell-head.abmDashboardZip{
      background-color: #6a768f;
      color: white;
      height: 92px;
      font-size: 14px;
      font-weight: 400;
  }

  .abmdashboardClinicList{
    color: #3D446F; 
   cursor: pointer;
   font-size: 11px;
   font-weight: 500;
}
.abmdashboardcityList{
   color: #3D446F;
   font-size: 11px;
   font-weight:500;
}
}

@media screen and (max-width: 950px ) {

  .MuiTableCell-root.MuiTableCell-head.abmDashboardSiteName{
    background: #6a768f !important;
    color: white;
    height: 92px;
    padding-right: 106px;
    margin-right: 20px;
    font-size:14px;
    font-weight:400;
  }
  
  .MuiTableCell-root.MuiTableCell-head.abmDashboardcity{
    background-color: #6a768f;
    color: white;
    height: 92px;
    padding-right: 74px;
    font-size: 14px;
    font-weight: 400;
  }
  .MuiTableCell-root.MuiTableCell-head.abmDashboardState{
      background-color: #6a768f;
      color: white;
      height: 92px;
      padding-right: 74px;
      font-size: 14px;
      font-weight: 400;
  }
  
  .MuiTableCell-root.MuiTableCell-head.abmDashboardZip{
      background-color: #6a768f;
      color: white;
      height: 92px;
      font-size: 14px;
      font-weight: 400;
  }

  .abmdashboardClinicList{
    color: #3D446F; 
   cursor: pointer;
   font-size: 11px;
   font-weight: 500;
}
.abmdashboardcityList{
   color: #3D446F;
   font-size: 11px;
   font-weight:500;
}
}

@media screen and (max-width: 930px ) {

  .MuiTableCell-root.MuiTableCell-head.abmDashboardSiteName{
    background: #6a768f !important;
    color: white;
    height: 92px;
    padding-right: 96px;
    margin-right: 20px;
    font-size:14px;
    font-weight:400;
  }
  
  .MuiTableCell-root.MuiTableCell-head.abmDashboardcity{
    background-color: #6a768f;
    color: white;
    height: 92px;
    padding-right: 74px;
    font-size: 14px;
    font-weight: 400;
  }
  .MuiTableCell-root.MuiTableCell-head.abmDashboardState{
      background-color: #6a768f;
      color: white;
      height: 92px;
      padding-right: 74px;
      font-size: 14px;
      font-weight: 400;
  }
  
  .MuiTableCell-root.MuiTableCell-head.abmDashboardZip{
      background-color: #6a768f;
      color: white;
      height: 92px;
      font-size: 14px;
      font-weight: 400;
  }

  .abmdashboardClinicList{
    color: #3D446F; 
   cursor: pointer;
   font-size: 11px;
   font-weight: 500;
}
.abmdashboardcityList{
   color: #3D446F;
   font-size: 11px;
   font-weight:500;
}
}

@media screen and (max-width: 910px ) {

  .MuiTableCell-root.MuiTableCell-head.abmDashboardSiteName{
    background: #6a768f !important;
    color: white;
    height: 92px;
    padding-right: 72px;
    margin-right: 20px;
    font-size:14px;
    font-weight:400;
  }
  
  .MuiTableCell-root.MuiTableCell-head.abmDashboardcity{
    background-color: #6a768f;
    color: white;
    height: 92px;
    padding-right: 74px;
    font-size: 14px;
    font-weight: 400;
  }
  .MuiTableCell-root.MuiTableCell-head.abmDashboardState{
      background-color: #6a768f;
      color: white;
      height: 92px;
      padding-right: 74px;
      font-size: 14px;
      font-weight: 400;
  }
  
  .MuiTableCell-root.MuiTableCell-head.abmDashboardZip{
      background-color: #6a768f;
      color: white;
      height: 92px;
      font-size: 14px;
      font-weight: 400;
  }

  .abmdashboardClinicList{
    color: #3D446F; 
   cursor: pointer;
   font-size: 11px;
   font-weight: 500;
}
.abmdashboardcityList{
   color: #3D446F;
   font-size: 11px;
   font-weight:500;
}
}

@media screen and (max-width: 889px ) {

  .MuiTableCell-root.MuiTableCell-head.abmDashboardSiteName{
    background: #6a768f !important;
    color: white;
    height: 92px;
    padding-right: 58px;
    margin-right: 20px;
    font-size:14px;
    font-weight:400;
  }
  
  .MuiTableCell-root.MuiTableCell-head.abmDashboardcity{
    background-color: #6a768f;
    color: white;
    height: 92px;
    padding-right: 74px;
    font-size: 14px;
    font-weight: 400;
  }
  .MuiTableCell-root.MuiTableCell-head.abmDashboardState{
      background-color: #6a768f;
      color: white;
      height: 92px;
      padding-right: 74px;
      font-size: 14px;
      font-weight: 400;
  }
  
  .MuiTableCell-root.MuiTableCell-head.abmDashboardZip{
      background-color: #6a768f;
      color: white;
      height: 92px;
      font-size: 14px;
      font-weight: 400;
  }

  .abmdashboardClinicList{
    color: #3D446F; 
   cursor: pointer;
   font-size: 11px;
   font-weight: 500;
}
.abmdashboardcityList{
   color: #3D446F;
   font-size: 11px;
   font-weight:500;
}
}

@media screen and (max-width: 869px ) {

  .MuiTableCell-root.MuiTableCell-head.abmDashboardSiteName{
    background: #6a768f !important;
    color: white;
    height: 92px;
    padding-right: 50px;
    margin-right: 20px;
    font-size:13px;
    font-weight:400;
  }
  
  .MuiTableCell-root.MuiTableCell-head.abmDashboardcity{
    background-color: #6a768f;
    color: white;
    height: 92px;
    padding-right: 70px;
    font-size: 13px;
    font-weight: 400;
  }
  .MuiTableCell-root.MuiTableCell-head.abmDashboardState{
      background-color: #6a768f;
      color: white;
      height: 92px;
      padding-right: 74px;
      font-size: 13px;
      font-weight: 400;
  }
  
  .MuiTableCell-root.MuiTableCell-head.abmDashboardZip{
      background-color: #6a768f;
      color: white;
      height: 92px;
      font-size: 13px;
      font-weight: 400;
  }

  .abmdashboardClinicList{
    color: #3D446F; 
   cursor: pointer;
   font-size: 11px;
   font-weight: 500;
}
.abmdashboardcityList{
   color: #3D446F;
   font-size: 11px;
   font-weight:500;
}
}

@media screen and (max-width: 849px ) {

  .MuiTableCell-root.MuiTableCell-head.abmDashboardSiteName{
    background: #6a768f !important;
    color: white;
    height: 92px;
    padding-right: 49px;
    margin-right: 20px;
    font-size:13px;
    font-weight:400;
  }
  
  .MuiTableCell-root.MuiTableCell-head.abmDashboardcity{
    background-color: #6a768f;
    color: white;
    height: 92px;
    padding-right: 58px;
    font-size: 13px;
    font-weight: 400;
  }
  .MuiTableCell-root.MuiTableCell-head.abmDashboardState{
      background-color: #6a768f;
      color: white;
      height: 92px;
      padding-right: 74px;
      font-size: 13px;
      font-weight: 400;
  }
  
  .MuiTableCell-root.MuiTableCell-head.abmDashboardZip{
      background-color: #6a768f;
      color: white;
      height: 92px;
      font-size: 13px;
      font-weight: 400;
  }

  .abmdashboardClinicList{
    color: #3D446F; 
   cursor: pointer;
   font-size: 11px;
   font-weight: 500;
}
.abmdashboardcityList{
   color: #3D446F;
   font-size: 11px;
   font-weight:500;
}
}

@media screen and (max-width: 837px ) {

  .MuiTableCell-root.MuiTableCell-head.abmDashboardSiteName{
    background: #6a768f !important;
    color: white;
    height: 92px;
    padding-right: 49px;
    margin-right: 20px;
    font-size:12px;
    font-weight:400;
  }
  
  .MuiTableCell-root.MuiTableCell-head.abmDashboardcity{
    background-color: #6a768f;
    color: white;
    height: 92px;
    padding-right: 58px;
    font-size: 12px;
    font-weight: 400;
  }
  .MuiTableCell-root.MuiTableCell-head.abmDashboardState{
      background-color: #6a768f;
      color: white;
      height: 92px;
      padding-right: 70px;
      font-size: 12px;
      font-weight: 400;
  }
  
  .MuiTableCell-root.MuiTableCell-head.abmDashboardZip{
      background-color: #6a768f;
      color: white;
      height: 92px;
      font-size: 12px;
      font-weight: 400;
  }

  .abmdashboardClinicList{
    color: #3D446F; 
   cursor: pointer;
   font-size: 11px;
   font-weight: 500;
}
.abmdashboardcityList{
   color: #3D446F;
   font-size: 11px;
   font-weight:500;
}
}

@media screen and (max-width: 817px ) {

  .MuiTableCell-root.MuiTableCell-head.abmDashboardSiteName{
    background: #6a768f !important;
    color: white;
    height: 92px;
    padding-right: 49px;
    margin-right: 20px;
    font-size:12px;
    font-weight:400;
  }
  
  .MuiTableCell-root.MuiTableCell-head.abmDashboardcity{
    background-color: #6a768f;
    color: white;
    height: 92px;
    padding-right: 58px;
    font-size: 12px;
    font-weight: 400;
  }
  .MuiTableCell-root.MuiTableCell-head.abmDashboardState{
      background-color: #6a768f;
      color: white;
      height: 92px;
      padding-right: 70px;
      font-size: 12px;
      font-weight: 400;
  }
  
  .MuiTableCell-root.MuiTableCell-head.abmDashboardZip{
      background-color: #6a768f;
      color: white;
      height: 92px;
      font-size: 12px;
      font-weight: 400;
  }

  .abmdashboardImageList{
    padding-left: 56px;
  }

  .abmdashboardClinicList{
    color: #3D446F; 
   cursor: pointer;
   font-size: 11px;
   font-weight: 500;
}
.abmdashboardcityList{
   color: #3D446F;
   font-size: 11px;
   font-weight:500;
}
}

@media screen and (max-width: 796px ) {

  .MuiTableCell-root.MuiTableCell-head.abmDashboardSiteName{
    background: #6a768f !important;
    color: white;
    height: 92px;
    padding-right: 39px;
    margin-right: 20px;
    font-size:12px;
    font-weight:400;
  }
  
  .MuiTableCell-root.MuiTableCell-head.abmDashboardcity{
    background-color: #6a768f;
    color: white;
    height: 92px;
    padding-right: 48px;
    font-size: 12px;
    font-weight: 400;
  }
  .MuiTableCell-root.MuiTableCell-head.abmDashboardState{
      background-color: #6a768f;
      color: white;
      height: 92px;
      padding-right: 70px;
      font-size: 12px;
      font-weight: 400;
  }
  
  .MuiTableCell-root.MuiTableCell-head.abmDashboardZip{
      background-color: #6a768f;
      color: white;
      height: 92px;
      font-size: 12px;
      font-weight: 400;
  }

  .abmdashboardImageList{
    padding-left: 40px;
  }

  .abmdashboardClinicList{
    color: #3D446F; 
   cursor: pointer;
   font-size: 11px;
   font-weight: 500;
}
.abmdashboardcityList{
   color: #3D446F;
   font-size: 11px;
   font-weight:500;
}
}

@media screen and (max-width: 773px ) {

  .MuiTableCell-root.MuiTableCell-head.abmDashboardSiteName{
    background: #6a768f !important;
    color: white;
    height: 92px;
    padding-right: 29px;
    margin-right: 20px;
    font-size:12px;
    font-weight:400;
  }
  
  .MuiTableCell-root.MuiTableCell-head.abmDashboardcity{
    background-color: #6a768f;
    color: white;
    height: 92px;
    padding-right: 38px;
    font-size: 12px;
    font-weight: 400;
  }
  .MuiTableCell-root.MuiTableCell-head.abmDashboardState{
      background-color: #6a768f;
      color: white;
      height: 92px;
      padding-right: 70px;
      font-size: 12px;
      font-weight: 400;
  }
  
  .MuiTableCell-root.MuiTableCell-head.abmDashboardZip{
      background-color: #6a768f;
      color: white;
      height: 92px;
      font-size: 12px;
      font-weight: 400;
  }

  .abmdashboardImageList{
    padding-left: 30px;
  }

  .abmdashboardClinicList{
    color: #3D446F; 
   cursor: pointer;
   font-size: 11px;
   font-weight: 500;
}
.abmdashboardcityList{
   color: #3D446F;
   font-size: 11px;
   font-weight:500;
}
}

@media screen and (max-width: 490px ) {

  .MuiTableCell-root.MuiTableCell-head.abmDashboardSiteName{
    background: #6a768f !important;
    color: white;
    height: 92px;
    padding-right: 20px;
    margin-right: 20px;
    font-size:12px;
    font-weight:400;
  }
  
  .MuiTableCell-root.MuiTableCell-head.abmDashboardcity{
    background-color: #6a768f;
    color: white;
    height: 92px;
    padding-right: 30px;
    font-size: 12px;
    font-weight: 400;
  }
  .MuiTableCell-root.MuiTableCell-head.abmDashboardState{
      background-color: #6a768f;
      color: white;
      height: 92px;
      padding-right: 70px;
      font-size: 12px;
      font-weight: 400;
  }
  
  .MuiTableCell-root.MuiTableCell-head.abmDashboardZip{
      background-color: #6a768f;
      color: white;
      height: 92px;
      font-size: 12px;
      font-weight: 400;
  }

  .abmdashboardImageList{
    padding-left: 20px;
  }

  .abmdashboardClinicList{
    color: #3D446F; 
   cursor: pointer;
   font-size: 11px;
   font-weight: 500;
}
.abmdashboardcityList{
   color: #3D446F;
   font-size: 11px;
   font-weight:500;
}
}

@media screen and (max-width: 475px ) {

  .MuiTableCell-root.MuiTableCell-head.abmDashboardSiteName{
    background: #6a768f !important;
    color: white;
    height: 92px;
    padding-right: 20px;
    margin-right: 20px;
    font-size:11px;
    font-weight:400;
  }
  
  .MuiTableCell-root.MuiTableCell-head.abmDashboardcity{
    background-color: #6a768f;
    color: white;
    height: 92px;
    padding-right: 30px;
    font-size: 11px;
    font-weight: 400;
  }
  .MuiTableCell-root.MuiTableCell-head.abmDashboardState{
      background-color: #6a768f;
      color: white;
      height: 92px;
      padding-right: 70px;
      font-size: 11px;
      font-weight: 400;
  }
  
  .MuiTableCell-root.MuiTableCell-head.abmDashboardZip{
      background-color: #6a768f;
      color: white;
      height: 92px;
      font-size: 11px;
      font-weight: 400;
  }

  .abmdashboardImageList{
    padding-left: 20px;
  }

  .abmdashboardClinicList{
    color: #3D446F; 
   cursor: pointer;
   font-size: 10px;
   font-weight: 500;
}
.abmdashboardcityList{
   color: #3D446F;
   font-size: 10px;
   font-weight:500;
}
}

@media screen and (max-width: 465px ) {
  .MuiAvatar-root.MuiAvatar-circular.MuiAvatar-colorDefault.abmdashboardImageListWrapper{
    width: 30px;
    height: 30px;
  }

  .MuiTableCell-root.MuiTableCell-head.abmDashboardSiteName{
    background: #6a768f !important;
    color: white;
    height: 92px;
    padding-right: 20px;
    margin-right: 20px;
    font-size:11px;
    font-weight:400;
  }
  
  .MuiTableCell-root.MuiTableCell-head.abmDashboardcity{
    background-color: #6a768f;
    color: white;
    height: 92px;
    padding-right: 30px;
    font-size: 11px;
    font-weight: 400;
  }
  .MuiTableCell-root.MuiTableCell-head.abmDashboardState{
      background-color: #6a768f;
      color: white;
      height: 92px;
      padding-right: 70px;
      font-size: 11px;
      font-weight: 400;
  }
  
  .MuiTableCell-root.MuiTableCell-head.abmDashboardZip{
      background-color: #6a768f;
      color: white;
      height: 92px;
      font-size: 11px;
      font-weight: 400;
  }

  .abmdashboardImageList{
    padding-left: 20px;
  }

  .abmdashboardClinicList{
    color: #3D446F; 
   cursor: pointer;
   font-size: 10px;
   font-weight: 500;
}
.abmdashboardcityList{
   color: #3D446F;
   font-size: 10px;
   font-weight:500;
}
}

@media screen and (max-width: 445px ) {
  .MuiAvatar-root.MuiAvatar-circular.MuiAvatar-colorDefault.abmdashboardImageListWrapper{
    width: 30px;
    height: 30px;
  }

  .MuiTableCell-root.MuiTableCell-head.abmDashboardSiteName{
    background: #6a768f !important;
    color: white;
    height: 92px;
    padding-right: 10px;
    margin-right: 20px;
    font-size:11px;
    font-weight:400;
  }
  
  .MuiTableCell-root.MuiTableCell-head.abmDashboardcity{
    background-color: #6a768f;
    color: white;
    height: 92px;
    padding-right: 20px;
    font-size: 11px;
    font-weight: 400;
  }
  .MuiTableCell-root.MuiTableCell-head.abmDashboardState{
      background-color: #6a768f;
      color: white;
      height: 92px;
      padding-right: 46px;
      font-size: 11px;
      font-weight: 400;
  }
  
  .MuiTableCell-root.MuiTableCell-head.abmDashboardZip{
      background-color: #6a768f;
      color: white;
      height: 92px;
      font-size: 11px;
      font-weight: 400;
  }

  .abmdashboardImageList{
    padding-left: 20px;
  }

  .abmdashboardClinicList{
    color: #3D446F; 
   cursor: pointer;
   font-size: 10px;
   font-weight: 500;
}
.abmdashboardcityList{
   color: #3D446F;
   font-size: 10px;
   font-weight:500;
}
}

@media screen and (max-width: 415px ) {
  .MuiAvatar-root.MuiAvatar-circular.MuiAvatar-colorDefault.abmdashboardImageListWrapper{
    width: 30px;
    height: 30px;
  }

  .MuiTableCell-root.MuiTableCell-head.abmDashboardSiteName{
    background: #6a768f !important;
    color: white;
    height: 92px;
    padding-right: 10px;
    margin-right: 20px;
    font-size:11px;
    font-weight:400;
  }
  
  .MuiTableCell-root.MuiTableCell-head.abmDashboardcity{
    background-color: #6a768f;
    color: white;
    height: 92px;
    padding-right: 20px;
    font-size: 11px;
    font-weight: 400;
  }
  .MuiTableCell-root.MuiTableCell-head.abmDashboardState{
      background-color: #6a768f;
      color: white;
      height: 92px;
      padding-right: 36px;
      font-size: 11px;
      font-weight: 400;
  }
  
  .MuiTableCell-root.MuiTableCell-head.abmDashboardZip{
      background-color: #6a768f;
      color: white;
      height: 92px;
      font-size: 11px;
      font-weight: 400;
  }

  .abmdashboardImageList{
    padding-left: 20px;
  }

  .abmdashboardClinicList{
    color: #3D446F; 
   cursor: pointer;
   font-size: 10px;
   font-weight: 500;
}
.abmdashboardcityList{
   color: #3D446F;
   font-size: 10px;
   font-weight:500;
}
}

@media screen and (max-width: 395px ) {
  .MuiAvatar-root.MuiAvatar-circular.MuiAvatar-colorDefault.abmdashboardImageListWrapper{
    width: 30px;
    height: 30px;
  }

  .MuiTableCell-root.MuiTableCell-head.abmDashboardSiteName{
    background: #6a768f !important;
    color: white;
    height: 92px;
    padding-right: 10px;
    margin-right: 20px;
    font-size:11px;
    font-weight:400;
  }
  
  .MuiTableCell-root.MuiTableCell-head.abmDashboardcity{
    background-color: #6a768f;
    color: white;
    height: 92px;
    padding-right: 10px;
    font-size: 11px;
    font-weight: 400;
  }
  .MuiTableCell-root.MuiTableCell-head.abmDashboardState{
      background-color: #6a768f;
      color: white;
      height: 92px;
      padding-right: 26px;
      font-size: 11px;
      font-weight: 400;
  }
  
  .MuiTableCell-root.MuiTableCell-head.abmDashboardZip{
      background-color: #6a768f;
      color: white;
      height: 92px;
      font-size: 11px;
      font-weight: 400;
  }

  .abmdashboardImageList{
    padding-left: 20px;
  }

  .abmdashboardClinicList{
    color: #3D446F; 
   cursor: pointer;
   font-size: 10px;
   font-weight: 500;
}
.abmdashboardcityList{
   color: #3D446F;
   font-size: 10px;
   font-weight:500;
}
}

@media screen and (max-width: 375px ) {
  .MuiAvatar-root.MuiAvatar-circular.MuiAvatar-colorDefault.abmdashboardImageListWrapper{
    width: 30px;
    height: 30px;
  }

  .MuiTableCell-root.MuiTableCell-head.abmDashboardSiteName{
    background: #6a768f !important;
    color: white;
    height: 92px;
    padding-right: 10px;
    margin-right: 20px;
    font-size:11px;
    font-weight:400;
  }
  
  .MuiTableCell-root.MuiTableCell-head.abmDashboardcity{
    background-color: #6a768f;
    color: white;
    height: 92px;
    padding-right: 10px;
    font-size: 11px;
    font-weight: 400;
  }
  .MuiTableCell-root.MuiTableCell-head.abmDashboardState{
      background-color: #6a768f;
      color: white;
      height: 92px;
      padding-right: 18px;
      font-size: 11px;
      font-weight: 400;
  }
  
  .MuiTableCell-root.MuiTableCell-head.abmDashboardZip{
      background-color: #6a768f;
      color: white;
      height: 92px;
      font-size: 11px;
      font-weight: 400;
  }

  .abmdashboardImageList{
    padding-left: 20px;
  }

  .abmdashboardClinicList{
    color: #3D446F; 
   cursor: pointer;
   font-size: 10px;
   font-weight: 500;
}
.abmdashboardcityList{
   color: #3D446F;
   font-size: 10px;
   font-weight:500;
}
}

@media screen and (max-width: 360px ) {
  .MuiAvatar-root.MuiAvatar-circular.MuiAvatar-colorDefault.abmdashboardImageListWrapper{
    width: 25px;
    height: 25px;
  }

  .MuiTableCell-root.MuiTableCell-head.abmDashboardSiteName{
    background: #6a768f !important;
    color: white;
    height: 92px;
    padding-right: 10px;
    margin-right: 20px;
    font-size:10px;
    font-weight:400;
  }
  
  .MuiTableCell-root.MuiTableCell-head.abmDashboardcity{
    background-color: #6a768f;
    color: white;
    height: 92px;
    padding-right: 10px;
    font-size: 10px;
    font-weight: 400;
  }
  .MuiTableCell-root.MuiTableCell-head.abmDashboardState{
      background-color: #6a768f;
      color: white;
      height: 92px;
      padding-right: 18px;
      font-size: 10px;
      font-weight: 400;
  }
  
  .MuiTableCell-root.MuiTableCell-head.abmDashboardZip{
      background-color: #6a768f;
      color: white;
      height: 92px;
      font-size: 10px;
      font-weight: 400;
  }

  .abmdashboardImageList{
    padding-left: 10px;
  }

  .abmdashboardClinicList{
    color: #3D446F; 
   cursor: pointer;
   font-size: 9px;
   font-weight: 500;
}
.abmdashboardcityList{
   color: #3D446F;
   font-size: 9px;
   font-weight:500;
}
}

@media screen and (max-width: 320px ) {
  .MuiAvatar-root.MuiAvatar-circular.MuiAvatar-colorDefault.abmdashboardImageListWrapper{
    width: 20px;
    height: 20px;
  }

  .MuiTableCell-root.MuiTableCell-head.abmDashboardSiteName{
    background: #6a768f !important;
    color: white;
    height: 92px;
    padding-right: 4px;
    margin-right: 20px;
    font-size:10px;
    font-weight:400;
  }
  
  .MuiTableCell-root.MuiTableCell-head.abmDashboardcity{
    background-color: #6a768f;
    color: white;
    height: 92px;
    padding-right: 4px;
    font-size: 10px;
    font-weight: 400;
  }
  .MuiTableCell-root.MuiTableCell-head.abmDashboardState{
      background-color: #6a768f;
      color: white;
      height: 92px;
      padding-right: 0px;
      font-size: 10px;
      font-weight: 400;
  }
  
  .MuiTableCell-root.MuiTableCell-head.abmDashboardZip{
      background-color: #6a768f;
      color: white;
      height: 92px;
      font-size: 10px;
      font-weight: 400;
  }

  .abmdashboardImageList{
    padding-left: 0px;
  }

  .abmdashboardClinicList{
    color: #3D446F; 
   cursor: pointer;
   font-size: 9px;
   font-weight: 500;
}
.abmdashboardcityList{
   color: #3D446F;
   font-size: 9px;
   font-weight:500;
}
}

.tabelHeadingAbmDashboard{
  width : 22.4%;
  border : 1px solid #6A768F80; 
  border-collapse: collapse;
  text-align: center;
}
.mainTableAbmDashboard{
  width: 95%;
  border : 1px solid #6A768F80; 
  border-collapse: collapse;
  overflow-x: auto;
}

.nodatafoundWrapperSuperadmin{
  text-align: center;
  font-family: 'Roboto';
  font-weight: 500;
  /* font-size: 16px; */
  color : #3D446F;  
  padding-top: 10px;
  padding-bottom: 10px;
}

.tabelHeadingImgAbmDashboard {
  width : 10%;
  border : 1px solid #6A768F80; 
  border-collapse: collapse;
  text-align: center;

}