.Ptient-heading-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 500px;
}

.Submit-button-Ptient {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 85px;
}

.buttonforPtientId {
    margin-bottom: 30px !important;
    border: none;
    margin-top: 15px !important;
    box-shadow: #6A768F80 0px 3px 6px;
    border-radius: 9px !important;
    background-color: #fff !important;
    text-transform: capitalize !important;
    font-size: 17px !important;
    font-weight: 600 !important;
    outline: none;
    padding: 5px 25px !important;
}

.media-patient {
    background-image: url('../../assets/img/userprofilediv/newBlueDesign.png');
    background-repeat: no-repeat;
    background-size: 500px 500px;
    background-position: right top;
}

