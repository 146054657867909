.clinicprofileLabel {
  /* width: 188px; */
  height: 29px;
  text-align: left;
  letter-spacing: 0px;
  color: #6a768f;
  opacity: 1;
  font-style: "Roboto";
}
.clinicProfileTitle {
  background: #f2f7f8;
  margin-bottom: 20px;
  margin-left: 30px;
  text-align: left;
}
.clinicProfileWebsiteLabel {
  width: 188px;
  height: 29px;
  text-align: left;
  letter-spacing: 0px;
  color: #6a768f;
  opacity: 1;
  outline: none;
}
.formBackgroundforClinic {
  background: #f2f7f8;
}
.caresiteIcon {
  position: relative !important;
  height: 175px;
  border-radius: 50%;
  z-index: 1;
  top: 72px !important;
  left: 58px !important;
}
.inputfeildsforallclinicProfile {
  border: none;
  border-bottom: 1px solid #8a8e95;
  padding: 5px 10px;
  outline: none !important;
  background-color: #f2f7f8;
  width: 100%;
  box-shadow: none;
  outline-offset: unset;
  margin-bottom: 10px;
  font-style: "Roboto";
}
.inputfeildsforallclinicProfile:focus {
  outline: transparent !important;
}
.clinicprofileWebsiteInputFeilds {
  width: 100%;
  height: 173px;
  background-color: #f2f7f8;
  padding: 5px 10px;
  border-color: #8a8e95;
  margin-bottom: 10px;
}
.updatebuttonCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  box-shadow: 1px 2px 0px 5 10px #0000;
}
.buttonforUpdate {
  border: none;
  height: 70px;
  width: 50%;
  box-shadow: 0px 0px 4px 0px #8a8e95;
  border-radius: 7px !important;
  background-color: #ffffff !important;
  text-transform: capitalize !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  outline: none !important;
  color: #3d446f;
}
InputField:focus {
  outline: none;
}

.CardClinicProfileWrapper {
  background-color: #f2f7f8;
}

@media screen and (max-width: 767px) {
  .CardClinicProfileWrapper {
    margin-top: 160px;
  }
}
