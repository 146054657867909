.inActiveHedingTitle{
    color: #6a768f;
    text-align: center;
    position: relative;
}
.bottomContainerForAlert{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border-top: 2px solid #dbdbdd;
}
.vl{
    border-left: 2px solid #dbdbdd;
    height : 50px;
}
.cancelButtonAlert{
    color: #3d446f !important;
    font-size : 20px !important;
    text-transform: capitalize !important;
    font-family: "Roboto" !important;
    font-weight: 600 !important;
    border-right: 5px solid #0000;
    margin-bottom: 10px !important;
    margin-top: 10px !important;
    outline: none !important;
}
