
.inviteModalContainer{
    color: #6a768f;
    text-align: center;
    position: relative;
}
.middletextForrevokepopup{
    color: #919191;
    font-family: 'Roboto';
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 25px;
    text-align: center;
}
.revokecardbody{
    padding: 0px 0px !important;
}

.bottomContainerForrevoke{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border-top: 2px solid #dbdbdd;
}
.cancelbuttonforrevoke{
    color: #3d446f !important;
    font-size : 20px !important;
    text-transform: capitalize !important;
    font-family: "Roboto" !important;
    font-weight: 600 !important;
    border-right: 5px solid #0000;
    margin-bottom: 10px !important;
    margin-top: 10px !important;
    outline: none !important;
}
.vl{
    border-left: 2px solid #dbdbdd;
    height : 50px;
}