.not-found-container {
  width: 100%;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.not-found-container .oops-page-not-found {
  font-size: 30px;
  margin-bottom: 2px;
  font-weight: 500;
  text-align: center;
}
.cant-fount-text {
  font-size: 24px;
  text-align: center;
}
.not-found-image {
  margin: 0px auto;
  width: 200px;
  margin-top: -76px;
}
