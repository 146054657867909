.MuiTableCell-root.MuiTableCell-head.invitationTableImageHeading{
    background-color: #6a768f; 
        color: white;
        font-size: 16px;
        font-weight: 400;
        padding: 16px;
}

.MuiTableCell-root.MuiTableCell-head.invitationTableEmailHeading{
    background-color: #6a768f; 
        color: white;
        font-size: 14px;
        font-weight: 500;
        padding: 16px;
        text-transform: uppercase;
}


.MuiTableCell-root.MuiTableCell-head.invitationTableRoleHeading{
    background-color: #6a768f; 
        color: white;
        font-size: 14px;
        font-weight: 500;
        padding: 16px;
        text-transform: uppercase;
}

.MuiTableCell-root.MuiTableCell-head.invitationTableClinicNameHeading{
    background-color: #6a768f; 
        color: white;
        font-size: 14px;
        font-weight: 500;
        padding: 16px;
        text-transform: uppercase;
}
.Revokepopup .MuiPaper-elevation8{
  box-shadow : #6a768f80 0px 2px 5px 0px;
}
.MuiTableCell-root.MuiTableCell-head.invitationTableStatusHeading{
    background-color: #6a768f; 
        color: white;
        font-size: 14px;
        font-weight: 500;
        padding: 16px;
        text-transform: uppercase;
}

.invitationTableImageList{
    width: 30px; 
    height: 30px;
}

.invitationTableEmailList{
    font-size: 14px;
    font-weight: 500;
    padding: 16px;
    color : #126AC4;
    cursor: pointer;
}
.invitationTableRoleList{
    color: #3D446F;
    font-size: 14px;
    font-weight: 400;
    padding: 16px;
}
.invitationTableClinicNameList{
    color: #3D446F;
    font-size: 14px;
    font-weight: 400;
    padding: 16px;
}
.tabelHeading2{
  text-align: center;
}
.tabelHeadingRole{
  width : 12%;
  border : 1px solid #6A768F80; 
  border-collapse: collapse;
  text-align: center;
}

.tabelHeadingStatus{
  width : 5%;
  border : 1px solid #6A768F80; 
  border-collapse: collapse;
  text-align: center;
}
.tabelHeadingclinicname{
  width : 28%;
  border : 1px solid #6A768F80; 
  border-collapse: collapse;
  text-align: center;
}
.tabelHeadingReceivedCaresite{
  width : 28%;
  border : 1px solid #6A768F80; 
  border-collapse: collapse;
  text-align: center;
}
.tabelHeadingReceivedStatus{
  width : 10%;
  border : 1px solid #6A768F80; 
  border-collapse: collapse;
  text-align: center;
}

.tabelHeadingReciveInvitation{
  width: 18%;
    border: 1px solid #6A768F80;
    border-collapse: collapse;
    padding: 10px;
}
.tableHeadingTopInvitationTable{
  font-weight: 800;
  color: #3D446F;
  font-family: 'Roboto';
  
}
.tableDescInInvitationTable{
  text-align: left;
  padding: 16px;
  font-weight: 400;
  color: #3D446F;
  font-family: 'Roboto';
 
}
.tabelHeading2InInvitationTable{
  padding: 16px;
  text-align: left;
  font-size: 16px;
}
.InvitationHeading{
  font-family: "Roboto";
  font-weight: 450;
  color: #3D446F;
 
}
.tableDescIn{
  font-family: "Roboto";
  font-weight: 400;
  color: #3D446F;

}
.tableDataReciveInvitation{
  font-family: "Roboto";
  font-weight: 400;
  color: #3D446F;
  display: flex;
  justify-content: center;
}
.InvitationTableWrapper{
    background-color: transparent;
    width: 100%;
    overflow-x: scroll;
}
.pagination{
  margin-right: 20px;
   padding-top: 20px;
    padding-bottom: 20px;
}
@media screen and (max-width: 1115px ){
  .InvitationTableWrapper{
    background-color: transparent;
    width: 835px;
    overflow-x: scroll;
}
}

@media screen and (max-width: 1050px ){
  .InvitationTableWrapper{
    background-color: transparent;
    width: 750px;
    overflow-x: scroll;
}
}

@media screen and (max-width: 1025px ){
    .InvitationTableWrapper{
      background-color: transparent;
      width: 720px;
      overflow-x: scroll;
  }
  }
@media screen and (max-width: 995px ){
  .InvitationTableWrapper{
    background-color: transparent;
    width: 690px;
    overflow-x: scroll;
}
}

@media screen and (max-width: 965px ){
    .InvitationTableWrapper{
      background-color: transparent;
      width: 660px;
      overflow-x: scroll;
  }
  }

@media screen and (max-width: 935px ){
  .InvitationTableWrapper{
    background-color: transparent;
    width: 630px;
    overflow-x: scroll;
}
}

@media screen and (max-width: 905px ){
    .InvitationTableWrapper{
      background-color: transparent;
      width: 630px;
      overflow-x: scroll;
  }
  }

@media screen and (max-width: 873px ){
  .InvitationTableWrapper{
    background-color: transparent;
    /* width: 580px; */
    /* overflow-x: scroll; */
}
}

@media screen and (max-width: 850px ){
    .InvitationTableWrapper{
      background-color: transparent;
      width: 550px;
      overflow-x: scroll;
  }
 
  }

  @media screen and (max-width: 825px ){
    .InvitationTableWrapper{
      background-color: transparent;
      width: 520px;
      overflow-x: scroll;
  }
  }

@media screen and (max-width: 795px ){
  .InvitationTableWrapper{
    background-color: transparent;
    width: 500px;
    overflow-x: scroll;
}
}

@media screen and (max-width: 775px ){
    .InvitationTableWrapper{
      background-color: transparent;
      width: 480px;
      overflow-x: scroll;
  }
  }

@media screen and (max-width: 767px ){
  .InvitationTableWrapper{
    background-color: transparent;
    width: 730px;
    overflow-x: scroll;
}
}

@media screen and (max-width: 748px ){
    .InvitationTableWrapper{
      background-color: transparent;
      width: 710px;
      overflow-x: scroll;
  }
  }

@media screen and (max-width: 727px ){
  .InvitationTableWrapper{
    background-color: transparent;
    width: 690px;
    overflow-x: scroll;
}
}

@media screen and (max-width: 705px ){
    .InvitationTableWrapper{
      background-color: transparent;
      width: 660px;
      overflow-x: scroll;
  }
  }

  @media screen and (max-width: 670px ){
    .InvitationTableWrapper{
      background-color: transparent;
      width: 630px;
      overflow-x: scroll;
  }
  }

@media screen and (max-width: 650px ){
  .InvitationTableWrapper{
    background-color: transparent;
    width: 600px;
    overflow-x: scroll;
}
}

@media screen and (max-width: 620px ){
    .InvitationTableWrapper{
      background-color: transparent;
      width: 580px;
      overflow-x: scroll;
  }
  }

@media screen and (max-width: 595px ){
  .InvitationTableWrapper{
    background-color: transparent;
    width: 560px;
    overflow-x: scroll;
}
}

@media screen and (max-width: 575px ){
  .InvitationTableWrapper{
    background-color: transparent;
    width: 540px;
    overflow-x: scroll;
}
}

@media screen and (max-width: 545px ){
    .InvitationTableWrapper{
      background-color: transparent;
      width: 500px;
      overflow-x: scroll;
  }
  }

  @media screen and (max-width: 520px ){
    .InvitationTableWrapper{
      background-color: transparent;
      width: 480px;
      overflow-x: scroll;
  }
  }

@media screen and (max-width: 497px ){
  .InvitationTableWrapper{
    background-color: transparent;
    width: 460px;
    overflow-x: scroll;
}
}

@media screen and (max-width: 477px ){
    .InvitationTableWrapper{
      background-color: transparent;
      width: 440px;
      overflow-x: scroll;
  }
  }

  @media screen and (max-width: 456px ){
    .InvitationTableWrapper{
      background-color: transparent;
      width: 420px;
      overflow-x: scroll;
  }
  }

  @media screen and (max-width: 438px ){
    .InvitationTableWrapper{
      background-color: transparent;
      width: 400px;
      overflow-x: scroll;
  }
  }
  

@media screen and (max-width: 418px ){
  .InvitationTableWrapper{
    background-color: transparent;
    width: 380px;
    overflow-x: scroll;
}
}

@media screen and (max-width: 400px ){
  .InvitationTableWrapper{
    background-color: transparent;
    width: 360px;
    overflow-x: scroll;
}
}

@media screen and (max-width: 375px ){
    .InvitationTableWrapper{
      background-color: transparent;
      width: 320px;
      overflow-x: scroll;
  }
  }

@media screen and (max-width: 360px ){
    .InvitationTableWrapper{
      background-color: transparent;
      width: 310px;
      overflow-x: scroll;
  }
  }

  .invitaionButtonWrapper{
      margin-left: 200px;
  }
  .ReceivedInvitationsWrapper{
    color: #3D446F;
    font-size: 16px;
    font-weight: 500;
    margin-top: 30px;
    margin-left: 10px;
  }

  @media screen and (max-width: 1115px ){
    .ReceivedInvitationsWrapper{
        color: #3D446F;
        font-size: 13px;
        font-weight: 500;
        margin-top: 30px;
        margin-left: 10px;
      }
  }

  @media screen and (max-width: 1108px ){
    .ReceivedInvitationsWrapper{
        color: #3D446F;
        font-size: 13px;
        font-weight: 500;
        margin-top: 30px;
        margin-left: 10px;
      }
      .invitaionButtonWrapper{
        margin-left: 180px;
    }
  }
  @media screen and (max-width: 1094px ){
    .ReceivedInvitationsWrapper{
        color: #3D446F;
        font-size: 13px;
        font-weight: 500;
        margin-top: 30px;
        margin-left: 10px;
      }
      .invitaionButtonWrapper{
        margin-left: 160px;
    }
  }

  @media screen and (max-width: 1074px ){
    .ReceivedInvitationsWrapper{
        color: #3D446F;
        font-size: 13px;
        font-weight: 500;
        margin-top: 30px;
        margin-left: 10px;
      }
      .invitaionButtonWrapper{
        margin-left: 140px;
    }
  }

  @media screen and (max-width: 1055px ){
    .ReceivedInvitationsWrapper{
        color: #3D446F;
        font-size: 13px;
        font-weight: 500;
        margin-top: 30px;
        margin-left: 10px;
      }
      .invitaionButtonWrapper{
        margin-left: 120px;
    }
  }

  @media screen and (max-width: 1035px ){
    .ReceivedInvitationsWrapper{
        color: #3D446F;
        font-size: 13px;
        font-weight: 500;
        margin-top: 30px;
        margin-left: 10px;
      }
      .invitaionButtonWrapper{
        margin-left: 100px;
    }
  }

  @media screen and (max-width: 1015px ){
    .ReceivedInvitationsWrapper{
        color: #3D446F;
        font-size: 13px;
        font-weight: 500;
        margin-top: 30px;
        margin-left: 10px;
      }
      .invitaionButtonWrapper{
        margin-left: 80px;
    }
  }

  @media screen and (max-width: 995px ){
    .ReceivedInvitationsWrapper{
        color: #3D446F;
        font-size: 13px;
        font-weight: 500;
        margin-top: 30px;
        margin-left: 10px;
      }
      .invitaionButtonWrapper{
        margin-left: 60px;
    }
  }

  @media screen and (max-width: 975px ){
    .ReceivedInvitationsWrapper{
        color: #3D446F;
        font-size: 13px;
        font-weight: 500;
        margin-top: 30px;
        margin-left: 10px;
      }
      .invitaionButtonWrapper{
        margin-left: 40px;
    }
  }

  @media screen and (max-width: 955px ){
    .ReceivedInvitationsWrapper{
        color: #3D446F;
        font-size: 13px;
        font-weight: 500;
        margin-top: 30px;
        margin-left: 10px;
      }
      .invitaionButtonWrapper{
        margin-left: 20px;
    }
  }

  .selectImage{
    background-image: url("../../assets/img/dropInvitation.svg");
    border : none;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 20px 20px;
    /* position: relative; */
  }
  
  .nodatafoundWrapper{
    text-align: center;
    font-family: 'Roboto';
    font-weight: 500;
    /* font-size: 16px; */
    color : #3D446F;  
    padding-top: 10px;
    padding-bottom: 10px;
}