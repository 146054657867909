.forheadertitletheorpy{
   color: #000;
   font-size: 20px;
   color: #3D446F;
   font-weight: 500;
   font-family: "Roboto";
}
.subTitleForTheorpyGOal{
    margin-right: 300px;
    font-size: 20px;
    color: #6A768F;
    margin-top: 30px;
    opacity : 1;
    font-weight: 500;
    font-family: "Roboto";
}
.daysWrapper{
    margin: 48px 16px 0 16px;
    display: flex;
    justify-content: space-between;
    text-align: center;
}
.perDay{
    margin-right: 60px;
}

.updatebuttonCenterforTheorpy {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    margin-top: 60px;
    box-shadow: 1px 2px 0px 5 10px #0000;
  }
  .buttonforsubmittheropy {
    border: none;
    height: 57px;
    width: 42%;
    box-shadow: 0px 0px 4px 0px #8a8e95;
    border-radius: 7px !important;
    background-color: #ffffff !important;
    text-transform: capitalize !important;
    font-size: 18px !important;
    font-weight: 600 !important;
    outline: none !important;
    color: #3d446f;
  }

.css-dwximl {
    box-shadow : #6A768F80 0px 3px 6px !important
}
.css-h1n4m0 {
    border-radius: 50% !important;
        width: 35px !important;
        height: 35px !important;
        background-color: #f26930 !important;
}
.perDay lable{
    font-weight: 600;
}
.css-1knalkq::before {
    display: block !important;
    width: 20px !important;
    height: 25px !important;
    content: "";
    align-items: center;
    position: absolute;
    top: 12px;
    left: 14px;
    background-repeat: no-repeat;
}
.css-dwximl{
    width: 35px !important;
    height : 35px !important;
}
.countContainer{
  display : flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}
.iconIncrement{
    margin-right: 20px;
    font-size: 40px;
    width: 50px;
    color: #3D446F;
    cursor: pointer;
}
.iconDecrement{
    margin-left: 20px;
    font-size: 40px;
    width: 50px;
    color: #3D446F;
    cursor: pointer;
}
.countInput{
    border: none;
}
.countValue{
  text-align : center;
  border: none;
  font-size: 40px;
  width: 150px;
  border-radius : 10px;
  box-shadow: #6A768F80 0px 3px 6px;
  font-weight: bold;
  color: #6A768F;
}
.css-1knalkq{
    background-color: #F26930 !important;
}

@media only screen and (max-width: 838px) {
    .subTitleForTheorpyGOal{
        margin-right: 0px;
    }
  }