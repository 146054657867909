.subDropDown{
    margin-top: -63px;
    margin-left: -116px;
    margin-bottom: -8px;
    border: 1px solid white;
    background: white;
    border-radius: 4px;
    width: 185px;
    display: flex;
    min-width: 185px;
    justify-content: center;
    box-shadow: 0px 0px 6px #6A768F38;
}
.dropdownTextWrapper{
    font-style: 'Roboto';
    display: flex;
    justify-content: center;
    color:#3D446F;
    font-weight: 400;
    padding: 8px;
    background-color: white;
    border: 1px solid transparent;
    cursor: pointer;
}
.dropdownTextWrapper:hover{
    background-color: #F2F7F8;
    border: 1px solid #3D446F;
}


.MuiPaper-root.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded{
    overflow: visible;
}
.drpopdowntooltipWrapper{
    color: white;
    margin: -61px -29px -29px -1px;
}

@media screen and (min-width: 1440px) {
    .subDropDown{
        width: 185px;
    }
}

@media screen and (min-width: 1920px) {
    .subDropDown{
        margin-top: -80px;
        margin-left: -200px;
        margin-bottom: -8px;
        border: 1px solid white;
        background: white;
        border-radius: 4px;
        width: 250px;
        display: flex;
        justify-content: center;
        box-shadow: 0px 0px 6px #6A768F38;
    }
    .drpopdowntooltipWrapper{
        color: white;
        margin: -72px -29px -29px -1px;
    }
}
