/* input:focus {
    border-bottom: none !important;
}

input#customized-hook-demo{
    height: 42px;
} */

.MuiTableCell-root {
  padding: 6px !important;
}
.table-item-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.table-not-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.invitation-table-website {
  width: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.table-search-header {
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.total-count-table {
  margin-right: 20px;
  margin-top: 11px;
}
.total-count-table h5 {
  font-size: 16px;
  font-weight: 600;
  color: #f26930;
}
#table-scroll-invitation-popup ::-webkit-scrollbar {
  width: 15px;
}

#table-scroll-invitation-popup ::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

#table-scroll-invitation-popup ::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
