.notificationSummaryHeading1 {
  font-family: "Roboto";
  font-weight: 500;
  color: #3d446f;
  padding-left: 80px;
}

.notificationSummaryDropdown {
  width: 38%;
  margin-top: 24px;
  margin-left: 83px;
  margin-right: 64px;
}

.notificationSummaryHeading2 {
  font-family: "Roboto";
  font-weight: 500;
  color: #3d446f;
  margin-left: 80px;
  margin-top: 42px;
}

.editImageWrapper {
  width: 33px;
  height: 33px;
  margin-top: 25px;
  margin-left: 24px;
}
.editImageWrapper1 {
  width: 35px;
  height: 35px;
  margin-top: 25px;
  margin-left: 24px;
  
}

.notificationSummaryPatientslistHeadingWrapper {
  text-align: left;
  color: #3d446f;
  opacity: 1;
  margin-left: 82px;
  font-weight: 500;
  padding-top: 8px;
}

.dateWrapper {
  text-align: left;
  /*color: #3d446f;*/
  opacity: 1;
  margin-left: 82px;
  font-weight: 500;
  /*margin-top: 32px;*/
}

.notificationSummaryHeadingWrapper {
  font-weight: bold;
  text-align: left;
  color: #3d446f;
  opacity: 1;
  margin-left: 80px;
  /* font-size: 22px; */
  /* margin-top: 28px; */
  font-weight: 700;
}

.notificationSummarySubHeadingWrapper {
  font-weight: bold;
  text-align: left;
  color: #3d446f;
  opacity: 1;
  margin-left: 80px;
  /* font-size: 14px; */
  margin-top: 10px;
  font-weight: 400;
}

.card1 {
  margin-left: "48px";
  margin-bottom: "54px";
  /* width:'299px'; */
}

@media screen and (max-width: 768px) {
  .notificationSummaryHeading1 {
    padding-top: 160px;
    /* margin-left: 83px; */
    /* margin-right: 64px; */
  }
}

.MuiTableCell-root.MuiTableCell-head.notificationArchiveDate {
  background-color: #6a768f;
  color: white;
  height: 92px;
  padding-left: 72px;
  font-size: 22px;
  font-weight: 400;
}

.MuiTableCell-root.MuiTableCell-head.notificationArchiveReport {
  background-color: #6a768f;
  color: white;
  height: 92px;
  padding-left: 500px;
  font-size: 22px;
  font-weight: 400;
}

.MuiTableCell-root.notificationArchiveDateList {
  color: #3d446f;
  font-size: 16px;
  font-weight: 500;
  padding-left: 72px;
}

.MuiTableCell-root.notificationArchiveReportList {
  color: deepskyblue;
  font-size: 16px;
  font-weight: 500;
  text-decoration: underline;
  padding-left: 500px;
}

@media screen and (max-width: 1115px) {
  .MuiTableCell-root.MuiTableCell-head.notificationArchiveReport {
    background-color: #6a768f;
    color: white;
    height: 92px;
    padding-left: 300px;
    font-size: 22px;
    font-weight: 400;
  }

  .MuiTableCell-root.notificationArchiveReportList {
    color: deepskyblue;
    font-size: 16px;
    font-weight: 500;
    text-decoration: underline;
    padding-left: 300px;
  }
}

@media screen and (max-width: 1064px) {
  .MuiTableCell-root.MuiTableCell-head.notificationArchiveDate {
    background-color: #6a768f;
    color: white;
    height: 92px;
    padding-left: 72px;
    font-size: 20px;
    font-weight: 400;
  }

  .MuiTableCell-root.MuiTableCell-head.notificationArchiveReport {
    background-color: #6a768f;
    color: white;
    height: 92px;
    padding-left: 300px;
    font-size: 20px;
    font-weight: 400;
  }

  .MuiTableCell-root.notificationArchiveDateList {
    color: #3d446f;
    font-size: 14px;
    font-weight: 500;
    padding-left: 72px;
  }

  .MuiTableCell-root.notificationArchiveReportList {
    color: deepskyblue;
    font-size: 14px;
    font-weight: 500;
    text-decoration: underline;
    padding-left: 300px;
  }
}

@media screen and (max-width: 1010px) {
  .MuiTableCell-root.MuiTableCell-head.notificationArchiveDate {
    background-color: #6a768f;
    color: white;
    height: 92px;
    padding-left: 72px;
    font-size: 20px;
    font-weight: 400;
  }

  .MuiTableCell-root.MuiTableCell-head.notificationArchiveReport {
    background-color: #6a768f;
    color: white;
    height: 92px;
    padding-left: 280px;
    font-size: 20px;
    font-weight: 400;
  }

  .MuiTableCell-root.notificationArchiveDateList {
    color: #3d446f;
    font-size: 14px;
    font-weight: 500;
    padding-left: 72px;
  }

  .MuiTableCell-root.notificationArchiveReportList {
    color: deepskyblue;
    font-size: 14px;
    font-weight: 500;
    text-decoration: underline;
    padding-left: 280px;
  }
}

@media screen and (max-width: 988px) {
  .MuiTableCell-root.MuiTableCell-head.notificationArchiveDate {
    background-color: #6a768f;
    color: white;
    height: 92px;
    padding-left: 72px;
    font-size: 18px;
    font-weight: 400;
  }

  .MuiTableCell-root.MuiTableCell-head.notificationArchiveReport {
    background-color: #6a768f;
    color: white;
    height: 92px;
    padding-left: 280px;
    font-size: 18px;
    font-weight: 400;
  }

  .MuiTableCell-root.notificationArchiveDateList {
    color: #3d446f;
    font-size: 12px;
    font-weight: 500;
    padding-left: 72px;
  }

  .MuiTableCell-root.notificationArchiveReportList {
    color: deepskyblue;
    font-size: 12px;
    font-weight: 500;
    text-decoration: underline;
    padding-left: 280px;
  }
}

@media screen and (max-width: 940px) {
  .MuiTableCell-root.MuiTableCell-head.notificationArchiveDate {
    background-color: #6a768f;
    color: white;
    height: 92px;
    padding-left: 72px;
    font-size: 18px;
    font-weight: 400;
  }

  .MuiTableCell-root.MuiTableCell-head.notificationArchiveReport {
    background-color: #6a768f;
    color: white;
    height: 92px;
    padding-left: 240px;
    font-size: 18px;
    font-weight: 400;
  }

  .MuiTableCell-root.notificationArchiveDateList {
    color: #3d446f;
    font-size: 12px;
    font-weight: 500;
    padding-left: 72px;
  }

  .MuiTableCell-root.notificationArchiveReportList {
    color: deepskyblue;
    font-size: 12px;
    font-weight: 500;
    text-decoration: underline;
    padding-left: 240px;
  }
}

@media screen and (max-width: 900px) {
  .MuiTableCell-root.MuiTableCell-head.notificationArchiveDate {
    background-color: #6a768f;
    color: white;
    height: 92px;
    padding-left: 72px;
    font-size: 18px;
    font-weight: 400;
  }

  .MuiTableCell-root.MuiTableCell-head.notificationArchiveReport {
    background-color: #6a768f;
    color: white;
    height: 92px;
    padding-left: 200px;
    font-size: 18px;
    font-weight: 400;
  }

  .MuiTableCell-root.notificationArchiveDateList {
    color: #3d446f;
    font-size: 12px;
    font-weight: 500;
    padding-left: 72px;
  }

  .MuiTableCell-root.notificationArchiveReportList {
    color: deepskyblue;
    font-size: 12px;
    font-weight: 500;
    text-decoration: underline;
    padding-left: 200px;
  }
}

@media screen and (max-width: 860px) {
  .MuiTableCell-root.MuiTableCell-head.notificationArchiveDate {
    background-color: #6a768f;
    color: white;
    height: 92px;
    padding-left: 72px;
    font-size: 18px;
    font-weight: 400;
  }

  .MuiTableCell-root.MuiTableCell-head.notificationArchiveReport {
    background-color: #6a768f;
    color: white;
    height: 92px;
    padding-left: 160px;
    font-size: 18px;
    font-weight: 400;
  }

  .MuiTableCell-root.notificationArchiveDateList {
    color: #3d446f;
    font-size: 12px;
    font-weight: 500;
    padding-left: 72px;
  }

  .MuiTableCell-root.notificationArchiveReportList {
    color: deepskyblue;
    font-size: 12px;
    font-weight: 500;
    text-decoration: underline;
    padding-left: 160px;
  }
}

@media screen and (max-width: 820px) {
  .MuiTableCell-root.MuiTableCell-head.notificationArchiveDate {
    background-color: #6a768f;
    color: white;
    height: 92px;
    padding-left: 72px;
    font-size: 18px;
    font-weight: 400;
  }

  .MuiTableCell-root.MuiTableCell-head.notificationArchiveReport {
    background-color: #6a768f;
    color: white;
    height: 92px;
    padding-left: 120px;
    font-size: 18px;
    font-weight: 400;
  }

  .MuiTableCell-root.notificationArchiveDateList {
    color: #3d446f;
    font-size: 12px;
    font-weight: 500;
    padding-left: 72px;
  }

  .MuiTableCell-root.notificationArchiveReportList {
    color: deepskyblue;
    font-size: 12px;
    font-weight: 500;
    text-decoration: underline;
    padding-left: 120px;
  }
}

@media screen and (max-width: 780px) {
  .MuiTableCell-root.MuiTableCell-head.notificationArchiveDate {
    background-color: #6a768f;
    color: white;
    height: 92px;
    padding-left: 72px;
    font-size: 18px;
    font-weight: 400;
  }

  .MuiTableCell-root.MuiTableCell-head.notificationArchiveReport {
    background-color: #6a768f;
    color: white;
    height: 92px;
    padding-left: 90px;
    font-size: 18px;
    font-weight: 400;
  }

  .MuiTableCell-root.notificationArchiveDateList {
    color: #3d446f;
    font-size: 12px;
    font-weight: 500;
    padding-left: 72px;
  }

  .MuiTableCell-root.notificationArchiveReportList {
    color: deepskyblue;
    font-size: 12px;
    font-weight: 500;
    text-decoration: underline;
    padding-left: 90px;
  }
}

@media screen and (max-width: 767px) {
  .MuiTableCell-root.MuiTableCell-head.notificationArchiveDate {
    background-color: #6a768f;
    color: white;
    height: 92px;
    padding-left: 72px;
    font-size: 18px;
    font-weight: 400;
  }

  .MuiTableCell-root.MuiTableCell-head.notificationArchiveReport {
    background-color: #6a768f;
    color: white;
    height: 92px;
    padding-left: 320px;
    font-size: 18px;
    font-weight: 400;
  }

  .MuiTableCell-root.notificationArchiveDateList {
    color: #3d446f;
    font-size: 12px;
    font-weight: 500;
    padding-left: 72px;
  }

  .MuiTableCell-root.notificationArchiveReportList {
    color: deepskyblue;
    font-size: 12px;
    font-weight: 500;
    text-decoration: underline;
    padding-left: 320px;
  }
}

@media screen and (max-width: 725px) {
  .MuiTableCell-root.MuiTableCell-head.notificationArchiveDate {
    background-color: #6a768f;
    color: white;
    height: 92px;
    padding-left: 72px;
    font-size: 18px;
    font-weight: 400;
  }

  .MuiTableCell-root.MuiTableCell-head.notificationArchiveReport {
    background-color: #6a768f;
    color: white;
    height: 92px;
    padding-left: 300px;
    font-size: 18px;
    font-weight: 400;
  }

  .MuiTableCell-root.notificationArchiveDateList {
    color: #3d446f;
    font-size: 12px;
    font-weight: 500;
    padding-left: 72px;
  }

  .MuiTableCell-root.notificationArchiveReportList {
    color: deepskyblue;
    font-size: 12px;
    font-weight: 500;
    text-decoration: underline;
    padding-left: 300px;
  }
}

@media screen and (max-width: 700px) {
  .MuiTableCell-root.MuiTableCell-head.notificationArchiveDate {
    background-color: #6a768f;
    color: white;
    height: 92px;
    padding-left: 72px;
    font-size: 18px;
    font-weight: 400;
  }

  .MuiTableCell-root.MuiTableCell-head.notificationArchiveReport {
    background-color: #6a768f;
    color: white;
    height: 92px;
    padding-left: 280px;
    font-size: 18px;
    font-weight: 400;
  }

  .MuiTableCell-root.notificationArchiveDateList {
    color: #3d446f;
    font-size: 12px;
    font-weight: 500;
    padding-left: 72px;
  }

  .MuiTableCell-root.notificationArchiveReportList {
    color: deepskyblue;
    font-size: 12px;
    font-weight: 500;
    text-decoration: underline;
    padding-left: 280px;
  }
}

@media screen and (max-width: 685px) {
  .MuiTableCell-root.MuiTableCell-head.notificationArchiveDate {
    background-color: #6a768f;
    color: white;
    height: 92px;
    padding-left: 72px;
    font-size: 18px;
    font-weight: 400;
  }

  .MuiTableCell-root.MuiTableCell-head.notificationArchiveReport {
    background-color: #6a768f;
    color: white;
    height: 92px;
    padding-left: 250px;
    font-size: 18px;
    font-weight: 400;
  }

  .MuiTableCell-root.notificationArchiveDateList {
    color: #3d446f;
    font-size: 12px;
    font-weight: 500;
    padding-left: 72px;
  }

  .MuiTableCell-root.notificationArchiveReportList {
    color: deepskyblue;
    font-size: 12px;
    font-weight: 500;
    text-decoration: underline;
    padding-left: 250px;
  }
}

@media screen and (max-width: 652px) {
  .MuiTableCell-root.MuiTableCell-head.notificationArchiveDate {
    background-color: #6a768f;
    color: white;
    height: 92px;
    padding-left: 72px;
    font-size: 18px;
    font-weight: 400;
  }

  .MuiTableCell-root.MuiTableCell-head.notificationArchiveReport {
    background-color: #6a768f;
    color: white;
    height: 92px;
    padding-left: 210px;
    font-size: 18px;
    font-weight: 400;
  }

  .MuiTableCell-root.notificationArchiveDateList {
    color: #3d446f;
    font-size: 12px;
    font-weight: 500;
    padding-left: 72px;
  }

  .MuiTableCell-root.notificationArchiveReportList {
    color: deepskyblue;
    font-size: 12px;
    font-weight: 500;
    text-decoration: underline;
    padding-left: 210px;
  }
}

@media screen and (max-width: 615px) {
  .MuiTableCell-root.MuiTableCell-head.notificationArchiveDate {
    background-color: #6a768f;
    color: white;
    height: 92px;
    padding-left: 72px;
    font-size: 18px;
    font-weight: 400;
  }

  .MuiTableCell-root.MuiTableCell-head.notificationArchiveReport {
    background-color: #6a768f;
    color: white;
    height: 92px;
    padding-left: 180px;
    font-size: 18px;
    font-weight: 400;
  }

  .MuiTableCell-root.notificationArchiveDateList {
    color: #3d446f;
    font-size: 12px;
    font-weight: 500;
    padding-left: 72px;
  }

  .MuiTableCell-root.notificationArchiveReportList {
    color: deepskyblue;
    font-size: 12px;
    font-weight: 500;
    text-decoration: underline;
    padding-left: 180px;
  }
}

@media screen and (max-width: 585px) {
  .MuiTableCell-root.MuiTableCell-head.notificationArchiveDate {
    background-color: #6a768f;
    color: white;
    height: 92px;
    padding-left: 72px;
    font-size: 18px;
    font-weight: 400;
  }

  .MuiTableCell-root.MuiTableCell-head.notificationArchiveReport {
    background-color: #6a768f;
    color: white;
    height: 92px;
    padding-left: 140px;
    font-size: 18px;
    font-weight: 400;
  }

  .MuiTableCell-root.notificationArchiveDateList {
    color: #3d446f;
    font-size: 12px;
    font-weight: 500;
    padding-left: 72px;
  }

  .MuiTableCell-root.notificationArchiveReportList {
    color: deepskyblue;
    font-size: 12px;
    font-weight: 500;
    text-decoration: underline;
    padding-left: 140px;
  }
}

@media screen and (max-width: 545px) {
  .MuiTableCell-root.MuiTableCell-head.notificationArchiveDate {
    background-color: #6a768f;
    color: white;
    height: 92px;
    padding-left: 72px;
    font-size: 18px;
    font-weight: 400;
  }

  .MuiTableCell-root.MuiTableCell-head.notificationArchiveReport {
    background-color: #6a768f;
    color: white;
    height: 92px;
    padding-left: 100px;
    font-size: 18px;
    font-weight: 400;
  }

  .MuiTableCell-root.notificationArchiveDateList {
    color: #3d446f;
    font-size: 12px;
    font-weight: 500;
    padding-left: 72px;
  }

  .MuiTableCell-root.notificationArchiveReportList {
    color: deepskyblue;
    font-size: 12px;
    font-weight: 500;
    text-decoration: underline;
    padding-left: 100px;
  }
}

@media screen and (max-width: 498px) {
  .MuiTableCell-root.MuiTableCell-head.notificationArchiveDate {
    background-color: #6a768f;
    color: white;
    height: 92px;
    padding-left: 72px;
    font-size: 18px;
    font-weight: 400;
  }

  .MuiTableCell-root.MuiTableCell-head.notificationArchiveReport {
    background-color: #6a768f;
    color: white;
    height: 92px;
    padding-left: 80px;
    font-size: 18px;
    font-weight: 400;
  }

  .MuiTableCell-root.notificationArchiveDateList {
    color: #3d446f;
    font-size: 12px;
    font-weight: 500;
    padding-left: 72px;
  }

  .MuiTableCell-root.notificationArchiveReportList {
    color: deepskyblue;
    font-size: 12px;
    font-weight: 500;
    text-decoration: underline;
    padding-left: 80px;
  }
}

@media screen and (max-width: 480px) {
  .MuiTableCell-root.MuiTableCell-head.notificationArchiveDate {
    background-color: #6a768f;
    color: white;
    height: 92px;
    padding-left: 72px;
    font-size: 17px;
    font-weight: 400;
  }

  .MuiTableCell-root.MuiTableCell-head.notificationArchiveReport {
    background-color: #6a768f;
    color: white;
    height: 92px;
    padding-left: 80px;
    font-size: 17px;
    font-weight: 400;
  }

  .MuiTableCell-root.notificationArchiveDateList {
    color: #3d446f;
    font-size: 11px;
    font-weight: 500;
    padding-left: 72px;
  }

  .MuiTableCell-root.notificationArchiveReportList {
    color: deepskyblue;
    font-size: 11px;
    font-weight: 500;
    text-decoration: underline;
    padding-left: 80px;
  }
}

@media screen and (max-width: 455px) {
  .MuiTableCell-root.MuiTableCell-head.notificationArchiveDate {
    background-color: #6a768f;
    color: white;
    height: 92px;
    padding-left: 72px;
    font-size: 17px;
    font-weight: 400;
  }

  .MuiTableCell-root.MuiTableCell-head.notificationArchiveReport {
    background-color: #6a768f;
    color: white;
    height: 92px;
    padding-left: 60px;
    font-size: 17px;
    font-weight: 400;
  }

  .MuiTableCell-root.notificationArchiveDateList {
    color: #3d446f;
    font-size: 11px;
    font-weight: 500;
    padding-left: 72px;
  }

  .MuiTableCell-root.notificationArchiveReportList {
    color: deepskyblue;
    font-size: 11px;
    font-weight: 500;
    text-decoration: underline;
    padding-left: 60px;
  }
}

@media screen and (max-width: 435px) {
  .MuiTableCell-root.MuiTableCell-head.notificationArchiveDate {
    background-color: #6a768f;
    color: white;
    height: 92px;
    padding-left: 72px;
    font-size: 17px;
    font-weight: 400;
  }

  .MuiTableCell-root.MuiTableCell-head.notificationArchiveReport {
    background-color: #6a768f;
    color: white;
    height: 92px;
    padding-left: 40px;
    font-size: 17px;
    font-weight: 400;
  }

  .MuiTableCell-root.notificationArchiveDateList {
    color: #3d446f;
    font-size: 11px;
    font-weight: 500;
    padding-left: 72px;
  }

  .MuiTableCell-root.notificationArchiveReportList {
    color: deepskyblue;
    font-size: 11px;
    font-weight: 500;
    text-decoration: underline;
    padding-left: 40px;
  }
}

@media screen and (max-width: 415px) {
  .MuiTableCell-root.MuiTableCell-head.notificationArchiveDate {
    background-color: #6a768f;
    color: white;
    height: 92px;
    padding-left: 72px;
    font-size: 17px;
    font-weight: 400;
  }

  .MuiTableCell-root.MuiTableCell-head.notificationArchiveReport {
    background-color: #6a768f;
    color: white;
    height: 92px;
    padding-left: 25px;
    font-size: 17px;
    font-weight: 400;
  }

  .MuiTableCell-root.notificationArchiveDateList {
    color: #3d446f;
    font-size: 11px;
    font-weight: 500;
    padding-left: 72px;
  }

  .MuiTableCell-root.notificationArchiveReportList {
    color: deepskyblue;
    font-size: 11px;
    font-weight: 500;
    text-decoration: underline;
    padding-left: 25px;
  }
}

@media screen and (max-width: 375px) {
  .MuiTableCell-root.MuiTableCell-head.notificationArchiveDate {
    background-color: #6a768f;
    color: white;
    height: 92px;
    padding-left: 68px;
    font-size: 16px;
    font-weight: 400;
  }

  .MuiTableCell-root.MuiTableCell-head.notificationArchiveReport {
    background-color: #6a768f;
    color: white;
    height: 92px;
    padding-left: 20px;
    font-size: 16px;
    font-weight: 400;
  }

  .MuiTableCell-root.notificationArchiveDateList {
    color: #3d446f;
    font-size: 10px;
    font-weight: 500;
    padding-left: 68px;
  }

  .MuiTableCell-root.notificationArchiveReportList {
    color: deepskyblue;
    font-size: 10px;
    font-weight: 500;
    text-decoration: underline;
    padding-left: 20px;
  }
}

@media screen and (max-width: 360px) {
  .MuiTableCell-root.MuiTableCell-head.notificationArchiveDate {
    background-color: #6a768f;
    color: white;
    height: 92px;
    padding-left: 64px;
    font-size: 16px;
    font-weight: 400;
  }

  .MuiTableCell-root.MuiTableCell-head.notificationArchiveReport {
    background-color: #6a768f;
    color: white;
    height: 92px;
    padding-left: 15px;
    font-size: 16px;
    font-weight: 400;
  }

  .MuiTableCell-root.notificationArchiveDateList {
    color: #3d446f;
    font-size: 10px;
    font-weight: 500;
    padding-left: 64px;
  }

  .MuiTableCell-root.notificationArchiveReportList {
    color: deepskyblue;
    font-size: 10px;
    font-weight: 500;
    text-decoration: underline;
    padding-left: 15px;
  }
}

@media screen and (max-width: 320px) {
  .MuiTableCell-root.MuiTableCell-head.notificationArchiveDate {
    background-color: #6a768f;
    color: white;
    height: 92px;
    padding-left: 55px;
    font-size: 14px;
    font-weight: 400;
  }

  .MuiTableCell-root.MuiTableCell-head.notificationArchiveReport {
    background-color: #6a768f;
    color: white;
    height: 92px;
    padding-left: 10px;
    font-size: 14px;
    font-weight: 400;
  }

  .MuiTableCell-root.notificationArchiveDateList {
    color: #3d446f;
    font-size: 9px;
    font-weight: 500;
    padding-left: 55px;
  }

  .MuiTableCell-root.notificationArchiveReportList {
    color: deepskyblue;
    font-size: 9px;
    font-weight: 500;
    text-decoration: underline;
    padding-left: 10px;
  }
}

.PatientTableWrapper {
  background-color: transparent;
  width: 1080px;
  overflow-x: scroll;
}

@media screen and (max-width: 1115px) {
  .PatientTableWrapper {
    background-color: transparent;
    width: 835px;
    overflow-x: scroll;
  }
}

@media screen and (max-width: 1050px) {
  .PatientTableWrapper {
    background-color: transparent;
    width: 770px;
    overflow-x: scroll;
  }
}

@media screen and (max-width: 980px) {
  .PatientTableWrapper {
    background-color: transparent;
    width: 700px;
    overflow-x: scroll;
  }
}

@media screen and (max-width: 930px) {
  .PatientTableWrapper {
    background-color: transparent;
    width: 620px;
    overflow-x: scroll;
  }
}

@media screen and (max-width: 830px) {
  .PatientTableWrapper {
    background-color: transparent;
    width: 560px;
    overflow-x: scroll;
  }
}

@media screen and (max-width: 775px) {
  .PatientTableWrapper {
    background-color: transparent;
    width: 500px;
    overflow-x: scroll;
  }
}

@media screen and (max-width: 767px) {
  .PatientTableWrapper {
    background-color: transparent;
    width: 750px;
    overflow-x: scroll;
  }
}

@media screen and (max-width: 705px) {
  .PatientTableWrapper {
    background-color: transparent;
    width: 690px;
    overflow-x: scroll;
  }
}

@media screen and (max-width: 647px) {
  .PatientTableWrapper {
    background-color: transparent;
    width: 630px;
    overflow-x: scroll;
  }
}

@media screen and (max-width: 585px) {
  .PatientTableWrapper {
    background-color: transparent;
    width: 570px;
    overflow-x: scroll;
  }
}

@media screen and (max-width: 527px) {
  .PatientTableWrapper {
    background-color: transparent;
    width: 510px;
    overflow-x: scroll;
  }
}

@media screen and (max-width: 467px) {
  .PatientTableWrapper {
    background-color: transparent;
    width: 450px;
    overflow-x: scroll;
  }
}

@media screen and (max-width: 410px) {
  .PatientTableWrapper {
    background-color: transparent;
    width: 390px;
    overflow-x: scroll;
  }
}

@media screen and (max-width: 346px) {
  .PatientTableWrapper {
    background-color: transparent;
    width: 330px;
    overflow-x: scroll;
  }
}

/* Done by poovarasan */
.pagination {
  display: flex;

  flex-direction: row;

  justify-content: flex-end;

  padding: 0px 30px 0px 30px;
}

.paginationNotificationsummaryArchive {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 30px 30px 60px 30px;
}

.tableHeadingTopDate {
  text-align: center;
  font-family: 'Roboto';
  font-weight: 800;
  /* font-size: 16px; */
  color: #3D446F;
  padding: 16px;
}

.tableRowDate {
  text-align: center;
  font-family: 'Roboto';
  font-weight: 450;
  padding: 16px;
}

.tableRowReport {
  text-align: center;
  font-family: 'Roboto';
  font-weight: 450;
  padding: 16px;
  text-decoration: underline;
  color: deepskyblue;
}

.tabelDescAndRowafterMapping {
  border: 1px solid #6A768F80;
  border-collapse: collapse;
}

.tabelDescAndRowafterMapping:hover {
  background-color: white;
  /* cursor: pointer; */
}

.tabelHeadingNotificationArchive {
  width: '50%';
  border: 1px solid #6A768F80;
  border-collapse: collapse;
}

.notify-summery-arch-wrapper {
  overflow: hidden;
  padding-top: 20px;
  margin: 0px 80px;
  padding-bottom: 80px;
}

@media screen and (max-width: 1024px) {

  .notify-summery-arch-wrapper {
    margin: 0px 20px
  }

  .notificationSummaryHeadingWrapper, .notificationSummarySubHeadingWrapper {
    margin-left: 20px;
  }
}
.downloadWrapper{
  color: #6A768F;
  font-family: "Roboto";
  font-weight: 500;
  padding-left: 53px;
  /* cursor: pointer; */
}
