.PopuupContainer{
    box-shadow: #6A768F80 0px 3px 6px;
    width: 98%;
    padding-bottom: 20px;
    background-color: white;
    margin-left: 10px;
}
.EnterEmailContainer{
    width: 100%;
    margin-top: 30px;
}
.MeasurementContainer{
    width: 100%;
}

.InputOptforemail{
    /* height: 60vh; */
    display: flex;
    justify-content: center;
    /* margin-left: 25px;   */
    /* margin-right: 40px; */
}
.dropdown-popup{
    box-shadow: #6A768F80 0px 3px 6px;
    width: 47%;
    border: none;
    border-bottom: none;
    margin-left: 46px;
}
.input-popup{
    box-shadow: #6A768F80 0px 3px 6px;
    width: 92%;
    border: none;
    border-bottom: none;
}
.cnfrmac{
    /* padding-top: 20px; */
    padding-left: 32px;
    padding-bottom: 8px;
    color: #6A768F;
    font-size: 16px;
    font-family: "Roboto";
}
.varifyotp{
    padding-top: 8px;
    padding-left: 48px;
    padding-bottom: 8px;
    color: #6A768F;
    font-size: 16px;
    font-family: "Roboto";
    font-weight: bold;
}
.inputfield{
    width: 50%;
    margin-left:45px;
}
.inputfieldforotpinput{
    width: 50%;
    margin-left:15px;
}
.buttonbutn{
    justify-content: right;
    /* margin-left: 84%; */
    width: 30%;
    height: 10%;
    top: -20px;
    /* margin-top: 16px; */
    /* align-items: right; */
    float: right;
}
.measurebuttonbutn{
    justify-content: right;
    /* margin-left: 84%; */
    width: 33%;
    height: 10%;
    top: -20px;
    /* margin-top: 16px; */
    /* align-items: right; */
    float: right;
}
.btnpopup{
    background-color: #fff !important;
    /* margin-bottom: 30px !important; */
    border: none;
  font-size: 16px !important;
  font-weight: bold !important;
    /* width: 40%; */
    height:40px;
    text-transform: capitalize !important;
    justify-content:center;  
    align-items:center;  
    color: #F26930  !important;
    /* box-shadow : #6A768F80 0px 3px 6px; */
    border-radius: 9px !important;
    text-decoration: underline;
    float: right;
}

.btnpopupInactive{
    background-color: #fff !important;
    /* margin-bottom: 30px !important; */
    border: none;
  font-size: 16px !important;
  font-weight: bold !important;
    /* width: 40%; */
    height:40px;
    text-transform: capitalize !important;
    justify-content:center;  
    align-items:center;  
    color: #6A768F  !important;
    /* box-shadow : #6A768F80 0px 3px 6px; */
    border-radius: 9px !important;
    text-decoration: underline;
    float: right;
}
.btnverificationpopup{
    background-color: #fff !important;
    /* margin-bottom: 30px !important; */
    border: none;
  font-size: 16px !important;
  font-weight: bold !important;
    width: 500px;
    height:40px;
    text-transform: capitalize !important;
    justify-content:center;  
    align-items:center;  
color: #464c76  !important;
    box-shadow : #6A768F80 0px 3px 6px;
    border-radius: 9px !important;

}
.buttonbutnsave{
    justify-content:right;      
         margin-left: 85%;
           width: 30%;
           height: 12%;
           top: -48px !important; 
           margin-top:-180px;
           /* margin-bottom: 20px; */
    align-items:right;
    position: relative;
    
}
.btnpopupsave{
    background-color: #fff !important;
    /* margin-bottom: 30px !important; */
    border: none;
  font-size: 18px !important;
  font-weight: bold !important;
  width: 40%;
  height:40px;
  text-transform: capitalize !important;
    justify-content:center;  
    align-items:center;  
color: #464c76  !important;
    box-shadow : #6A768F80 0px 3px 6px;
    border-radius: 9px !important;

}

.confirmaccount{
    margin-right: 20px;
}
.otp-email{
  /*   padding: 80%; */
    /* margin-top: -200px; */
    margin-left: 35px;
    height: 150px;
    width: 12%;
}
.enteremail{
    display: flex;
    justify-content: space-between;
    padding-left: 48px;
    padding-right: 40px;
    margin-top: 8px;
    color: #6A768F;
    font-size: 16px;
    font-family: "Roboto";
    font-weight: bold;
}
.resendcode{
    color: #f2733f;
    text-decoration: underline;
    cursor: pointer;
}
/* .resendcode:hover{} */
@media screen and (max-width:1000px) {
    .input-popup{
width: 87%;
    }
} 

.phButtonNxt{
    justify-content:right;      
         margin-left: 84%;
           width: 30%;
           height: 10%;
           top: -20px; 
           margin-top:16px;
           /* margin-bottom: 20px; */
    align-items:right;
    
}