.main__chatcontent {
  flex-grow: 1;
  /* padding: 40px 40px; */
  /*  max-width: 63%; */
  border-right: 1px solid #ebe7fb;
  background-color: #fff;
  min-height: 590px;
}
.content__header {
  padding-bottom: 15px;
  border-bottom: 1px solid #ebe7fb;
  padding-left: 24px;
  background-color: #f2f7f8;
}
.current-chatting-user {
  /*  display: flex; */
  align-items: center;
  text-align: -webkit-right;
  margin-top: 28px;
}
.current-chatting-user p {
  margin: 0;
  font-weight: 600;
  text-align: center;
  margin-right: 26px;
}
.UpperUser {
  display: flex;
  margin-bottom: 10px;
}
.UpperUser .rightsideImage {
  width: 68px;
  margin-top: 24px;
  height: 68px;
  border: 2px solid #fff;
  border-radius: 50%;
  margin-right: 20px;
}
.UpperUser .leftsideImage {
  /*  width: 68px; */
  margin-top: 24px;
  height: 68px;
  border-radius: 50%;
  margin-right: 20px;
}
.UpperUser .nicName {
  text-align: center;
  font-weight: 500;
  font-family: "Roboto";
}
.topMoreUserCount {
  margin-top: 54px;
  font-weight: 500;
  color: #f26930;
  font-family: "Roboto";
}
.content__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-left: 24px; */
}
.settings .btn-nobg {
  color: #000;
}
.content__body {
  height: 500px;
  overflow: auto;
  background-color: white;
  padding-left: 16px;
}
.chat__item {
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
  align-items: flex-end;
  margin-bottom: 15px;
  transition: all 0.3s ease;
  transform: scale(0);
  transform-origin: right;
  animation-name: showIn;
  animation-duration: 0.2s; /* or: Xms */
  animation-iteration-count: 1;
  animation-direction: normal; /* or: normal */
  animation-timing-function: cubic-bezier(
    0.88,
    0.19,
    0.37,
    1.11
  ); /* or: ease, ease-in, ease-in-out, linear, cubic-bezier(x1, y1, x2, y2) */
  animation-fill-mode: both; /* or: backwards, both, none */
  animation-delay: 0.2s; /* or: Xms */
}
.sendUsername {
  position: relative;
  top: -44px;
}
@keyframes showIn {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
.chat__item .avatar {
  /* margin-right: 0px; */
  margin-left: 20px;
  /* border: 1px solid #fff; */
  padding: 1px;
}
.yesterdayMsg {
  color: #535f66;
  text-align: center;
  font-weight: 500;
  font-size: 15px;
  margin-top: 10px;
  font-family: "Roboto";
}
.chat__item__content {
  background-color: #f2f4f8;
  color: #424040;
  padding: 15px;
  border-radius: 10px 10px 0 10px;
  /*  max-width: 50%;
  min-width: 215px; */
  margin-top: 18px;
  position: relative;
  height: 100px;
  width: 250px;
  box-shadow: #6a768f80 0px 3px 6px;
}
.chat__item__content .chat__meta {
  justify-content: space-between;
  display: flex;
  margin-top: 10px;
  position: absolute;
  top: -10px;
  right: 30px;
}
.chat__item__content .chat__meta img {
  width: 14px;
  margin-top: 6px;
  margin-right: 10px;
}
.chat__item__content .chat__meta span {
  font-size: 13px;
  color: #d1d1d1;
  margin-top: 7px;
}
.chat__msg {
  user-select: none;
  margin-top: 20px;
  text-align: left;
  font-family: "Roboto";
}

.chat__item.other {
  flex-direction: row-reverse;
  transform-origin: left;
}
.chat__item.other .chat__item__content {
  background-color: #f2f4f8;
  color: #424040;
  border-radius: 10px 10px 10px 0;
  max-width: 50%;
}
.chat__item.other .avatar {
  margin-right: 20px;
  margin-left: 0px;
}
.chat__item.other .chat__item__content .chat__meta span {
  color: #d1d1d1;
}
.content__footer {
  padding-top: 17px;
  position: fixed;
  bottom: 0px;
  width: 58%;
  /* margin-bottom: 64px; */
  background-color: white;
  padding-bottom: 67px;
}
.sendFile {
  position: absolute;
  top: 28px;
  width: 32px;
  left: 15px;
  cursor: pointer;
}
.sendNewMessage {
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  padding: 6px;
  margin-left: 62px;
  margin-right: 70px;
  border: 1px solid #adb3b8;
  border-radius: 8px;
}
.sendNewMessage button {
  width: 36px;
  height: 36px;
  background-color: #ecefff;
  border: none;
  box-shadow: none;
  outline: none;
  cursor: pointer;
  font-size: 16px;
  color: #4665ff;
  padding: 0;
  border-radius: 5px;
  line-height: 36px;
  transition: all 0.3s cubic-bezier(0.88, 0.19, 0.37, 1.11);
}
.sendNewMessage button:hover {
  transform: scale(1.2);
}
.sendNewMessage button i {
  display: block;
}
.sendNewMessage input {
  flex-grow: 1;
  padding: 0 15px;
  background-color: transparent;
  border: none;
  outline: none;
}
.sendNewMessage input:focus {
  outline: none !important;
  border-bottom: none !important;
}
#sendMsgBtn {
  color: #6a7091;
  background-color: transparent;
}
.msgWrapper {
  /* display: flex;
  height: 100%;
  flex-direction: column-reverse;
  margin-bottom: 7px; */
  padding: 10px;
}
.msgRight {
  min-width: 300px;
  max-width: 450px;
  background-color: #f2f7f8;
  text-align: right;
  box-shadow: #6a768f80 0px 3px 6px;
  padding: 16px 12px;
  border-radius: 2px;
  font-size: 16px;
  color: #36434b;
  font-family: "Roboto";
  margin-top: 10px;
}
.msgOuterRight {
  padding-right: 10px;
}
.msgOuterLeft {
  padding-left: 10px;
}
.msgLeft {
  min-width: 300px;
  max-width: 450px;
  background-color: #f2f7f8;
  text-align: left;
  box-shadow: #6a768f80 0px 3px 6px;
  border-radius: 2px;
  font-size: 16px;
  color: #36434b;
  font-family: "Roboto";
  padding: 16px 12px;
  margin-top: 10px;
}

.msgImgDivRight {
  display: flex;
}
.msgImgDivLeft {
  display: flex;
  flex-direction: row-reverse;
}
.eyeOnMsg {
  width: 10px;
}
.msgsenttimeright {
  padding-right: 5px;
}
.msgsenttimeLeft {
  padding-right: 5px;
}
.eyeOnMsgLeft {
  width: 10px;
  margin-right: 2px;
}
.msgInnerTimeRight {
  display: flex;
  padding-bottom: 10px;
}
.msgInnerTimeLeft {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 10px;
}
.topImg {
  border-radius: 50%;
  box-shadow: #6a768f80 0px 3px 6px;
}
.noChatDivMsg {
  display: flex;
  justify-content: center;
  margin-bottom: 100px;
}
.noChatDiv {
  display: flex;
  min-height: 250px;
  flex-direction: column-reverse;
}
.chatContentIcon {
  font-size: 20px;
  background-color: #fff !important;
  padding: 14px;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 50%;
  box-shadow: #6a768f80 0px 3px 6px;
  color: #ceccd3;
}

/* 1024 & 1119px */
@media (max-width: 1119px) and (min-width: 1024px) {
  .userMetaforGroup {
    width: 100px;
  }
}

@media (max-width: 1420px) and (min-width: 1119px) {
  .content__body {
    min-height: 546px;
  }
}

@media (max-width: 1620px) and (min-width: 1421px) {
  .content__body {
    min-height: 570px;
  }
  .chatlist__items {
    min-height: 590px;
  }
  .main__chatcontent {
    min-height: 690px;
  }
}

@media (max-width: 1800px) and (min-width: 1621px) {
  .content__body {
    min-height: 630px;
  }
  .chatlist__items {
    min-height: 670px;
  }
  .main__chatcontent {
    min-height: 790px;
  }
}

@media (max-width: 1920px) and (min-width: 1801px) {
  .content__body {
    min-height: 680px;
  }
  .chatlist__items {
    min-height: 730px;
  }
  .main__chatcontent {
    min-height: 890px;
  }
}

.msgReverseDiv {
  display: flex;
  flex-direction: column-reverse;
}

.rightUserName {
  /* margin-top: 32px;
  margin-right: 10px;
  font-weight: 500;
  text-transform: capitalize;
  color: #36434b;
  font-family: "Roboto";
  font-size: 16px; */
  font-weight: 500;
  font-size: 14px;
  color: #36434b;
  font-family: "Roboto";
  text-align: right;
  text-transform: capitalize;
}

.leftUserName {
  /* margin-top: 32px;
  margin-left: 10px;
  font-weight: 500;
  text-transform: capitalize;
  color: #36434b;
  font-family: "Roboto"; */
  font-weight: 500;
  font-size: 14px;
  color: #36434b;
  font-family: "Roboto";
  text-transform: capitalize;
}
.chatDialogAttachInput {
  display: none;
}

.top_chat {
  min-height: 118px;
}

.outChatDiv {
  min-height: 700px;
}
