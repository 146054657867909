.main__chatlist {
  background-color: #fff;
  border-right: 1px solid #ebe7fb;
  padding: 0 0px 0 0px;
  height: 100%;
  font-family: "Roboto";
}

.chatlist__heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f2f7f8;
  padding: 25px 20px 25px 20px;
  min-height: 118px;
}
.user_Icon_Button {
  outline: none !important;
  font-size: 18px;
}
.msg_count_data {
  font-size: 13px;
}

.eyemsg {
  position: absolute;
  top: 10px;
  left: -2px;
}
.User_icon .user {
  color: #ceccd3;
  font-size: 25px;
  background-color: #fff !important;
  padding: 20px;
  border-radius: 50%;
  box-shadow: #6a768f80 0px 3px 6px;
}
.userforchat {
  color: #ceccd3;
  font-size: 25px;
  background-color: #fff !important;
  padding: 20px;
  margin-top: 10px;
  border-radius: 50%;
  box-shadow: #6a768f80 0px 3px 6px;
}
.userforchatgroup {
  color: #ceccd3;
  font-size: 25px;
  background-color: #fff !important;
  padding: 16px;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 50%;
  box-shadow: #6a768f80 0px 3px 6px;
}
.Msg_Top_Icon img {
  width: 16px;
  margin-top: 24px;
}
.contact_icon {
  margin-right: 40px;
  font-size: 10px;
  margin-top: 24px;
}
.btn-nobg {
  background-color: transparent;
  border: none;
  box-shadow: none;
  font-size: 18px;
  cursor: pointer;
  padding: 10px;
  color: #435057;
  outline: none;
}
.search_wrap {
  background-color: #f2f7f8;
  box-shadow: #6a768f80 0px 3px 6px;
  border-radius: 10px;
}
.search_wrap input {
  background-color: #f2f7f8;
  border: none;
  padding: 5px 15px;
  outline: none;
  width: 80%;
  padding-right: 0;
  font-weight: bold;
  border-radius: 10px;
  border-color: #f2f7f8;
}
.search_wrap input:focus {
  background-color: #f2f7f8 !important;
}

.search-btn {
  height: 35px;
  border: none;
  /* background-color: #fff; */
  outline: none;
  width: 20%;
  cursor: pointer;
  font-size: 20px;
}
.chatList__search {
  margin: 20px;
}
.ChatusernameDetails .username {
  font-size: 17px;
  font-family: "Roboto";
  color: #3d446f;
  font-weight: 600;
}
.ChatusernameDetails .lastMsg {
  color: #6a768f;
  font-weight: 200;
  font-family: "Roboto";
  font-size: 10px;
}
.eyeIcon {
  width: 10px;
  position: absolute;
  right: 100px;
  top: 18px;
}
.lastTime {
  position: absolute;
  right: 40px;
  font-family: "Roboto";
  font-size: 12px;
  top: 15px;
  color: #9d9ba1;
}
.Messages p {
  color: #fff;
  width: 22px;
  height: 20px;
  background-color: #f26930;
  text-align: center;
  align-items: center;
  border-radius: 50%;
  font-size: 13px;
}
.TwoImages {
  position: relative;
}
.FirstImages {
  width: 100px;
  border-radius: 50%;
}
.secondImages {
  height: 58px;
  width: 58px;
  position: absolute !important;
  top: 37px;
  left: 20px;
  border-radius: 50%;
}
.userChatCount {
  position: absolute;
  top: 59px;
  font-size: 13px;
  background: cyan;
  text-align: center;
  left: 4px;
  border-radius: 50%;
  width: 27px;
  font-weight: 500;
  color: #000;
  border: 4px solid #fff;
}

.chatlist__items {
  overflow: auto;
  max-height: calc(100vh - calc(100vh / 2));
  padding: 10px 30px 50px 20px;
  background-color: #f2f7f8;
  min-height: 550px;
}

.chatlist__item {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  cursor: pointer;
  padding: 10px 10px 10px 20px;
  transition: all 0.3s cubic-bezier(0.88, 0.19, 0.37, 1.11);
  transform: scale(0);
  animation-name: showIn;
  animation-duration: 0.2s; /* or: Xms */
  animation-iteration-count: 1;
  animation-direction: normal; /* or: normal */
  animation-timing-function: cubic-bezier(
    0.88,
    0.19,
    0.37,
    1.11
  ); /* or: ease, ease-in, ease-in-out, linear, cubic-bezier(x1, y1, x2, y2) */

  animation-fill-mode: both; /* or: backwards, both, none */
  animation-delay: 0.1s; /* or: Xms */
  margin-bottom: 10px;
}
.chatlist__item_for_direct {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  margin-top: 10px;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.88, 0.19, 0.37, 1.11);
  transform: scale(0);
  animation-name: showIn;
  animation-duration: 0.2s; /* or: Xms */
  animation-iteration-count: 1;
  animation-direction: normal; /* or: normal */
  animation-timing-function: cubic-bezier(
    0.88,
    0.19,
    0.37,
    1.11
  ); /* or: ease, ease-in, ease-in-out, linear, cubic-bezier(x1, y1, x2, y2) */

  animation-fill-mode: both; /* or: backwards, both, none */
  animation-delay: 0.1s; /* or: Xms */
  margin-bottom: 16px;
  margin-left: 17px;
  margin-right: 16px;
}
.chatlist__item_for_group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.88, 0.19, 0.37, 1.11);
  transform: scale(0);
  animation-name: showIn;
  animation-duration: 0.2s; /* or: Xms */
  animation-iteration-count: 1;
  animation-direction: normal; /* or: normal */
  animation-timing-function: cubic-bezier(
    0.88,
    0.19,
    0.37,
    1.11
  ); /* or: ease, ease-in, ease-in-out, linear, cubic-bezier(x1, y1, x2, y2) */

  animation-fill-mode: both; /* or: backwards, both, none */
  animation-delay: 0.1s; /* or: Xms */

  margin-bottom: 10px;
}
.chatlist__item:hover {
  background-color: #fff;
  color: #000;
}
.chatlist__item_for_direct:hover {
  background-color: #fff;
  color: #000;
  border-bottom-left-radius: 80px;
  border-top-left-radius: 80px;
  /* padding-left: 30px; */
  /* padding: 12px 0px 12px 12px; */
}
.chatlist__item_for_group:hover {
  background-color: #fff;
  color: #000;
  border-bottom-left-radius: 80px;
  border-top-left-radius: 80px;
}
.iconButtons {
  margin-right: 30px;
  color: #00000029;
}
@keyframes showIn {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
.chatlist__item:first-child {
  margin-top: 0;
}
.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;

  margin-right: 20px;
  position: relative;
}
.avatar img {
  max-width: 100%;
  object-fit: cover;
}
.userMeta {
  margin-top: 30px;
  width: 140px;
}
.userMetafordirect {
  margin-top: 27px;
  margin-left: 60px;
  /* width: 140px */
}
.user {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-left: 36px;
}
.activeTime {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.userMetaforGroup {
  margin-top: 30px;
  width: 140px;
}
.chatlist__item .userMeta p {
  margin: 0;
  padding: 0;
  color: #3d446f;
  font-weight: 600;
  font-size: 15px;
  text-transform: capitalize;
  font-family: "Roboto";
  margin-bottom: 5px;
}
.chatlist__item_for_direct .userMetafordirect {
  margin: 0;
  padding: 0;
  color: #3d446f;
  font-weight: 600;
  font-size: 15px;
  text-transform: capitalize;
  font-family: "Roboto";
  margin-bottom: 5px;
  /* text-overflow: ellipsis; */
}
/* .chatlist__item_for_direct .userMetafordirect span {
  margin: 0;
  padding: 0;
  color: #6a768f;
  font-weight: 400;
  font-size: 12px;
  display: block;
  margin-bottom: 20px;
} */
.chatlist__item_for_group .userMetaforGroup span {
  margin: 0;
  padding: 0;
  color: #6a768f;
  font-weight: 400;
  font-size: 12px;
  display: block;
  margin-bottom: 20px;
}
.chatlist__item_for_group .userMetaforGroup p {
  margin: 0;
  padding: 0;
  color: #3d446f;
  font-weight: 600;
  font-size: 15px;
  text-transform: capitalize;
  font-family: "Roboto";
  margin-bottom: 5px;
}
.chatlist__item .userMeta span {
  margin: 0;
  padding: 0;
  color: #6a768f;
  font-weight: 400;
  font-size: 16px;
  display: block;
  margin-bottom: 20px;
}
.Allmsg {
  margin-top: 24px;
  border: none;
  width: 18px;
  text-align: center;
  align-self: center;
  height: 18px;
  font-size: 15px;
  font-weight: 500;
  background-color: #f26930;
  margin-right: 20px;
  border-radius: 50%;
  color: #fff;
}
.No_msg {
  margin-top: 24px;
  border: none;
  width: 18px;
  text-align: center;
  align-self: center;
  height: 18px;
  font-size: 15px;
  font-weight: 500;
  margin-right: 20px;
  border-radius: 50%;
  color: #fff;
}
.MultiUserContainer {
  position: relative;
}
.firstImage img {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  border: 3px solid #fff;
}
.secondImage img {
  position: absolute;
  top: 40px;
  left: 22px;
  width: 49px;
  height: 49px;
  border-radius: 50%;
  border: 3px solid #fff;
}

.MoreUserCount {
  position: absolute;
  top: 54px;
  background: #6ce3ff;
  left: 7px;
  border-radius: 50%;
  height: 20px;
  color: #000;
  border: 2px solid #fff;
  font-size: 13px;
  width: 23px;
}
.singleUser img {
  border: 3px solid #fff;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  /* margin-left: -5px; */
}

.isOnline {
  position: absolute;
  width: 10px;
  height: 10px;
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #ddd;
  border-radius: 50%;
  border: 2px solid #fff;
}
.isOnline.active {
  background-color: tomato;
}
.avatar-img {
  overflow: hidden;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  margin-top: -63px;
}
.groupchat_icon {
  cursor: pointer;
  font-size: 12px;
}
.no_converstion {
  text-align: center;
  color: #f26930;
  font-weight: 500;
  text-transform: capitalize;
}
.eyemsg {
  position: absolute;
  top: -1px;
  left: -17px;
}

.checkedIconForGroup {
  margin-right: 20px;
  margin-top: 20px;
}

/* media query for messages design  */
@media (max-width: 1262px) and (min-width: 1024px) {
  .userMeta {
    margin-top: 30px;
    margin-left: 20px;
    width: 100px;
  }
  .userMetafordirect {
    margin-top: 30px;
    margin-left: 20px;
    width: 100px;
  }
  .eyemsg {
    position: absolute;
    top: -1px;
    left: -17px;
  }
}

@media screen and (max-width: 768px) {
  .main__chatlist {
    background-color: #fff;
    padding-top: 160px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1075px) {
  .newchat {
    font-size: 10px !important;
  }
  .group-chat {
    font-size: 9.2px !important;
  }
  .groupchat_icon {
    margin-right: 12px !important;
    font-size: 24px;
  }
}
.MuiAvatar-root {
  width: 55px !important;
  height: 55px !important;
}
