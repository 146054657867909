.topHeadingForSetting {
  color: #3d446f;
  margin-left: 40px;
  font-family: "Roboto";
  font-weight: 800;
}
.topHeadingForSettingSelect {
  margin-right: 40px;
  margin-left: 56px;
  margin-bottom: 40px;
  margin-top: 16px;
}
.notificationScheduleForSetting {
  margin-left: 40px;
  padding-top: 40px;
}
.notificationScheduleForSetting {
  color: #3d446f;
  font-family: "Roboto";
  font-weight: 800;
}

.ScheduleText {
  margin-top: 24px;
  color: #6a768f;
  font-weight: 400;
  font-family: "Roboto";
}
.AllDaY {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  margin-left: 56px;
  margin-right: 40px;
  font-family: "Roboto";
}

.AllDaYTow {
  display: flex;
  justify-content: space-evenly;
  margin-top: 40px;
  margin-left: 13px;
  margin-right: 13px;
  font-family: "Roboto";
}

.radio_button {
  margin-left: 10px;
  margin-right: 40px;
  margin-top: -27px;
}
.PerDaY button {
  border-radius: 5px;
  font-size: 15px;
  padding: 10px 20px;
  font-family: "Roboto";
  text-transform: capitalize;
  box-shadow: #6a768f80 0px 3px 6px;
  outline: none !important;
}
.notificationRow {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
.AdheranceMainHeading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.heading {
  font-size: 20px;
  font-weight: 400;
  color: black;
  padding: 20px 20px 20px 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.heading-one {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 400;
  color: black;
}

.heading-two {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.875rem;
  color: grey;
}

.dropdown-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.NotificationSchedule {
  display: flex;
  align-items: center;
  flex-direction: column;
  border: 1px solid lightgray;
  background: white;
  color: black;
  width: 100%;
  height: 725px;
  border-radius: 8px;
  /* margin-right: 2%; */
  margin-left: 2%;
}
.NotificationSchedule-email {
  display: flex;
  align-items: center;
  flex-direction: column;
  border: 1px solid lightgray;
  background: white;
  color: black;
  width: 100%;
  height: 725px;
  border-radius: 8px;
  margin-right: 2%;
  margin-left: 2%;
}

.NotificationScheduleOne {
  display: flex;
  align-items: center;
  flex-direction: column;
  border: 1px solid lightgray;
  background: white;
  color: black;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  margin: 20px;
}

.ReportPatient {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.lineWrapper {
  border: 1px solid lightgray;
  background: lightgray;
  width: 90%;
  height: 1px;
  margin-top: 60px;
  margin-bottom: 10px;
}
.lineWrapper-therapy {
  border: 1px solid lightgray;
  background: lightgray;
  width: 90%;
  height: 1px;
  margin-top: 140px;
  margin-bottom: 10px;
}

.emailLineWrapper {
  border: 1px solid lightgray;
  background: lightgray;
  width: 90%;
  height: 1px;
  margin-top: 337px;
  margin-bottom: 10px;
}

.lineWrapper-patient {
  border: 1px solid lightgray;
  background: lightgray;
  width: 350px;
  height: 1px;
  margin-top: 40px;
  margin-bottom: 10px;
}

.textwrapperone {
  font-size: 0.875rem;
  color: black;
  align-items: center;
  margin: 10px;
  display: contents;
}

.heading-three {
  font-size: 20px;
  font-weight: 400;
  color: black;
  padding: 20px 20px 20px 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.heading-four {
  font-size: 0.875rem;
  color: black;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 43px;
}

.slider-wrapper {
  font-size: 20px;
  color: black;
  justify-content: center;
  align-items: center;
  margin-top: 81px;
  margin-bottom: 6px;
}

.heading-five {
  font-size: 20px;
  font-weight: 400;
  color: black;
  padding: 20px 20px 20px 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.heading-six {
  font-size: 20px;
  font-weight: 400;
  color: black;
  padding: 20px 20px 20px 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.button-wrapper {
  border: 1px solid rgb(53, 139, 209);
  color: rgb(53, 139, 209);
  height: 30px;
  width: 150px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2px;
}

.button-close-wrapper {
  border: 1px solid transparent;
  color: transparent;
}

.button-close-wrapper:hover {
  color: rgb(53, 139, 209);
}

.button-wrapper-two {
  border: 1px solid rgb(53, 139, 209);
  color: rgb(53, 139, 209);
  height: 30px;
  width: 280px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  padding: 16px;
  font-size: 0.875rem;
}

.input-wrapper {
  display: flex;
}

.NotificationScheduletwo {
  display: flex;
  align-items: center;
  flex-direction: column;
  border: 1px solid lightgray;
  background: rgb(122, 118, 118);
  color: black;
  width: 400px;
  height: 435px;
  border-radius: 8px;
  margin: 20px;
}
.NotificationScheduletwo-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  border: 1px solid lightgray;
  background: white;
  color: black;
  width: 400px;
  height: 435px;
  border-radius: 8px;
}
.weekday-wrapper {
  border: 1px solid black;
  padding: 10px;
  width: 200px;
  /* height: 200px; */
}

.weekdays-name-wrapper {
  display: inline-block !important;
}

.userEmail-wrapper {
  overflow-x: hidden;
}

/* New Chanhes */

.section3_part1 {
  margin-top: 80px;
}
.progressbarForAdherence {
  margin-left: 48px;
  margin-right: 48px;
  margin-top: 80px;
}
.spo2Div {
  margin-left: 48px;
  margin-right: 48px;
  margin-top: 80px;
}
.titleText {
  font-size: 20px;
  color: #3d446f;
  margin-left: 40px;
  font-weight: 500;
}

.subtitle {
  font-size: 16px;
  align-items: left;
  color: #6a768f;
  margin-left: 40px;
}
.titleTextAherance {
  color: #3d446f;
  font-weight: 800;
  font-family: "Roboto";
}

.titleTextspo2 {
  color: #3d446f;
  font-weight: 800;
  font-family: "Roboto";
}
.subtitlespo2 {
  color: #6a768f;
  font-family: "Roboto";
  margin-top: 24px;
}
.subtitleadherance {
  align-items: left;
  color: #6a768f;
  margin-top: 20px;
  font-family: "Roboto";
}
.subtitleforTheorpy {
  color: #6a768f;
  margin-top: 24px;
  font-family: "Roboto";
}
.titleTextfortheorpy {
  font-family: "Roboto";
  color: #3d446f;
  font-weight: 800;
}

.section3_part2 {
  background-color: white;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  margin: 20px 20px 0px 0px;
  height: 300px;
  padding: 20px 5px;
  border-radius: 10px;
  height: 100%;
}
.section3_part3 {
  background-color: transparent !important;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  margin: 20px 20px 0px 0px;
  height: 300px;
  padding: 20px 5px;
  border-radius: 10px;
  height: 100%;
}
.section3_text {
  text-align: center;
}
.section3_text1 {
  text-align: center;
  padding: 20px 10px 0px 10px;
}
.section3_text2 {
  text-align: center;
  padding: 20px 10px 0px 10px;
}

.section3_text3 {
  text-align: center;
  padding: 20px 10px 0px 10px;
}

/* Section 2 */
.section2_part1 {
  background-color: transparent;
  margin-top: 80px;
}
.rePatientInputFeilds {
  box-shadow: #6a768f80 0px 3px 6px;
  width: 150%;
  height: 51px;
}
.clinicFeildsInputs {
  box-shadow: 0px 3px 6px;
  font-weight: 600;
}
.notificationRepatient {
  color: #3d446f;
  font-weight: 800;
  margin-bottom: 16px;
  font-family: "Roboto";
}
.notificationRepatientInnerDiv {
  color: #6a768f;
  font-family: "Roboto";
  margin-left: 40px;
}

.css-k46nbh-MuiSwitch-root {
  padding: 0px !important;
  width: 48px !important;
  height: 28px !important;
  border: 3px solid #c5c82f !important;
  border-radius: 50px !important;
}
.Component-root-6 .MuiSwitch-switchBase {
  margin: 0px !important;
}
.Component-root-12 .MuiSwitch-switchBase.Mui-checked {
  margin-top: 0px !important;
  margin-left: 0px !important;
}
.Component-root-18 .MuiSwitch-switchBase.Mui-checked {
  margin-top: 0px !important;
  margin-left: 0px !important;
}
.updatebuttonnotificationsetting {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
  /* margin-bottom: 30px; */
  box-shadow: 1px 2px 0px 5 10px #0000;
}
.buttonforUpdatenotificationsetting {
  border: none;
  height: 60px;
  width: 30%;
  padding: 40px 40px 40px 40px;
  box-shadow: 0px 0px 4px 0px #8a8e95;
  border-radius: 7px;
  background-color: #ffffff !important;
  text-transform: capitalize !important;
  font-size: 24px !important;
  font-weight: 800 !important;
  outline: none !important;
  color: #3d446f !important;
  font-family: "Roboto" !important;
}
.descriptionWrapper {
  color: #3d446f;
  font-size: 22px;
  font-weight: bold;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
}

.radio_button_notification_summary {
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 61px;
}
.css-1gv0vcd-MuiSlider-track {
  color: lightgrey !important;
  border: 1px solid lightgrey !important;
}

.css-eg0mwd-MuiSlider-thumb {
  color: white !important;
}
.MuiSlider-rail {
  width: 100%;
  height: 5px !important;
  display: block;
  opacity: 0.38;
  position: absolute;
  border-radius: 10px !important;
}
.MuiSlider-track {
  height: 5px !important;
  display: block;
  position: absolute;
  border-radius: 10px !important;
  background-color: #bec4d1 !important;
}
.PrivateValueLabel-circle-8 {
  width: 32px;
  height: 32px;
  display: flex;
  transform: rotate(-45deg);
  align-items: center;
  border-radius: 50% 50% 50% 0;
  justify-content: center;
  background-color: #fff !important;
}
.PrivateValueLabel-label-9 {
  color: #f26930 !important;
  transform: rotate(45deg);
  font-weight: 600;
}
.MuiSlider-thumb {
  width: 22px !important;
  height: 22px !important;
  display: flex;
  outline: 0;
  position: absolute;
  box-sizing: border-box;
  margin-top: -9px !important;
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  align-items: center;
  margin-left: -6px;
  border-radius: 50%;
  justify-content: center;
  background-color: #fff !important;
}
.PrivateValueLabel-thumb-5.PrivateValueLabel-open-6
  .PrivateValueLabel-offset-7 {
  transform: scale(1) translateY(-16px) !important;
}
.MuiSlider-valueLabel {
  /* left: calc(-11% - 4px) !important; */
}
.PrivateValueLabel-circle-19 {
  background-color: #fff !important;
}
.PrivateValueLabel-label-20 {
  color: #ff4b00 !important;
  font-weight: 600;
}
.CancelDiv {
  margin-top: 15px;
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}
.cancel {
  font-family: "Roboto";
}

@media screen and (max-width: 768px) {
  .topHeadingForSetting {
    padding-top: 160px;
  }
}
@media screen and (max-width: 1102px) {
  .AllDaY {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .AllDaYTow {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .PerDaY {
    margin-top: 10px;
  }
  .PerDaY button {
    width: 140px;
  }
}

@media screen and (max-width: 475px) {
  .PerDaY button {
    font-size: 13px;
  }
}

@media screen and (max-width: 445px) {
  .PerDaY button {
    font-size: 10px;
  }
}

@media screen and (max-width: 360px) {
  .PerDaY button {
    font-size: 10px;
    padding: 10px 16px;
  }
}

@media screen and (max-width: 320px) {
  .PerDaY button {
    font-size: 10px;
    padding: 10px 10px;
  }
}
.InactiveTextToggleNotification {
  position: relative;
  top: 4px;
  left: 10px;
  font-weight: 600;
  font-family: "Roboto";
}
.activeTextToggleNotification {
  position: relative;
  right: 10px;
  top: 3px;
  font-weight: 600;
  font-family: "Roboto";
}
