.titleforActiveadmin {
  /* font-size: 16px; */
  color: #6a768f;
  text-align: center;
  padding-bottom: 16px;
  padding-top: 16px;
  font-weight: 800;
  font-family: "Roboto";
}
.tableRow {
  margin-top: 10px;
}
.DividerforCard {
  margin-top: 64px !important;
}
.emaildata {
  font-weight: 500;
  /* font-size: 12px; */
  color: #3d446f;
  margin-left: -5px;
}
.phoneNumber {
  color: #6a768f;
  margin-left: -5px;
}
.dotIocn {
  cursor: pointer;
}
.countAdminNumberUserProfile {
  margin-left: 16px;
  color: #f26930;
  opacity: 1;
  font-weight: 800;
  /* font-size: 18px; */
  font-family: "Roboto";
  padding-top: 15px;
  padding-bottom: 16px;
}
