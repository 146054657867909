.CareSiteLogo {
    height: 230px;
    width: 230px;
  }
  .bottomContainerInFooterCard {
    background-color: white;
    box-shadow: #6a768f80 0px 3px 6px;
    margin-right: 80px;
    margin-left: 80px;
    margin-top: 40px;
    border-radius: 10px;
    background-image: url('../../assets/icons/helpcenter/blueDesign.png');
    background-repeat: no-repeat;
    background-position: right top;
  }
  .link_helpInFooterCard{
      color: #F26930;
  }
  .ClinicNameHeading {
    color: #3d446f;
    font-weight: 600;
    font-family: "Roboto";
    margin-bottom: 29px;
  }
  .bottomSectionContainer1InFooterCard {
    display: flex;
    margin-top: 8px;
  }
  .EmailIconInFooterCard {
    background-color: white;
    padding: 4px;
    border-radius: 5px;
    margin-right: 30px;
    box-shadow: #6a768f80 0px 3px 6px;
  }
  .customservice {
    align-self: center;
    color: #3d446f;
    font-family: "Roboto";
  }
  .linkservice {
    align-self: center;
    color: #3d446f;
    font-family: "Roboto";
    margin-bottom: 12px;
  }
  .websiteIconInFooterIcon {
    background-color: white;
    padding: 6px;
    border-radius: 5px;
    margin-right: 30px;
    box-shadow: #6a768f80 0px 3px 6px;
  }
   .websiteanvhortag {
    width : 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
  overflow: hidden;
  }

  .ABMLogocontainerInFooterCard {
    margin: 34px 0 32px 56px;
  }
  .CareSiteDetailsContainer {
    margin: 24px 0px 0px 28px;
  }
  .addressContainer{
    color: #3d446f;
    font-family: "Roboto";
    margin-top: 3px;
  }
  .phoneIconInFooterCard{
    background-color: white;
    padding: 3px;
    border-radius: 5px;
    margin-right: 30px;
    box-shadow: #6a768f80 0px 3px 6px;
  }
  .LinkIcon{
    background-color: white;
    padding: 4px;
    border-radius: 5px;
    margin-right: 30px;
    box-shadow: #6a768f80 0px 3px 6px;
    margin-bottom: 12px;
  }
  
 
   @media only screen and (max-width: 1920px) and (min-width: 1750px) {
    .bottomContainerInFooterCard {
      margin-right: 60px;
      margin-left: 60px;
    }
    .ABMLogocontainerInFooterCard {
      margin: 24px 32px 32px 64px;
    }
    .CareSiteLogo {
      height: 330px;
      width: 330px;
    }
    .CareSiteDetailsContainer{
        margin: 56px 0px 0px 0px;
    }
  }
  
  @media only screen and (max-width: 1750px) and (min-width: 1650px) {
    .bottomContainerInFooterCard {
        margin-right: 60px;
        margin-left: 60px;
      }
      .ABMLogocontainerInFooterCard {
        margin: 24px 32px 32px 64px;
      }
      .CareSiteLogo {
        height: 330px;
        width: 330px;
      }
      .CareSiteDetailsContainer{
          margin: 56px 0px 0px 0px;
      }
  }
  
  @media only screen and (max-width: 1650px) and (min-width: 1550px) {
    .bottomContainerInFooterCard {
        margin-right: 60px;
        margin-left: 60px;
      }
      .ABMLogocontainerInFooterCard {
        margin: 24px 32px 32px 64px;
      }
      .CareSiteLogo {
        height: 330px;
        width: 330px;
      }
      .CareSiteDetailsContainer{
          margin: 56px 0px 0px 0px;
      }
  }
  
  @media only screen and (max-width: 1550px) and (min-width: 1450px) {
    .bottomContainerInFooterCard {
        margin-right: 60px;
        margin-left: 60px;
      }
      .ABMLogocontainerInFooterCard {
        margin: 24px 32px 32px 64px;
      }
      .CareSiteLogo {
        height: 330px;
        width: 330px;
      }
      .CareSiteDetailsContainer{
          margin: 56px 0px 0px 0px;
      }
  }
  
  @media only screen and (max-width: 1450px) and (min-width: 1350px) {
    .bottomContainerInFooterCard {
        margin-right: 60px;
        margin-left: 60px;
      }
      .ABMLogocontainerInFooterCard {
        margin: 24px 32px 32px 64px;
      }
      .CareSiteLogo {
        height: 330px;
        width: 330px;
      }
      .CareSiteDetailsContainer{
          margin: 56px 0px 0px 0px;
      }
  }
  
  @media only screen and (max-width: 1350px) and (min-width: 1194px) {
    .bottomContainerInFooterCard {
        margin-right: 60px;
        margin-left: 60px;
      }
      .ABMLogocontainerInFooterCard {
        margin: 24px 32px 32px 32px;
      }
      .CareSiteLogo {
        height: 200px;
        width: 200px;
      }
      .CareSiteDetailsContainer{
          margin: 24px 0px 0px 0px;
      }
  }  