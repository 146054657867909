.card-wrapper-line {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.clinic-card-wrapper {
  border: 1px solid lightgray;
  width: 100%;
  margin-right: 2%;
  margin-left: 2%;
  height: 200px;
  background: white;
  border-radius: 8px;
}

.patient-card-wrapper {
  border: 1px solid lightgray;
  width: 100%;

  height: 200px;
  background: white;
  border-radius: 8px;
}

.clinician-card-wrapper {
  border: 1px solid lightgray;
  width: 100%;
  margin-right: 2%;
  margin-left: 2%;
  height: 200px;
  background: white;
  border-radius: 8px;
}
.cliniTable-wrapper {
  border: 1px solid lightgray;
  width: 96%;
  height: 100%;
  margin-right: 2%;
  margin-top: 2%;
  margin-left: 2%;
  background: white;
  border-radius: 4px;
}
.refresh-button-wrapper {
  border: 1px solid gray;
  border-radius: 27px;
  width: 55px;
  height: 42px;
  padding: 7px 12px 5px 15px;
  margin-right: 8px;
}
.list-button-wrapper {
  border: 1px solid gray;
  border-radius: 27px;
  width: 55px;
  height: 42px;
  padding: 7px 12px 5px 15px;
  margin-right: 8px;
}

.tablehover {
  background: white;
}

.tablehover :hover {
  background: lightgray;
}

.accountwrapper {
  display: flex;
  flex-direction: row;
}

.accountColWrapper {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.inputwrapper {
  width: 230px;
  border-radius: 4px;
  height: 50px;
  border: 1px solid lightgray;
  font-size: 18px;
}

.addressinputwrapper {
  width: 730px;
  border-radius: 4px;
  height: 50px;
  border: 1px solid lightgray;
  font-size: 18px;
}
.searchBarDropDown {
  background-color: #f2f4f8;
  color: black;
  padding-bottom: 30px;
  padding-top: 30px;
  border: 1px solid #f2f4f8;
  outline: none !important;
  background-image: url("../../../assets/img/dropDownSearchBar.svg");
}
.searchBarDropDown:hover {
  background-color: #fff !important;
}
.selectSearch {
  color: red;
  width: 100%;
  border: none;
  box-shadow: #6a768f80 0px 3px 6px;
  border-radius: 10px;
  color: gray;
  text-transform: capitalize;
}
.selectDurationDiv {
  margin-top: 32px;
  padding-left: 83px;
  /* margin-left: 83px; */
  color: #3d446f;
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 700;
}
.dateRangeButttons {
  display: flex;
  margin-top: 24px;
  margin-left: 72px;
}

.anouncenement {
  color: #3d446f;
  font-weight: 500;
  font-family: "Roboto";
  margin-left: 24px;
  align-self: center;
}
.selectDiv1 {

  width: 38%;
  margin-left: 83px;
  margin-right: 64px;
}
.tableDivBeforeAnouncement {
  color: #3d446f;
  font-size: 16px;
  font-family: "Roboto";
  font-weight: 800;
  margin-left: 80px;
  margin-top: 16px;
}
.clinicAdDashSrchBar {
  margin-left: 80px;
  margin-top: 48px;
  margin-right: 64px;
}

.clinicDash_report_search {
  width: 100%;
  /* text-align:left; */
  /* padding-right: 32px; */
  border-radius: 8px;
  border: none;
  box-shadow: #6a768f80 0px 3px 6px;
}
.tableCount {
  color: #f26930;
  font-size: 8px;
  font-family: "Roboto";
  font-weight: 800;
  text-align: right;
  margin-right: 32px;
  margin-top: 16px;
}
.bottomDashBorder {
  border-top: 1px solid #aaaaac2b;
}
.totalCountsAtPatientProfileTable {
  color: #f26930;
  font-family: "Roboto";
  font-weight: 400;
  text-align: right;
  margin-top: 16px;
  margin-right: 32px;
}

.dataRangeFormContainer {
  padding: 40px 30px;
}


  .dataRangeFormContainer{
      padding: 40px 30px;
  }

  .fromdataRange{
      margin-top: 20px;
  }
  .dataRangeButton{
      border-radius: 5px;
      margin: 0px auto;
      text-align  : center;
      margin-top: 20px;
      box-shadow: #6A768F80 0px 3px 6px;
      width: 50%;
      outline: none !important;
      border : none;
    }
   .dataRangeButton span{
      text-transform: capitalize;
  }
  .dateRangePopUp{
      top: 417px !important;
  }

.anouncement3Blue{
    background-image: url("../../../assets/img/Blue announcement card.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 340px;
    height: 160px;   
}

.anouncement3Green{
  background-image: url("../../../assets/img/Green announcement card.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 340px;
  height: 161px;   
}

  .anouncement3{
      background-image: url("../../../assets/img/Circle Organe.png");
      background-repeat: no-repeat;
      background-size: contain;
      width: 340px;
      height: 161px;
      
  }
  .announcement-3-sentence{
      margin-left: 16px;
      padding-top: 48px; 
      /* font-size: 12px; */
      font-family: "Roboto";
      color: #3D446F;
      font-weight: 800;
  }
  .announcement-1-sent{
      margin-left: 24px;
      margin-right: 24px;
      /* font-size: 12px; */
      font-family: "Roboto";
      color: #3D446F;
      font-weight: 600;
      padding-top: 48px;
  }
  .announcement-2-sent{
    margin-left: 24px;
    /* margin-right: 100px; */
    /* font-size: 12px; */
    font-family: "Roboto";
    color: #3D446F;
    font-weight: 800;
    padding-top: 48px;
    }
.anouncenementHeadingWrapper{
    margin-left: 80px;
    /* margin-top: 12px; */
}

@media screen and (max-width: 1920px ){
  .anouncement3Blue{
    background-image: url("../../../assets/img/Blue announcement card.png");;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 256px;  
  }

  .anouncement3{
    background-image: url("../../../assets/img/Circle Organe.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 256px;        
}   

.anouncement3Green{
  background-image: url("../../../assets/img/Green announcement card.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 256px;   
}

  .announcement-1-sent{
    margin-left: 24px;
    margin-right: 24px;
    font-family: "Roboto";
    color: #3D446F;
    font-weight: 500;
    padding-top: 24px;
}

.announcement-2-sent{
  margin-left: 24px;
  /* margin-right: 100px; */
  font-family: "Roboto";
  color: #3D446F;
  font-weight: 500;
  padding-top: 24px;
  }

  .announcement-3-sentence{
    margin-left: 16px;
    padding-top: 24px; 
    font-family: "Roboto";
    color: #3D446F;
    font-weight: 500;
}
}

@media screen and (max-width: 1440px ){
  .anouncement3Blue{
    background-image: url("../../../assets/img/Blue announcement card.png");;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 172px;  
  }

  .anouncement3{
    background-image: url("../../../assets/img/Circle Organe.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 172px;        
}   

.anouncement3Green{
  background-image: url("../../../assets/img/Green announcement card.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 172px;   
}

  .announcement-1-sent{
    margin-left: 24px;
    margin-right: 24px;
    font-family: "Roboto";
    color: #3D446F;
    font-weight: 500;
    padding-top: 12px;
}

.announcement-2-sent{
  margin-left: 24px;
  /* margin-right: 100px; */
  font-family: "Roboto";
  color: #3D446F;
  font-weight: 500;
  padding-top: 12px;
  }

  .announcement-3-sentence{
    margin-left: 16px;
    padding-top: 12px; 
    font-family: "Roboto";
    color: #3D446F;
    font-weight: 500;
}
}

@media screen and (max-width: 1280px ){
  .anouncement3Blue{
    background-image: url("../../../assets/img/Blue announcement card.png");;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 144px;  
  }

  .anouncement3{
    background-image: url("../../../assets/img/Circle Organe.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 144px;        
}   

.anouncement3Green{
  background-image: url("../../../assets/img/Green announcement card.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 144px;   
}

  .announcement-1-sent{
    margin-left: 24px;
    margin-right: 24px;
    font-family: "Roboto";
    color: #3D446F;
    font-weight: 500;
    padding-top: 12px;
}

.announcement-2-sent{
  margin-left: 24px;
  /* margin-right: 100px; */
  font-family: "Roboto";
  color: #3D446F;
  font-weight: 500;
  padding-top: 12px;
  }

  .announcement-3-sentence{
    margin-left: 16px;
    padding-top: 12px; 
    font-family: "Roboto";
    color: #3D446F;
    font-weight: 500;
}
}

@media screen and (max-width: 1024px ){
  .anouncement3Blue{
    background-image: url("../../../assets/img/Blue announcement card.png");;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 98px;  
  }

  .anouncement3{
    background-image: url("../../../assets/img/Circle Organe.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 98px;        
}   

.anouncement3Green{
  background-image: url("../../../assets/img/Green announcement card.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 98px;   
}

  .announcement-1-sent{
    margin-left: 24px;
    margin-right: 24px;
    font-family: "Roboto";
    color: #3D446F;
    font-weight: 500;
    padding-top: 12px;
}

.announcement-2-sent{
  margin-left: 24px;
  /* margin-right: 100px; */
  font-family: "Roboto";
  color: #3D446F;
  font-weight: 500;
  padding-top: 12px;
  }

  .announcement-3-sentence{
    margin-left: 16px;
    padding-top: 12px; 
    font-family: "Roboto";
    color: #3D446F;
    font-weight: 500;
}
.excelIconWrapper{
  width: 24px;
  height: 24px;
  margin-left: 30px;
}
}

@media screen and (min-width: 960px ) and (max-width: 995px ){
    .anouncement3{
        background-image: url("../../../assets/img/Circle Organe.png");
        background-repeat: no-repeat;
        background-size: contain;
        width: 590px;
        height: 318px;        
    } 
    
    .anouncement3Blue{
      background-image: url("../../../assets/img/Blue announcement card.png");
      background-repeat: no-repeat;
      background-size: contain;
      width: 590px;
      height: 313px;   
  }
  
  .anouncement3Green{
    background-image: url("../../../assets/img/Green announcement card.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 590px;
    height: 318px;   
  }
  }

  @media screen and (min-width: 924px ) and (max-width: 960px ){
    .anouncement3{
        background-image: url("../../../assets/img/Circle Organe.png");
        background-repeat: no-repeat;
        background-size: contain;
        width: 590px;
        height: 299px;        
    } 
    
    .anouncement3Blue{
      background-image: url("../../../assets/img/Blue announcement card.png");
      background-repeat: no-repeat;
      background-size: contain;
      width: 590px;
      height: 294px;   
  }
  
  .anouncement3Green{
    background-image: url("../../../assets/img/Green announcement card.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 590px;
    height: 299px;   
  }
  }

@media screen and (min-width: 882px) and (max-width: 924px) {
  .anouncement3 {
    background-image: url("../../../assets/img/Circle Organe.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 550px;
    height: 279px;
  }

  .anouncement3Blue{
    background-image: url("../../../assets/img/Blue announcement card.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 550px;
    height: 274px;   
}

.anouncement3Green{
  background-image: url("../../../assets/img/Green announcement card.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 550px;
  height: 279px;   
}
}
@media screen and (min-width: 814px) and (max-width: 882px) {
  .anouncement3 {
    background-image: url("../../../assets/img/Circle Organe.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 484px;
    height: 259px;
  }

  .anouncement3Blue{
    background-image: url("../../../assets/img/Blue announcement card.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 484px;
    height: 254px;   
}

.anouncement3Green{
  background-image: url("../../../assets/img/Green announcement card.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 484px;
  height: 259px;   
}
}
@media screen and (min-width: 768px) and (max-width: 814px) {
  .anouncement3 {
    background-image: url("../../../assets/img/Circle Organe.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 425px;
    height: 220px;
  }

  .anouncement3Blue{
    background-image: url("../../../assets/img/Blue announcement card.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 425px;
    height: 217px;   
}

.anouncement3Green{
  background-image: url("../../../assets/img/Green announcement card.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 425px;
  height: 220px;   
}
}

@media screen and (min-width: 700px ) and (max-width: 767px ){
  .anouncement3{
      background-image: url("../../../assets/img/Circle Organe.png");
      background-repeat: no-repeat;
      background-size: cover;
      width: 618px;
      height: 333px;        
  }
  
  .anouncement3Blue{
    background-image: url("../../../assets/img/Blue announcement card.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 618px;
    height: 328px;   
}

.anouncement3Green{
  background-image: url("../../../assets/img/Green announcement card.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 618px;
  height: 324px;   
}
}
@media screen and (min-width: 646px) and (max-width: 700px) {
  .anouncement3 {
    background-image: url("../../../assets/img/Circle Organe.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 547px;
    height: 296px;
  }
  .anouncement3Blue{
    background-image: url("../../../assets/img/Blue announcement card.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 547px;
    height: 290px;   
}

.anouncement3Green{
  background-image: url("../../../assets/img/Green announcement card.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 547px;
  height: 296px;   
}
}
@media screen and (min-width: 600px) and (max-width: 646px) {
  .anouncement3 {
    background-image: url("../../../assets/img/Circle Organe.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 512px;
    height: 268px;
  }

  .anouncement3Blue{
    background-image: url("../../../assets/img/Blue announcement card.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 512px;
    height: 264px;   
}

.anouncement3Green{
  background-image: url("../../../assets/img/Green announcement card.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 512px;
  height: 268px;   
}
}
@media screen and (min-width: 546px) and (max-width: 600px) {
  .anouncement3 {
    background-image: url("../../../assets/img/Circle Organe.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 465px;
    height: 243px;
  }

  .anouncement3Blue{
    background-image: url("../../../assets/img/Blue announcement card.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 465px;
    height: 239px;   
}

.anouncement3Green{
  background-image: url("../../../assets/img/Green announcement card.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 465px;
  height: 243px;   
}
}
@media screen and (min-width: 500px) and (max-width: 546px) {
  .anouncement3 {
    background-image: url("../../../assets/img/Circle Organe.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 400px;
    height: 214px;
  }

  .anouncement3Blue{
    background-image: url("../../../assets/img/Blue announcement card.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 400px;
    height: 211px;   
}

.anouncement3Green{
  background-image: url("../../../assets/img/Green announcement card.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 400px;
  height: 214px;   
}
}
@media screen and (min-width: 446px) and (max-width: 500px) {
  .anouncement3 {
    background-image: url("../../../assets/img/Circle Organe.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 368px;
    height: 220px;
  }

  .anouncement3Blue{
    background-image: url("../../../assets/img/Blue announcement card.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 368px;
    height: 220px;   
}

.anouncement3Green{
  background-image: url("../../../assets/img/Green announcement card.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 368px;
  height: 220px;   
}
}
@media screen and (min-width: 418px) and (max-width: 446px) {
  .anouncement3 {
    background-image: url("../../../assets/img/Circle Organe.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 316px;
    height: 210px;
  }

  .anouncement3Blue{
    background-image: url("../../../assets/img/Blue announcement card.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 316px;
    height: 210px;   
}

.anouncement3Green{
  background-image: url("../../../assets/img/Green announcement card.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 316px;
  height: 210px;   
}
}

@media screen and (min-width: 400px) and (max-width: 418px) {
  .anouncement3 {
    background-image: url("../../../assets/img/Circle Organe.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 280px;
    height: 200px;
  }

  .anouncement3Blue{
    background-image: url("../../../assets/img/Blue announcement card.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 280px;
    height: 200px;   
}

.anouncement3Green{
  background-image: url("../../../assets/img/Green announcement card.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 280px;
  height: 200px;   
}
}

@media screen and (min-width: 380px) and (max-width: 400px) {
  .anouncement3 {
    background-image: url("../../../assets/img/Circle Organe.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 250px;
    height: 190px;
  }

  .anouncement3Blue{
    background-image: url("../../../assets/img/Blue announcement card.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 250px;
    height: 190px;   
}

.anouncement3Green{
  background-image: url("../../../assets/img/Green announcement card.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 250px;
  height: 190px;   
}
}

@media screen and (min-width: 360px) and (max-width: 380px) {
  .anouncement3 {
    background-image: url("../../../assets/img/Circle Organe.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 220px;
    height: 180px;
  }

  .anouncement3Blue{
    background-image: url("../../../assets/img/Blue announcement card.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 220px;
    height: 180px;   
}

.anouncement3Green{
  background-image: url("../../../assets/img/Green announcement card.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 220px;
  height: 180px;   
}
}

@media screen and (min-width: 340px) and (max-width: 360px) {
  .anouncement3 {
    background-image: url("../../../assets/img/Circle Organe.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 205px;
    height: 170px;
  }

  .anouncement3Blue{
    background-image: url("../../../assets/img/Blue announcement card.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 205px;
    height: 170px;   
}

.anouncement3Green{
  background-image: url("../../../assets/img/Green announcement card.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 205px;
  height: 170px;   
}
}

@media screen and (min-width: 319px) and (max-width: 340px) {
  .anouncement3 {
    background-image: url("../../../assets/img/Circle Organe.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 190px;
    height: 160px;
  }

  .anouncement3Blue{
    background-image: url("../../../assets/img/Blue announcement card.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 190px;
    height: 160px;   
}

.anouncement3Green{
  background-image: url("../../../assets/img/Green announcement card.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 190px;
  height: 160px;   
}
}

@media screen and (max-width: 768px) {
.anouncenementHeadingWrapper{
    margin-top: 24px;
     margin-left: 83px;
}
}

@media screen and (max-width: 418px ){
    .anouncenementHeadingWrapper{
         margin-left: 77px;
    } 
  }

  @media screen and (max-width: 411px ){
    .anouncenement{
         margin-left: 22px;
    } 
  }

  @media screen and (max-width: 400px ){
    .anouncenement{
         margin-left: 12px;
    } 
  }

  @media screen and (max-width: 385px ){
    .anouncenementHeadingWrapper{
         margin-left: 78px;
    } 
  } 

  @media screen and (max-width: 360px ){
    .anouncenementHeadingWrapper{
         margin-left: 78px;
    } 
  }
  
  @media screen and (max-width: 320px ){
    .anouncenementHeadingWrapper{
         margin-left: 78px;
    } 
    .anouncenement {
      margin-left: 8px;
  }
  }  
  .PaginationWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 30px;
  
  }
  .ClinicAdminTableSearchBar{
    margin-left: 80px; 
    margin-top: 20px;
    margin-bottom: 30px;
  }
  
.scopedstyle{
  position:relative;
  background-color: white;
    
}

.scopedstyle:before {
  position:absolute;
  content: "";
  top:-40px;
  left:0;
  height:40px;
  width: 40px;
  border-bottom-left-radius: 50%;
  box-shadow: 0 20px 0 0 white;
}
.dateRangeWrapperAtTable{
  display : flex;
  flex-direction: row;
  justify-content: flex-end;
  color: #6A768F;
  font-family: "Roboto";
  font-weight: 500;
  margin-right: 32px;
  margin-bottom: 12px;
  

}

.downloadwrapper{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  color: #6A768F;
  font-family: "Roboto";
  font-weight: 800;
  padding-left:48px;
  /* cursor: pointer;
  text-decoration: underline ; */
  padding-top: 5px;
  padding-right: 16px;
}
.downloadwrappercaresitesettings{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  color: #6A768F;
  font-family: "Roboto";
  font-weight: 800;
  padding-left:28px;
  /* cursor: pointer; */
  /* text-decoration: underline ; */
  padding-top: 5px;
  padding-right: 16px;
}

@media screen and (max-width: 1115px ){
  .PatientTableWrapper{
    background-color: transparent;
    width: 835px;
    overflow-x: scroll;
}
}

@media screen and (max-width: 1050px ){
  .PatientTableWrapper{
    background-color: transparent;
    width: 770px;
    overflow-x: scroll;
}
}

@media screen and (max-width: 980px ){
  .PatientTableWrapper{
    background-color: transparent;
    width: 700px;
    overflow-x: scroll;
}
}

@media screen and (max-width: 930px ){
  .PatientTableWrapper{
    background-color: transparent;
    width: 620px;
    overflow-x: scroll;
}
}

@media screen and (max-width: 830px ){
  .PatientTableWrapper{
    background-color: transparent;
    width: 560px;
    overflow-x: scroll;
}
}


@media screen and (max-width: 775px ){
  .PatientTableWrapper{
    background-color: transparent;
    width: 500px;
    overflow-x: scroll;
}
}

@media screen and (max-width: 767px ){
  .PatientTableWrapper{
    background-color: transparent;
    width: 750px;
    overflow-x: scroll;
}
}

@media screen and (max-width: 705px ){
  .PatientTableWrapper{
    background-color: transparent;
    width: 690px;
    overflow-x: scroll;
}
}

@media screen and (max-width: 647px ){
  .PatientTableWrapper{
    background-color: transparent;
    width: 630px;
    overflow-x: scroll;
}
}

@media screen and (max-width: 585px ){
  .PatientTableWrapper{
    background-color: transparent;
    width: 570px;
    overflow-x: scroll;
}
}

@media screen and (max-width: 527px ){
  .PatientTableWrapper{
    background-color: transparent;
    width: 510px;
    overflow-x: scroll;
}
}

@media screen and (max-width: 467px ){
  .PatientTableWrapper{
    background-color: transparent;
    width: 450px;
    overflow-x: scroll;
}
}


@media screen and (max-width: 410px ){
  .PatientTableWrapper{
    background-color: transparent;
    width: 390px;
    overflow-x: scroll;
}
}

@media screen and (max-width: 346px ){
  .PatientTableWrapper{
    background-color: transparent;
    width: 330px;
    overflow-x: scroll;
}
}

.clinicDashboardHeading{
  margin-top: 0px;
  padding-left: 83px;
  color: #3d446f;
  font-family: "Roboto";
  font-size: 20px;
  font-weight: 500;
}

@media screen and (max-width: 768px) {
  .clinicDashboardHeading {
    padding-top: 126px;
  }
}

.dateRangeWrapper{
  margin-top: 10px;
  color: #6A768F;
  font-family: "Roboto";
  font-weight: 500;
  margin-right: 48px;
}

@media screen and (max-width: 1115px) {
  .dateRangeWrapper{
    padding-left: 130px;
  }
}

@media screen and (max-width: 1092px) {
  .dateRangeWrapper{
    padding-left: 115px;
  }
}

@media screen and (max-width: 1080px) {
  .dateRangeWrapper{
    padding-left: 105px;
  }
}

@media screen and (max-width: 1065px) {
  .dateRangeWrapper{
    padding-left: 95px;
  }
}


@media screen and (max-width: 1055px) {
  .dateRangeWrapper{
    padding-left: 85px;
  }
}

@media screen and (max-width: 1040px) {
  .dateRangeWrapper{
    padding-left: 75px;
  }
}

@media screen and (max-width: 1020px) {
  .dateRangeWrapper{
    padding-left: 65px;
  }
}

@media screen and (max-width: 1010px) {
  .dateRangeWrapper{
    padding-left: 55px;
  }
}

@media screen and (max-width: 990px) {
  .dateRangeWrapper{
    padding-left: 45px;
  }
}

@media screen and (max-width: 970px) {
  .dateRangeWrapper{
    padding-left: 35px;
  }
}

@media screen and (max-width: 950px) {
  .dateRangeWrapper{
    padding-left: 25px;
  }
}

@media screen and (max-width: 930px) {
  .dateRangeWrapper{
    padding-top: 12px;
    padding-left: 84px;
  }
}

@media screen and (max-width: 767px) {
  .dateRangeWrapper{
    padding-left: 70px;
  }
}

@media screen and (max-width: 740px) {
  .dateRangeWrapper{
    padding-left: 60px;
  }
}

@media screen and (max-width: 720px) {
  .dateRangeWrapper{
    padding-left: 50px;
  }
}

@media screen and (max-width: 700px) {
  .dateRangeWrapper{
    padding-left: 40px;
  }
}


@media screen and (max-width: 690px) {
  .dateRangeWrapper{
    padding-left: 30px;
  }
}

@media screen and (max-width: 680px) {
  .dateRangeWrapper{
    padding-left: 20px;
  }
}

@media screen and (max-width: 670px) {
  .dateRangeWrapper{
    padding-top: 12px;
    padding-left: 84px;
  }
} 

@media screen and (min-width: 1024px ){
  .clinicDashboardHeading{
    font-size: 17px;
    padding-bottom: 14px;
  }
  .excelIconWrapper{
    width: 24px;
    height: 24px;
     margin-left: 30px;
    /* margin-top: -8px;  */
  }
}

@media screen and (min-width: 1280px ){
  .clinicDashboardHeading{
    font-size: 21.12px;
  }
  .excelIconWrapper{
    width: 32px;
    height: 32px;
    margin-left: 30px;
    /* margin-top: -16px; */
  }
}

@media screen and (min-width: 1440px ){
  .clinicDashboardHeading{
    font-size: 24px;
    padding-bottom: 25px;
  }
  .excelIconWrapper{
    width: 40px;
    height: 40px;
    margin-left: 30px;
    /* margin-top: -16px; */
  }
}

@media screen and (min-width: 1920px ){
  .clinicDashboardHeading{
    font-size: 32px;
    padding-bottom: 14px;
  }
  .excelIconWrapper{
    width: 48px;
    height: 48px;
    margin-left: 30px;
    /* margin-top: -16px; */
  }
}

.whiteSeparatorOneWrapper{
  width: 100%;
  height: 100%;
  margin-bottom: -6px;
}

.gradientSeparatorWrapper{
    background-image: url("../../../assets/img/Sepration gradiant.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%; 
}
