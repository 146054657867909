.mainTable {
    width: 100%;
    border : 1px solid #6A768F80; 
    border-collapse: collapse;
    overflow-x: auto;
}
.tableRow {    
    border : 1px solid #6A768F80; 
    border-collapse: collapse;
    overflow-x: auto;
}
.tabelHeadingImg {
    width : 10%;
    border : 1px solid #6A768F80; 
    border-collapse: collapse;
    text-align: center;

}
.tabelHeading{
    width : 22%;
    border : 1px solid #6A768F80; 
    border-collapse: collapse;
    text-align: center;
}
.tabelDescAndRowAfterMapping{
    border : 1px solid #6A768F80; 
    border-collapse: collapse;
}
.tableDesc{
    text-align: center;
    font-family: 'Roboto';
    font-weight: 450;
}
.tabelHeading1{
    width : 18%;
    border : 1px solid #6A768F80; 
    border-collapse: collapse;
}
.tabelHeading2{
    width : 18%;
    border : 1px solid #6A768F80; 
    border-collapse: collapse;
}
.tabelHeading3{
    width : 45%;
    border : 1px solid #6A768F80; 
    border-collapse: collapse;
}
.tabelHeading4{
    width : 30%;
    border : 1px solid #6A768F80; 
    border-collapse: collapse;
}
.tabelHeadin5{
    width : 30%;
    border : 1px solid #6A768F80; 
    border-collapse: collapse;
}
.tabelDescAndRowAfterMapping:hover{
    background-color: #F3FCFE;
    cursor: pointer;
}
.tableHeadingTop{
    text-align: center;
    font-family: 'Roboto';
    font-weight: 800;
    color : #3D446F;  
    padding : 10px;
}

.nodatafoundWrapper{
    text-align: center;
    font-family: 'Roboto';
    font-weight: 500;
    color : #3D446F;  
    padding-top: 10px;
    padding-bottom: 10px;
}
