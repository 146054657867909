.container {
  display: flex;
  flex-direction: column;
}
.container .abmImgDiv {
  align-self: center;
  padding-bottom: 32px;
}

.abmLogoImg {
  width: 162px;
  height: 160px;
}

.dashboardDiv {
  margin-top: 138px;
  display: flex;
  align-items: center;
  margin-left: 32px;
}
.dashboard {
  color: #6a768f;
  margin-left: 24px;
  text-align: left;
  font-size: 24px;
  font-family: "Roboto";
  cursor: pointer;
}
.notification {
  color: #6a768f;
  margin-left: 24px;
  text-align: left;

  font-family: "Roboto";
  cursor: pointer;
  font-weight: 700;
}
.container .notificationDiv {
  display: flex;
  margin-top: 24px;
  align-items: center;
  margin-left: 32px;
}
.container .helpImgDiv {
  margin-top: 64px;
}
.helpDeskChip {
  margin-top: 32px;
  text-align: center;
  text-transform: capitalize;
}
::-webkit-scrollbar {
  width: 0px;
}
.notification:hover {
  color: #36434b;
}

@media only screen and (max-width: 768px) {
  .container {
    display: none;
  }
}

.notification-collapseBar {
  color: #6a768f;
  margin-left: 24px;
  text-align: left;
  font-size: 16px;
  font-family: "Roboto";
  cursor: pointer;
  font-weight: 700;
  margin-top: 3px;
}

.notification-collapseBar:hover {
  color: #36434b;
}

.messageDiv {
  display: flex;
  margin-top: 24px;
  align-items: center;
}

.message-collapseBar {
  color: #6a768f;
  margin-left: 24px;
  text-align: left;
  font-size: 16px;
  font-family: "Roboto";
  cursor: pointer;
  font-weight: 700;
  margin-top: 3px;
}

.message-collapseBar:hover {
  color: #36434b;
}

.helpDeskChip-collapsebar {
  margin-top: 56px;
  text-align: center;
  text-transform: capitalize;
}

.MuiBadge-badge .badge {
  background-color: red !important;
}

.buttonhoverWrapper {
  background-color: white;
}

.buttonhoverWrapper:hover {
  background-color: #f26930;
  width: 50px;
}

@media screen and (min-width: 1024px) {
  .abmLogoImg {
    width: 200px;
    height: 190px;
  }

  .sidebarIconWrapper {
    height: 12.8px;
    width: 12.8px;
  }
}

@media screen and (min-width: 1280px) {
  .abmLogoImg {
    width: 200px;
    height: 190px;
  }
  .sidebarIconWrapper {
    height: 15.84px;
    width: 15.84px;
  }
}

@media screen and (min-width: 1440px) {
  .abmLogoImg {
    width: 200px;
    height: 190px;
  }
  .sidebarIconWrapper {
    height: 18px;
    width: 18px;
  }
}

@media screen and (min-width: 1920px) {
  .abmLogoImg {
    width: 200px;
    height: 190px;
  }
  .sidebarIconWrapper {
    height: 24px;
    width: 24px;
  }

  .container .notificationDiv {
    margin-left: 8px;
  }
  .helpcenterwrapper {
    font-size: 24px;
  }
}
