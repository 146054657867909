.confirmaccount {
  align-items: left;
}
.alertmsg {
  color: red;
  font-size: 12px;
  text-align: left;
  padding-top: 12px;
  margin-left: 2px;
}
.buttonbutnPopup {
  justify-content: right;
  /* margin-left: 84%; */
  width: 30%;
  height: 10%;
  top: -20px;
  /* margin-top:16px; */
  align-items: right;
  float: right;
}
.buttonbutnPopupforsaveinotp {

  justify-content: right;
  margin-left: 30px;
  width: 30%;
  height: 10%;
  /* top: -20px; */
  /* margin-top:16px; */
  align-items: right;
  float: right;
}
