.editIconWrapper{
    width: 22px;
    height: 22px;
    margin-right: 16px;
    cursor: pointer;
}

.addNoteInputWrapperone{
    border: 1px solid white;
    background: white;
    width: 100%;
    height: 40px;
    font-weight: 500;
    color: #646a8d;
    font-size: 14px;
    margin-bottom: 24px;
    overflow-y: scroll;
}
.addNoteInputWrapper{
    border: 1px solid white;
    background: white;
    width: 100%;
    height: 240px;
    font-weight: 500;
    color: #646a8d;
    font-size: 14px;
    margin-bottom: 24px;
    overflow-y: scroll;
}

.addNoteInputWrapperBeforeEdit{
    border: 1px solid white;
    background: white;
    width: 100%;
    height: 240px;
    font-weight: 500;
    color: #646a8d;
    font-size: 14px;
    margin-bottom: 62px;
    overflow-y: scroll;
}

.css-13r4j3i .MuiTextField-root {
    width: 30em;
    border: 1px solid white;
    background: white;
}
.addNoteWrapper{
    width: 100%;
}
.addNoteContentWrapper{
    background: #F2F7F8;
    background-image: url("../../assets//img/caresiteBgImage.png");
    background-repeat: no-repeat;
    background-position: right top;
    background-size: 350px 350px;
  }