.linkedinImgWrapper{
    margin-left: 0px;
}

.twitterImgWrapper{
    margin-top: 0px;
}

@media screen and (max-width: 1115px ){
    .linkedinImgWrapper{
        margin-left: -30px;
    }
    .twitterImgWrapper{
        margin-top: -3px;
    }
  }

  @media screen and (max-width: 1063px ){
    .linkedinImgWrapper{
        margin-left: -48px;
    }
    .twitterImgWrapper{
        margin-top: -3px;
    }
  }

  @media screen and (max-width: 320px ){
    .linkedinImgWrapper{
        margin-left: -60px;
    }
  }