.Caresitesettingscardwapper{
    display: flex;
    justify-content: center;
    /* margin-top: 40px; */
    /* border: ipx solid #FFFFFF;
    background-color: #FFFFFF;
    border-radius: 8px; */
    /* width: 374px;
    height: 505px; */
    /* margin-left: 80px; */
    /* box-shadow: 0px 3px 40px #6A768F38; */
}

.InvitationsCardsimageWrapper{
    /* display: flex;
    justify-content: center;
    align-items: center; */
    align-self : center;
    width: 132px;
    height: 132px;
    margin : auto;
    /* margin-left: 50px; */
}

.InvitationsCardsenrolledRoleWrapper{
    display: flex;
    padding-top: 35.37px;
    font-size: 25px;
    margin : auto;
    font-weight: 500;
    flex-direction: row;
    justify-content: center;
}
/* .CaresitesettingsenrolledWrapper{
font-family: 'Roboto';
font-weight: 500;
color: #6A768F;
} */
.newinvitationtextWrapper{
    font-family: 'Roboto';
    font-weight: 500;
    color: #6A768F;
    font-size: 24px;
    text-align: center;
}
.sentinvitationtextWrapper{
    font-family: 'Roboto';
    font-weight: 500;
    color: #6A768F;
    font-size: 24px;
    text-align: center;

}
.receivedinvitationtextWrapper{
    font-family: 'Roboto';
    font-weight: 500;
    color: #6A768F; 
    font-size: 24px;
    text-align: center;

}
.CaresitesettingsenrolledCountWrapper{
    font-family: 'Roboto';
    font-weight: 500;
color: #F26930;
}

.InvitationCardsviewButtonWrapper{
    /* box-shadow: 0px 3px 10px #6a768f38; */
    filter: drop-shadow(0px 2px 4px #6A768F80);
    border: 2px solid #F2F8F2;
    background: #F2F8F2;
    color: #3D446F;
    font-weight: 700;
    border-radius: 6px;
    /* width: 247px;
    height: 46px; */
    display: flex;
    justify-content: center;
    margin-left: 40px;
    margin-right: 46px;
    margin-top: 29px;
    /* font-size: 22px; */
    cursor: pointer;
    padding: 10px;
    margin-bottom: 40px;
}

.sent-inivtaion-button-hover:hover{
    border: 2px solid #FF4B00;
}

.received-inivtaion-button-hover:hover{
    border: 2px solid #9A9C23;
}

.new-inivtaion-button-hover:hover{
    border: 2px solid #7eccde;
}

/* .CaresitesettingsviewButtonWrapper:hover{
    width: 240px;
    height: 53px;
    transition-delay:1.8s;
} */

.CaresitesettingsinviteButtonWrapper{
    box-shadow: 0px 3px 10px #6A768F38;
    border: 1px solid #f3fcfe;
    background: #f3fcfe;
    color: #3D446F;
    font-weight: 700;
    border-radius: 8px;
    /* width: 247px;
    height: 46px; */
    display: flex;
    justify-content: center;
    margin-left: 40px;
    margin-right: 46px;
    margin-top: 24.5px;
    font-size: 22px;
    cursor: pointer;
   padding: 10px;
   
}

.CaresitesettingsinviteButtonWrapper:hover{
    width: 240px;
    height: 53px;
    transition-delay:1s ;
    /* margin : auto; */
}

@media screen and (max-width: 1115px ){
    .InvitationsCardsenrolledRoleWrapper{
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding:18px;
    }
  }

  @media only screen and (max-width:1120px) and (min-width:1095px) {
   
   
  }
/* 
  @media (max-width:1097px) and (min-width:1052px)  {
    .newinvitationtextWrapper{
        padding-bottom: 50px;
     
    }

   
  } */
  @media only screen and (max-width:1134px)and(min-width:1052px)  {
    
    .newinvitationtextWrapper{
        padding-bottom: 20px;
     
    }
    .sentinvitationtextWrapper{
        padding-bottom: 25px;
     
    }
  }
  @media only screen and (max-width:1052px)and(min-width:1034px)  {
    
    .newinvitationtextWrapper{
        padding-bottom: 24px;
     
    }
    .sentinvitationtextWrapper{
        padding-bottom: 7px;
     
    }
  }
  @media only screen and (max-width:1078px)  {
    
    .newinvitationtextWrapper{
        padding-bottom: 24px;
     
    }
    .sentinvitationtextWrapper{
        padding-bottom: 1px;
     
    }
  }
  @media only screen and (max-width:1048px)  {
    
    .newinvitationtextWrapper{
        padding-bottom: 2px;
     
    }
   
  }
  @media only screen and (max-width:1034px)  {
    
    .newinvitationtextWrapper{
        padding-bottom: 1px;
     
    }
    .sentinvitationtextWrapper{
        padding-bottom: 2px;
     
    }
  }