.inviteModalContainer {
  background: #f2f4f8;
  padding: 16px 32px !important;
}

.mainDilogContent {
  background-color: #f2f4f8;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
}

.iconForemail {
  width: 100px !important;
}

.modalheader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.forheadertitle {
  font-size: 24px;
  color: "#3D446F";
  margin-left: 13px;
}

.closeIcon {
  cursor: pointer;
}

.backbutonWhite {
  background-color: #fff !important;
  border-radius: 50% !important;
  padding: 25px 30px !important;
  box-shadow: #6a768f80 0px 3px 6px;
  outline: none !important;
}

.buttonForMiddle {
  background-color: #f2f4f8 !important;
  text-transform: capitalize !important;
  padding: 10px 30px;
  border-radius: 10px;
  box-shadow: #6a768f80 0px 3px 6px;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  color: #4f557c;
  outline: none !important;
  min-width: 150px;
}

.buttonForMiddle:hover {
  background-color: #fff !important;
}

.buttonForSendInvition {
  background-color: #fff !important;
  text-transform: capitalize !important;
  width: 27%;
  /*  height: 30px; */
  padding: 15px !important;
  border-radius: 10px;
  box-shadow: #6a768f80 0px 3px 6px;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  margin-top: 12px;
  margin-bottom: 3px;
  color: #4f557c;
  outline: none !important;
}

.selectbox {
  width: 100%;
  border: none;
  box-shadow: #6a768f80 0px 3px 6px;
  border-radius: 8px;
  height: 45px;
  color: gray;
  text-transform: capitalize;
}

.selectbox:focus {
  outline: none !important;
}

.caresiteClinicName {
  margin-top: 100px !important;
  background-color: #f2f4f8 !important;
  color: #000 !important;
  border: 1px solid #f2f4f8 !important;
  outline: none !important;
  height: 30%;
}

.caresiteClinicName:hover {
  background-color: #fff !important;
}

.inputbox {
  width: 100%;
  border: none;
  box-shadow: #6a768f80 0px 3px 6px;
  border-radius: 5px;
  padding-bottom: 10px;
  padding-top: 10px;
}

@media only screen and (max-width: 768px) {
  .buttonForMiddle {
    background-color: #fff !important;
    text-transform: capitalize !important;
    padding: 6px 55px;
    border-radius: 50px;
    width: 100%;
    box-shadow: #6a768f80 0px 3px 6px;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
  }

  .serarchbuttonforInput {
    width: 100%;
    background-color: transparent !important;
    border-radius: 5px !important;
  }

  .buttonForSendInvition {
    background-color: #fff !important;
    text-transform: capitalize !important;
    width: 80%;
    padding: 10px;
    border-radius: 10px;
    box-shadow: #6a768f80 0px 3px 6px;
    font-weight: bold;
    font-size: 11px;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 40px;
  }

  .inviteModalContainer {
    background: #f2f4f8;
    height: auto;
  }

  .serachContainer {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}

.emailInputInvitation {
  width: 100%;
  border-color: transparent;
  border-radius: 8px;
  opacity: 1;
  height: 45px;
  background-color: white !important;
  box-shadow: #6a768f80 0px 3px 6px !important;
  font-family: "Roboto";
  font-weight: 500;
  color: #6a768f !important;
  /* margin-bottom: -14px; */
  height: 37px;
}

@media (max-width: 1023px) and (min-width: 769px) {
  .buttonForMiddle {
    background-color: #fff !important;
    text-transform: capitalize !important;
    padding: 10px 60px;
    border-radius: 10px;
    box-shadow: #6a768f80 0px 3px 6px;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    width: 100%;
    color: #4f557c;
    outline: none !important;
  }
}

.invitationImageWrapper {
  width: 800px;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 910px) {
  .invitationImageWrapper {
    width: 701px;
  }
}

@media screen and (max-width: 785px) {
  .invitationImageWrapper {
    width: 673px;
  }
}
@media screen and (max-width: 768px) {
  .dropDownMediaQuery {
    width: 50%;
  }
  .emailInputmediaQuery {
    width: 50%;
  }
}

@media screen and (max-width: 758px) {
  .invitationImageWrapper {
    width: 650px;
  }
}

@media screen and (max-width: 735px) {
  .invitationImageWrapper {
    width: 625px;
  }
}

@media screen and (max-width: 700px) {
  .invitationImageWrapper {
    width: 588px;
  }
}

@media screen and (max-width: 640px) {
  .invitationImageWrapper {
    width: 551px;
  }
}

@media screen and (max-width: 600px) {
  .invitationImageWrapper {
    width: 514px;
  }
}

@media screen and (max-width: 592px) {
  .invitationImageWrapper {
    width: 504px;
  }
}

@media screen and (max-width: 550px) {
  .invitationImageWrapper {
    width: 462px;
  }
}

@media screen and (max-width: 500px) {
  .invitationImageWrapper {
    width: 388px;
  }
}

@media screen and (max-width: 474px) {
  .invitationImageWrapper {
    width: 362px;
  }
}

@media screen and (max-width: 446px) {
  .invitationImageWrapper {
    width: 334px;
  }
}

@media screen and (max-width: 413px) {
  .invitationImageWrapper {
    width: 301px;
  }
}

@media screen and (max-width: 384px) {
  .invitationImageWrapper {
    width: 280px;
  }
}

.inviteModalBack {
  background-image: url("../../assets/img/black_back.png");
  background-repeat: no-repeat;
  background-position: right top;
  background-size: 550px 550px;
}

.select-role-dropdown {
  width: 100%;
  border: none;
  height: 37px;
  border-radius: 10px;
  box-shadow: #6a768f80 0px 3px 6px;
}
.select-role-dropdown:focus {
  outline: none;
}
