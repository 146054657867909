.RegisterwrapperContainer {
  width: 100%;
  background-color: #f0f2f4;
}
.RegisterimgWrapper {
  display: flex;
  justify-content: center;
}
.RegisterrightSide {
  width: 100%;
  height: 95vh !important;
  overflow-y: scroll;
  /* padding-top: 46px; */
}
@media screen and (min-height: 899px) {
  .RegisterrightSide {
    /* height: 100vh; */
    padding-top: 122px; /* padding-top: 42px; */
  }
}
.plus-register {
  margin-left: -37px;
  margin-top: 104px;
  font-size: 25px;
  color: #a7aaad;
  cursor: pointer;
}
.account {
  color: #3d446f;
  font-weight: 800;
  /* border-bottom: 2px solid #3D446F; */
  margin-bottom: 10px;
  font-family: "Roboto";
}
.textInRegister {
  color: #3d446f;
  text-align: center;
  font-size: 32px;
  font-weight: 800;
  font-family: "Roboto";
}
.emailInputInRegiter {
  width: 100%;
  border-color: #3d446f;
  border-radius: 8px;
  opacity: 1;
  border: none;
  background-color: white !important;
  box-shadow: #6a768f80 0px 3px 6px !important;
}
.inputfiedforchangepwd {
  width: 91%;
  border-color: #3d446f;
  border-radius: 8px;
  opacity: 1;
  border: none;
  background-color: white !important;
  box-shadow: #6a768f80 0px 3px 6px !important;
}

.divPadding {
  margin-right: 24px;
}
.divPadding1 {
  margin-right: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.labelsInCommomForRegister {
  margin-top: 14px;
  font-family: "Roboto";
  font-weight: 800;
  color: #6a768f;
  padding-bottom: 10px;
}
.labelsInCommomForRegisteremail {
  margin-top: 10px;
  font-family: "Roboto";
  font-weight: 800;
  color: #6a768f;
  padding-bottom: 10px;
}
.eyeStyle {
  background-color: white;
  /* align-self : center; */
  /* height : 48px; */
  box-shadow: #6a768f80 0px 3px 6px;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
}

.RegisterrightSide .Registrtext_1 {
  font-size: 55px;
  text-align: center;
  font-weight: bold;
  color: #6a768f;
  font-family: "Roboto";
}
.RegisterrightSide .Registrtext_1 .RegisterinnerText {
  display: inline-block;
  text-align: left;
}
.RegisterrightSide .Registrtext_1 .RegisterinnerText2 {
  font-size: 46px;
  color: #f26930;
  position: relative;
  /* bottom: 24px; */
}
.SignupSuccessPopup {
  border: 1px solid #f2f4f8;
  background: #f2f4f8;
  border-radius: 8px;
  color: #6a768f;
  font-size: 18px;
  font-weight: 500;
  margin: 56px 50px 56px 50px;
  height: 63%;
}

.resendOtpWrapper {
  color: #3d446f;
  font-weight: bold;
  font-size: 14px;
  text-decoration: underline;
  margin-top: 16px;
  justify-content: center;
  display: flex;
  cursor: pointer;
}

.PhoneInputforRegister {
  /* width: 380px; */
  width: 10% !important;
  /* margin-right: 20px; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: white !important;
  box-shadow: #6a768f80 0px 3px 6px !important;
}

@media screen and (min-width: 1920px) {
  .PhoneInputforRegister {
    font-size: 21px !important;
  }
}
.PhoneinputWrapper {
  margin-left: 180px;
  margin-right: 180px;
}

.otpmsgInRegister {
  color: #3d446f;
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  margin: 10px 0px;
  font-family: "Roboto";
  text-align: center;
}

.leftSideInRegisterPage {
  background-color: white;
  width: 50%;
  height: 95vh !important;
}
.labaleforRegister {
  color: #3d446f;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  font-family: "Roboto";
}
.otp-time {
  display: flex;
  justify-content: center;
  align-items: center;
}
.otp-time p {
  color: #3d446f;
  text-align: center;
  font-size: 12px;
  font-weight: 800;
  font-family: "Roboto";
}
.otp-time span {
  color: #f26930;
}

@media only screen and (max-width: 1115px) {
  .PhoneinputWrapper {
    margin-left: 120px;
    margin-right: 120px;
  }
}

@media only screen and (max-width: 1094px) {
  .PhoneinputWrapper {
    margin-left: 110px;
    margin-right: 110px;
  }
}

@media only screen and (max-width: 1074px) {
  .PhoneinputWrapper {
    margin-left: 110px;
    margin-right: 110px;
  }
}

@media only screen and (max-width: 1061px) {
  .PhoneinputWrapper {
    margin-left: 100px;
    margin-right: 100px;
  }
}

@media only screen and (max-width: 1036px) {
  .PhoneinputWrapper {
    margin-left: 100px;
    margin-right: 100px;
  }
}

@media only screen and (max-width: 1023px) {
  .PhoneinputWrapper {
    margin-left: 350px;
    margin-right: 350px;
  }
  .leftSideInRegisterPage {
    width: 100%;
  }
}

@media only screen and (max-width: 1013px) {
  .PhoneinputWrapper {
    margin-left: 345px;
    margin-right: 345px;
  }
  .leftSideInRegisterPage {
    width: 100%;
  }
}

@media only screen and (max-width: 1006px) {
  .PhoneinputWrapper {
    margin-left: 340px;
    margin-right: 340px;
  }
}

@media only screen and (max-width: 997px) {
  .PhoneinputWrapper {
    margin-left: 340px;
    margin-right: 340px;
  }
}

@media only screen and (max-width: 987px) {
  .PhoneinputWrapper {
    margin-left: 335px;
    margin-right: 335px;
  }
}

@media only screen and (max-width: 977px) {
  .PhoneinputWrapper {
    margin-left: 325px;
    margin-right: 325px;
  }
}

@media only screen and (max-width: 967px) {
  .PhoneinputWrapper {
    margin-left: 325px;
    margin-right: 325px;
  }
}

@media only screen and (max-width: 957px) {
  .PhoneinputWrapper {
    margin-left: 320px;
    margin-right: 320px;
  }
}

@media only screen and (max-width: 947px) {
  .PhoneinputWrapper {
    margin-left: 315px;
    margin-right: 315px;
  }
}

@media only screen and (max-width: 937px) {
  .PhoneinputWrapper {
    margin-left: 310px;
    margin-right: 310px;
  }
}

@media only screen and (max-width: 927px) {
  .PhoneinputWrapper {
    margin-left: 305px;
    margin-right: 305px;
  }
}

@media only screen and (max-width: 917px) {
  .PhoneinputWrapper {
    margin-left: 300px;
    margin-right: 300px;
  }
}

@media only screen and (max-width: 909px) {
  .PhoneinputWrapper {
    margin-left: 290px;
    margin-right: 290px;
  }
}

@media only screen and (max-width: 889px) {
  .PhoneinputWrapper {
    margin-left: 285px;
    margin-right: 285px;
  }
}

@media only screen and (max-width: 872px) {
  .PhoneinputWrapper {
    margin-left: 275px;
    margin-right: 275px;
  }
}

@media only screen and (max-width: 852px) {
  .PhoneinputWrapper {
    margin-left: 260px;
    margin-right: 260px;
  }
}

@media only screen and (max-width: 832px) {
  .PhoneinputWrapper {
    margin-left: 255px;
    margin-right: 255px;
  }
}

@media only screen and (max-width: 825px) {
  .PhoneinputWrapper {
    margin-left: 250px;
    margin-right: 250px;
  }
}

@media only screen and (max-width: 815px) {
  .PhoneinputWrapper {
    margin-left: 245px;
    margin-right: 245px;
  }
}

@media only screen and (max-width: 800px) {
  .PhoneinputWrapper {
    margin-left: 240px;
    margin-right: 240px;
  }
}

@media only screen and (max-width: 790px) {
  .PhoneinputWrapper {
    margin-left: 235px;
    margin-right: 235px;
  }
}

@media only screen and (max-width: 770px) {
  .PhoneinputWrapper {
    margin-left: 225px;
    margin-right: 225px;
  }
}

@media only screen and (max-width: 750px) {
  .PhoneinputWrapper {
    margin-left: 215px;
    margin-right: 215px;
  }
}
@media only screen and (min-height: 700px) {
  .RegisterrightSide {
    width: 100%;
    height: 123vh;
  }
}

.passwrdHideIconSignUp {
  justify-content: flex-end;
  display: flex;
  padding: 23px;
  margin-top: -63px;
  outline: none;
  margin-right: 10px;
}
.passwrdHideIconSignUp button {
  outline: none;
}
input::-ms-reveal,
input::-ms-clear {
  display: none;
}
