.div1 {
  margin-left: 16px;
  margin-bottom: 20px;
  cursor: pointer;
}
.therapy {
  margin-left: 16px;
  margin-bottom: 21px;
  cursor: pointer;
  color: #f26930;
  font-family: "Roboto";
  font-weight: 400;
}
.section1 {
  cursor: pointer;
  display: flex;
  background-color:#fef4f0;
  margin-left: 24px;
  margin-right: 24px;
  border-radius: 6px;
  box-shadow: #6a768f80 0px 3px 6px;
  justify-content: space-between;
  border: 1px solid transparent;
}
.section1:hover {
  border: 1px solid rgb(242, 105, 48);
  border-radius: 6px;
}
.section1Div1 {
  margin: 10px;
  align-self: center;
}
.section1Div2 {
  display: flex;
  flex-direction: column;
  align-self: center;
}
.section1Div3 {
  justify-content: flex-end;
  align-self: center;
}
.desc {
  color: #6a768f;
  font-family: "Roboto";
  font-weight: 400;
}
.nums {
  color: #3d446f;
  font-family: "Roboto";
  font-weight: 600;
}

.section2 {
  cursor: pointer;
  display: flex;
  background-color: #f3fcfe;
  margin-left: 24px;
  margin-right: 24px;
  border-radius: 6px;
  box-shadow: #6a768f80 0px 3px 6px;
  margin-top: 16px;
  justify-content: space-between;
  border: 1px solid transparent;
}
.section2:hover {
  border: 1px solid lightblue;
  border-radius: 6px;
}
.section2Div1 {
  margin: 10px;
  align-self: center; 
  padding-bottom: 10px;
}
.section2Div2 {
  display: flex;
  flex-direction: column;
  align-self: center;
}
.section2Div3 {
  justify-content: flex-end;
  align-self: center;
}
.desc2 {
  color: #6a768f;
  font-family: "Roboto";
  font-weight: 400;
}
.nums2 {
  color: #3d446f;
  font-family: "Roboto";
  font-weight: 600;
}

.section3 {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  background-color: #f2f8f2;
  margin-left: 24px;
  margin-right: 24px;
  border-radius: 8px;
  box-shadow: #6a768f80 0px 3px 6px;
  margin-top: 16px;
  margin-bottom: 48px;
}
.section3:hover {
  width: 260px;
  /* height: 55px; */
  transition-delay: 1s;
}
.section3Div1 {
  margin: 10px;
  align-self: center;
  padding-bottom: 10px;
}
.section3Div2 {
  display: flex;
  flex-direction: column;
  align-self: center;
}
.section3Div3 {
  justify-content: flex-end;
  align-self: center;
}
.desc3 {
  color: #6a768f;
  font-family: "Roboto";
  font-weight: 400;
}
.nums3 {
  color: #3d446f;
  font-family: "Roboto";
  font-weight: 600;
}

@media only screen and (max-width: 1023px) {
  .section2:hover {
    border: 1px solid lightblue;
  border-radius: 6px;
  }
  .section1:hover {
    border: 1px solid rgb(242, 105, 48);
    border-radius: 6px;
  }

  .sectionThree:hover {
    border: 1px solid #9A9C23;
    border-radius: 6px;
  }
}
.sectionThree {
  display: flex;
  justify-content: space-between;
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 16px;
  background-color: #F2F8F2;
  border-radius: 6px;
  box-shadow: #6a768f80 0px 3px 6px;
  cursor: pointer;
  border: 1px solid transparent;
}

.sectionThree:hover {
  border: 1px solid #9A9C23;
  border-radius: 6px;
}

.cardImageWrapper{
  width: 33.6px;
   height:33px;
}


@media screen and (min-width: 1280px) {
  .cardSpace{
    padding-top: 36px;
  }

  .rightArrowImgWrapper{
    height: 40px;
  }

  .section1 {
    height: 72px;
    margin-bottom: 28px;
  }
  .section2 {
    height: 72px;
    margin-bottom: 28px;
  }
  .sectionThree{
    height: 72px;
    margin-bottom: 28px;
  }
  .cardImageWrapper{
    width: 50px;
     height:50.3px;
  }
}


.cardSpace{
  padding-top: 36px;
}

@media screen and (min-width: 1440px) {
  .cardImageWrapper{
    width: 47px;
     height:46.5px;
  }
}
@media screen and (min-width: 1920px) {
  /* .MuiPaper-root.MuiCard-root.makeStyles-mycard-5.MuiPaper-outlined.MuiPaper-rounded{
    height: 410px;
  } */

  
  .cardImageWrapper{
    width: 63px;
     height:62px;
  }

  .rightArrowImgWrapper{
    height: 53px;
  }
  .section1 {
    height: 72px;
    margin-bottom: 28px;
  }
  .section2 {
    height: 72px;
    margin-bottom: 28px;
  }
  .sectionThree{
    height: 72px;
    margin-bottom: 28px;
  }

  .cardSpace{
    padding-top: 45px;
  }
}



/* @media screen and (min-width: 1024px) {
  .MuiPaper-root.MuiCard-root.makeStyles-mycard-5.MuiPaper-outlined.MuiPaper-rounded{
    height: 320px;
  }
  .cardSpace{
    padding-top: 45px;
  }
} */





