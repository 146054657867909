.circular_chart{
    /* display: block; */
    /* margin: 20px auto; */
    max-width: 100%;
    max-height: 250px;
    /* box-shadow: red -18px 17px 6px; */
    
}
.circular_bg{
    fill: none;
}
.circle{
    fill:#fff0;
    
}
.percentage{
    font-weight: bolder;
    /* fill: rgb(8, 2, 2); */
    font-size: 47px;
    text-anchor: middle;

    
}
.circular-progresbar-wrapper{
    position: relative;
    height: 100%;
    width: 100%;
}
.circular-progresbar-wrapper svg {
    transform: rotate(-90deg);
    opacity:0.9px;
    /* -webkit-filter: drop-shadow( -3px -2px 10px rgb(216, 208, 208) );
  filter: drop-shadow( -3px -2px 5px  rgb(197, 188, 188)); */
  stroke-width: 10;
  stroke-linecap: round ;
  stroke-opacity:1px 0.4px 0.5px rgb(146, 141, 141) ;

}
.circular-progresbar-wrapper svg  .circle{
 -webkit-filter: drop-shadow( 0px 0px 3px rgb(216, 208, 208) );
  filter: drop-shadow( 0px 0px 3px  rgb(197, 188, 188));
}
.circular-progresbar-wrapper .progressbar-text{
    height: 95%;
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Roboto';
    color: #6a768f;
    font-weight: 800;
    font-size: 24px;
    background-color: white;
    border-radius: 100px;
}