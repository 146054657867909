.cnfrmac-img {
  padding-top: 20px;
  padding-left: 30px;
  padding-bottom: 24px;
  color: hsl(221, 15%, 49%);
  font-size: 16px;
  font-family: "Roboto";
  display: flex;
}
.piccreate {
  margin-right: 10px;
  width: 20px;
}

.buttonEyeIocnForChnagePassword {
  justify-content: flex-end;
  display: flex;
  padding: 15px;
  margin-top: -46px;
  outline: none;
  margin-right: 46px;
}
.buttonEyeIocnForChnagePassword button {
  outline: none;
}
