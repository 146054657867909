.ReportSearchBar {
  margin-left: 53px;
  margin-top: 15px;
  margin-right: 64px;
}

.report_search {
  width: 100%;
  font-family: "Roboto";
  /* font-size: 16px; */
  font-weight: 500;

  /* border-radius:8px; */
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  border: none;
  /* border-color: transparent; */
  outline: none;
  /* box-shadow: #6A768F80 0px 3px 6px;     */
}
.search-by {
  font-size: 20px;
  color: #3d446f;
  font-family: "Roboto";
  font-weight: 800;
}
.first-div {
  margin-left: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Upload-csv {
  margin-right: 66px;
}
.Upload-csv button {
  padding: 7px;
  border-radius: 5px;
  background: #fff;
  box-shadow: #6a768f80 0px 3px 6px;
  font-family: "Roboto";
  font-weight: 500;
  outline: none;
}
.uploadingcsv-heading {
  margin: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.uploadingcsv-heading i {
  color: #6a768f;
  margin-right: 10px;
}
.uploadingcsv-heading span {
  font-size: 16px;
  color: #6a768f;
  font-family: "Roboto";
  font-weight: 500;
}
.file-upload-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}
.file-uplaod-body {
  margin-left: 80px;
}
.criteria-div {
  font-size: 12px;
  color: #6a768f;
  font-family: "Roboto";
  font-weight: 800;
}

.second-div {
  margin-left: 53px;
  margin-top: 8px;
}
.report {
  height: 70vh;
}
.add-icon {
  display: flex;
  position: relative;
  box-shadow: #6a768f80 0px 3px 6px;
  border-radius: 8px;
  padding-left: 16px;
  background-color: white;
}
.srchImg {
  /* box-shadow: #6A768F80 0px 3px 6px; */
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
  position: relative;
  left: 3px;
  cursor: pointer;
}

.report-submit-button {
  background-color: white;
  text-transform: capitalize;
  text-align: center;
  display: flex;
  justify-content: center;
}

.reportsearchTable {
  border-collapse: collapse;
  width: 100%;
  text-align: left;
  font-family: "Roboto";
  font-weight: 800;
  color: #646a8d;
  font-size: 14px;
  text-align: left;
}
.reportmapdiv {
  width: "100%";
  cursor: pointer;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  border-bottom: 3px solid #f7f8fb;
}
.reportmapdiv:hover {
  background-color: #f3fcfe;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  /* cursor: pointer; */
}

.btn {
  border: 2px solid #2196f3;
  color: #2196f3;
  background-color: white;
  padding: 8px 20px;
  border-radius: 8px;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  align-items: center;
  padding-left: 100px;
  padding-right: 100px;
}
.btn2 {
  border: 2px solid #2196f3;
  color: #2196f3;
  background-color: white;
  padding: 8px 20px;
  border-radius: 8px;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
}
.upload-btn-wrapper input[type="file"] {
  /* font-size: 100px; */
  height: 50px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  /* cursor: pointer; */
}
.uploadcsv-text {
  font-size: 20px;
}

.ReportSearchBar input:focus {
  outline: none !important;
  border-bottom: none !important;
}

@media screen and (max-width: 768px) {
  .first-div {
    margin-top: 120px;
  }
  .Upload-csv {
    margin-top: 110px;
    margin-right: 20px;
  }
}

#table-scroll-report-data-table ::-webkit-scrollbar {
  width: 15px;
}

#table-scroll-report-data-table ::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

#table-scroll-report-data-table ::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.report-image-data {
  padding: 2px !important;
  margin-left: 10px !important;
}
.avatar-image-report {
  width: 32px;
  height: 32px;
  margin-left: 25px;
  border-radius: 50%;
}
.loading-dots {
  display: inline-block;
}

.loading-dots span {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin: 0 2px;
  background-color: #000; /* Customize the color */
  border-radius: 50%;
  animation: loading 1.5s infinite;
}

.loading-dots span:nth-child(1) {
  animation-delay: 0s;
}
.loading-dots span:nth-child(2) {
  animation-delay: 0.3s;
}
.loading-dots span:nth-child(3) {
  animation-delay: 0.6s;
}

@keyframes loading {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.5);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
