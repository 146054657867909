.pi-container{
    background-image: url('../../assets/img/userprofilediv/newBlueDesign.png');
    background-repeat: no-repeat;
    background-size: 500px 500px;
    background-position: right top;
    background-color: #F2F7F8;
    width: 852px;
    height: 110%px
}

.PersonalInformation_pic{
    display : flex;
    justify-content : center;
    align-items  : center;
    margin-bottom: 36px;
    margin-top: 20px;
}

    
.Personal_information{
    background-color:#F2F7F8;
    padding-top: 40px;
    display : flex;
    justify-content : space-between;
    padding-left: 40px;
    padding-right: 40px;
}
.backgroundforPersonal{
    position : fixed;
}
.caniconForPersonalInformation{
    cursor : pointer;
}
.header_Personalinformation{
    font-weight: 800 ;
    font-size: 24px;      
    font-family: "Roboto";
    color: #3D446F;
}
.personal-info-footer{
    margin-bottom: 40px !important;
}
/* .Personal_information .closeIcon{
        position: absolute;
      top: 20px;
    right: 3%;  
} */
.img1{
   top: 20px;
   width: 100px;
}

.personalinformation-table{
    margin-left: 3%;
    margin-right: 3%;
    margin-top: 63px;
}
.GenPersonalInfoDivs{
    font-size: 16px;
    font-family: "Roboto";
    font-weight: 800 ;
    /* color: #858fa4; */
    color: #0a0a0a;
    border-bottom: 1px solid black;
    padding-top: 16px; 
}


@media screen and (max-width:776px) and (min-width:650px){
    /* .Personal_information .header_Personalinformation{
      /*  margin-top: 40px;  */
    /*}*/
   /*  .Personal_information .closeIcon{
        
      top: 60px;
} */
/* .personalInformation2{
    width: 100px;
       top: 142px;
       right:40vh;
      
} */
}

@media screen and (max-width:650px) and (min-width:550px){
    .Personal_information .header_Personalinformation{
      /*   margin-top: 40px;  */
     }
   /*  .Personal_information .closeIcon{
        
        top: 60px;
  } */
  .personalInformation2{
      width: 100px;
         top: 134px;
         right:40vh;
        
  }
}
@media screen and (max-width:550px) and (min-width:430px){
    .Personal_information .header_Personalinformation{
       /*  margin-top: 40px;  */
     }
  .personalInformation2{
      width: 100px;
         top: 127px;
         right:30vh;
        
  }
}
@media screen and (max-width:430px) and (min-width:329px){
    /* .Personal_information .header_Personalinformation{ */
      /*   margin-top: 40px;  */
     /* } */
    .Personal_information .closeIcon{
        
        top: 60px;
  }
  .personalInformation2{
      width: 20%;
         top: 130px;
         right:22vh;
        
  }
}
@media screen and (max-width:320px){
    .personalInformation2{
        width: 20%;
           top: 43px;
           right:18vh;          
    }
}
@media screen and (max-width : 914px) {
    .pi-container{
        width : 600px;
    }
}
@media screen and (max-width : 577px) {
    .pi-container{
        width : 350px;
    }
}
@media screen and (max-width : 776px) {
    .Personal_information {
        margin-top: 0px;
    }
}

.personal-information-section-1{
    color : '#3D446F';
    font-family: "Roboto";
    font-weight: 800;
}