.Caresitesettingscardwapper{
    display: flex;
    justify-content: center;
    /* margin-top: 40px; */
    /* border: ipx solid #FFFFFF;
    background-color: #FFFFFF;
    border-radius: 8px; */
    /* width: 374px;
    height: 505px; */
    /* margin-left: 80px; */
    /* box-shadow: 0px 3px 40px #6A768F38; */
}

.CaresitesettingsimageWrapper{
    /* display: flex;
    justify-content: center;
    align-items: center; */
    align-self : center;
    width: 186px;
    height: 177px;
    margin : auto;
    /* margin-left: 50px; */
}

.CaresitesettingsenrolledRoleWrapper{
    display: flex;
    /* justify-content:space-evenly; */
    justify-content:flex-start;
    padding-top: 35.37px;
    font-size: 25px;
    margin : auto;
    font-weight: 500;
}
.CaresitesettingsenrolledWrapper{
    margin-left: 16px;
    /* text-align: center; */
    font-size: 25px;
/* font: normal normal medium 32px/38px Roboto; */
color: #6A768F;
}

.CaresitesettingsenrolledCountWrapper{
    margin-left: 12px;
    /* padding-right:9px; */
    /* padding-left: 20px; */
    margin-right:16px; 
    /* justify-content:space-evenly; */
    
    font-size: 30px;
/* font: normal normal medium 56px/67px Roboto; */
color: #F26930;
}

.CaresitesettingsviewButtonWrapper{
    box-shadow: 0px 3px 10px #6a768f38;
    border: 1px solid #F2F8F2;
    background: #F2F8F2;
    color: #3D446F;
    font-weight: 700;
    border-radius: 8px;
    /* width: 247px;
    height: 46px; */
    display: flex;
    justify-content: center;
    margin-left: 16px;
    /* margin-right: 46px; */
    margin-top: 29px;
    font-size: 22px;
    cursor: pointer;
    padding: 10px;
    width: 150px;
}

.CaresitesettingsinviteButtonWrapper{
    box-shadow: 0px 3px 10px #6A768F38;
    border: 1px solid #f3fcfe;
    background: #f3fcfe;
    color: #3D446F;
    font-weight: 700;
    border-radius: 8px;
    /* width: 247px;
    height: 46px; */
    display: flex;
    justify-content: center;
    margin-left: 16px;
    margin-right: 16px;
    margin-top: 29px;
    font-size: 22px;
    cursor: pointer;
    padding: 10px;
    width: 150px;
}

/* .CaresitesettingsinviteButtonWrapper:hover{
    width: 240px;
    height: 53px;
    transition-delay:1s ;
} */

@media screen and (max-width: 1115px ){
    .CaresitesettingsenrolledRoleWrapper{
        display: flex;
    justify-content:space-between;
    padding:18px;
    }
  }

.buttonWrapperDiv {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

@media screen and (min-width: 1520px) and (max-width: 1620px) {
    .CaresitesettingsviewButtonWrapper {
        width: 170px;
    }
    .CaresitesettingsinviteButtonWrapper {
        width: 170px;
    }
}

@media screen and (min-width: 1621px) and (max-width: 1780px) {
    .CaresitesettingsviewButtonWrapper {
        width: 195px;
    }
    .CaresitesettingsinviteButtonWrapper {
        width: 195px;
    }
}
@media screen and (min-width: 1781px) and (max-width: 1920px) {
    .CaresitesettingsviewButtonWrapper {
        width: 225px;
    }
    .CaresitesettingsinviteButtonWrapper {
        width: 225px;
    }
}
@media screen and (min-width: 900px) and (max-width: 1120px) {
    .CaresitesettingsviewButtonWrapper {
        width: 225px;
    }
    .CaresitesettingsinviteButtonWrapper {
        width: 225px;
    }
}