.leftSide {
  /* height: 500px; */
  background-color: white;
  /* padding: 82px 90px 82px 90px; */
  width: 50%;
}
.leftSide .text {
  color: #3d446f;
  text-align: center;
  font-size: 32px;
  font-weight: 800;
  font-family: "Roboto";
}
.rightSide {
  width: 50%;
  /* padding: 40px 0px 115px 0px; */
  background-color: #f0f2f4;
}

.wrapperContainer {
  width: 100%;
}

.image {
  width: 50%;
  height: 55vh;
  padding-top: 52px;
  box-sizing: border-box;
}

@media (max-width: 1024px) {
  .image {
    width: 70%;
    height: 40vh;
    padding-top: 40px;
  }
}

@media (max-width: 768px) {
  .image {
    width: 90%;
    height: 30vh;
    padding-top: 30px;
  }
}

@media (max-width: 480px) {
  .image {
    width: 100%;
    height: auto;
    padding-top: 20px;
  }
}
.imgWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.rightSide .text_1 {
  display: flex;
  justify-content: center;
}
.innerText {
  font-size: 72px;
  text-align: center;
  font-weight: bold;
  color: #6a768f;
  font-family: "Roboto";
}
.otp-time {
  display: flex;
  justify-content: center;
  align-items: center;
}
.otp-time p {
  color: #3d446f;
  text-align: center;
  font-size: 12px;
  font-weight: 800;
  font-family: "Roboto";
}
.otp-time span {
  color: #f26930;
}
.innerText2 {
  font-size: 72px;
  color: #f26930;
}
.Upload-csv1 {
  margin-right: 66px;
}
.backbutton {
  padding-top: 80px;
  padding-left: 30px;
  width: 80px;
  height: 80px;
  justify-self: left;
}
.innerText3 {
  padding-top: 60px;
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  color: #3d446f;
  font-family: "Roboto";
}
.emailInput {
  /* width: 100%; */
  width: 320px;
  border-radius: 5px;
  border-color: #3d446f;
  filter: drop-shadow(0px 0px 3px #6a768f80);
}
.passwordInput {
  width: 320px;
  border-radius: 5px;
  border-color: #3d446f;
  filter: drop-shadow(0px 0px 3px #6a768f80);
}
.inputs {
  /* padding: 15px 240px;
   */
  display: flex;
  justify-content: center;
  font-family: "Roboto";
}
.inputs2 {
  display: flex;
  justify-content: center;
}
.labaleforSingnIn {
  font-size: 16px;
  color: #3d446f;
  font-weight: 800;
  font-family: "Roboto";
}

.signInEmailAddress {
  font-family: "Roboto";
  font-weight: 800;
  color: #6a768f;
  padding-bottom: 10px;
}
.where1 {
  padding-top: 10px;
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  color: #3d446f;
  font-family: "Roboto";
  padding-bottom: 10px;
}

.signInEmailPwd {
  margin-top: 10px;
  font-family: "Roboto";
  font-weight: 800;
  color: #6a768f;
  padding-bottom: 10px;
}
/* .signInEmailPwd {
  position: relative;
} */
.passwrdHideIcon {
  justify-content: flex-end;
  display: flex;
  padding: 15px;
  margin-top: -46px;
  outline: none;
}
.passwrdHideIconforchangepwd {
  justify-content: flex-end;
  display: flex;
  padding: 15px;
  padding-right: 65px;
  margin-top: -46px;
  outline: none;
}
.passwrdHideIconforchangepwd button {
  outline: none;
}
.passwrdHideIcon button {
  outline: none;
}
.labels {
  text-align: center;
  padding-top: 15px;
}
.forgotouter {
  color: #f26930;
  font-size: 16px;
  font-family: "Roboto";
  text-align: center;
  padding-left: 26%;
}
.nextButtonnnn {
  background: white 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 10px #6a768f38;
  border-radius: 8px;
  opacity: 1;
  display: flex;
  justify-content: center;
  padding: 10px;
  width: 100%;
  /* width: 100%;
  margin-left: 40px;
  margin-right: 40px; */
  /* margin: 16px 0px; */
}
.nextButtonnnn1 {
  background: white 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 10px #6a768f38;
  border-radius: 8px;
  opacity: 1;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  width: 80%;
  height: 35px;
  align-items: center;
  /* width: 100%;
  margin-left: 40px;
  margin-right: 40px; */
  /* margin: 16px 0px; */
}
.next {
  text-align: center;
  color: #3d446f;
  font-weight: bold;
  cursor: pointer;
}
.textinimageforgot {
  text-align: center;
  color: #9c9c9c;
  font-family: "Roboto";
  cursor: pointer;
}
.account {
  text-align: center;
  color: #f26930;
}
.otpmsg {
  color: #3d446f;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  margin: 10px 0px;
}
.InputOpt {
  display: flex;
  justify-content: center;
  margin-top: 32px;
  margin-bottom: 32px;
}
.nextButtonnnnOuter {
  display: flex;
  justify-content: center;

  margin-top: 15px;
  margin-bottom: 16px;
  margin-left: 120px;
  margin-right: 120px;
}

.loginbuttoninforgot {
  display: flex;
  justify-content: center;
  margin-top: 5px;
  margin-bottom: 16px;
  margin-left: 120px;
  margin-right: 120px;
}
.LoginLeftContentWrapper {
  color: #3d446f;
  text-align: center;
  font-weight: 800;
  font-family: "Roboto";
  font-size: 32px;
}
.emailInputLogin {
  width: 100%;
  border-color: transparent;
  border-radius: 8px;
  opacity: 1;
  border: none;
  background-color: white !important;
  box-shadow: #6a768f80 0px 3px 6px !important;
  font-family: "Roboto";
  font-weight: 500;
  color: #6a768f;
}
.phoneInputforgot {
  width: 100%;
  border-color: transparent;
  border-radius: 8px;
  opacity: 1;
  height: 40px;
  border: none;
  background-color: white !important;
  box-shadow: #6a768f80 0px 3px 6px !important;
  font-family: "Roboto";
  font-weight: 500;
  color: #6a768f;
}
.centeredLink {
  font-family: "Roboto";
  font-weight: 800;
  color: #f26930;
  text-decoration: underline;
  text-decoration-color: #f26930;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
}
.centeredButton {
  display: flex;
  justify-content: center;
}
.uploadcsvButton {
  padding: 7px;
  border-radius: 5px;
  background: #fff;
  box-shadow: #6a768f80 0px 3px 6px;
  font-family: "Roboto";
  font-weight: 500;
  outline: none;
}
.uploadcsvButton2 {
  color: #fff;
  padding: 7px;
  border-radius: 10px;
  background: #f26930;
  box-shadow: #6a768f80 0px 3px 6px;
  font-family: "Roboto";
  font-weight: 500;
  outline: none;
  height: 50px;
  width: 200px;
}
.pwdInputLogin {
  width: 100%;
  border-color: #3d446f;
  border-radius: 8px;
  opacity: 1;
  border: none;
  background-color: white !important;
  box-shadow: #6a768f80 0px 3px 6px !important;
}
.inputFieldHeightWidthAdjustment {
  margin-top: 80px;
  margin-left: 120px;
  margin-right: 120px;
}
.inputFieldHeightWidthAdjustment11 {
  margin-top: 20px;
  margin-left: 120px;
  margin-right: 120px;
}
.otpMsg {
  color: #3d446f;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  margin: 10px 0px;
}

@media only screen and (max-width: 768px) {
  .leftSide .text {
    font-size: 24px;
  }
  .Upload-csv1 {
    margin-top: 110px;
    margin-right: 20px;
  }
}
@media only screen and (max-width: 1024px) {
  .leftSide {
    width: 100%;
  }

  .rightSide {
    width: 100%;
    height: auto;
  }
}
.eye {
  position: absolute;
  /* z-index: 1;
  padding-top: 0px;
  padding-left: 422px;
  padding-bottom: 0px;
  margin-top: -60px; */
  /* right: 20px;
  top: 501px;
  left: 419px; */
  margin-left: -33px;
  margin-top: -16px;
  width: 19px;
}
.eyeoff {
  position: absolute;
  /* z-index: 1;
  padding-top: 0px;
  padding-left: 422px;
  padding-bottom: 0px;
  margin-top: -60px; */
  /* right: 20px;
  top: 501px;
  left: 419px; */
  margin-left: -33px;
  margin-top: -16px;
  width: 18px;
}
@media only screen and (max-width: 1920px) {
  .rightSide {
    width: 100%;
  }
}

@media only screen and (min-height: 700px) {
  .rightSide {
    width: 100%;
    height: 95vh;
  }
}

@media only screen and (max-width: 1440px) {
  .rightSide {
    width: 100%;
  }
}
@media only screen and (max-width: 500px) {
  .inputFieldHeightWidthAdjustment {
    margin-top: 32px;
    margin-left: 40px;
    margin-right: 40px;
  }
}

@media only screen and (max-width: 500px) {
  .nextButtonnnnOuter {
    margin-top: 32px;
    margin-left: 40px;
    margin-right: 40px;
  }
  .nextButtonnnn {
    width: 100%;
  }
}
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}
