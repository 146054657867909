.mainTable {
    width: 100%;
    border : 1px solid #6A768F80; 
    border-collapse: collapse;
    overflow-x: auto;
}

.tableRow {    
    border : 1px solid #6A768F80; 
    border-collapse: collapse;
    overflow-x: auto;
}

.tabelHeadingImg {
    width : '10%';
    border : 1px solid #6A768F80; 
    border-collapse: collapse;
    text-align: center;

}

.tabelHeading1{
    width : '12%';
    border : 1px solid #6A768F80; 
    border-collapse: collapse;
}

.tableHeadingtop{
    text-align: center;
    font-family: 'Roboto';
    font-weight: 800;
    /* font-size: 16px; */
    color : #3D446F;  
    padding : 10px;
}

.nodatafoundWrapper{
    text-align: center;
    font-family: 'Roboto';
    font-weight: 500;
    /* font-size: 16px; */
    color : #3D446F;  
    padding-top: 10px;
    padding-bottom: 10px;
}

.paginationPatientTableSummaryArchive{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .downloadWrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    color: #6A768F;
    font-family: "Roboto";
    font-weight: 500;
    padding-left: 0px;
    /* cursor: pointer; */
    /* text-decoration: underline ; */
  }

  .PaginationForPatienttableNotiSummary {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 30px;
  }

  .totalCountsAtPatientProfileTableNotifiSummary {
    color: #f26930;
    /* font-size: 8px; */
    font-family: "Roboto";
    font-weight: 400;
    text-align: right;
    margin-top: 16px;
    margin-right: 0px;
  }