/* .notificationCardBgImageWrapper{
    background-image: url("../../assets/img/notifiactionSummaryCardImage.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 300px;
    height: 190px;
} */
.descriptionwrapper{
    color: #3D446F;
    /* font-size: 22px; */
    font-weight: bold;
    /* margin-top: 30px; */
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
}

.TherapyAdjustmentsdescriptionWrapper{
  color: #3D446F;
  /* font-size: 22px; */
  font-weight: bold;
  margin-top: -25px;
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
}

.radio_button_notification_summary{
    margin-left : 40px;
    margin-right : 40px;
    margin-top : 61px;
    margin-bottom: 9px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }
  .offtextWrapper{
    color: #6A768F;
    font-weight: bold;
    font-style: 'Roboto';
    opacity: 54%;
  }
  .ontextWrapper{
    color: #36434B;
    font-weight: bold;
    font-style: 'Roboto';
    opacity: 100%;
  }
  .MuiSlider-track{    
    color: #f26930 !important;
      border: 5px solid #f26930 !important;
      margin-top: -8px;
      opacity: 1.38 !important;
  }
  
  .MuiSlider-thumb{
      color : white; 
      
   }

  .MuiSlider-rail {
    color: lightgray !important;
    border: 5px solid lightgray !important;
    margin-top: -8px;
    opacity: 1.38 !important;
  }

  .MuiSlider-valueLabel.MuiSlider-valueLabelOpen {
      background: white;
      color: #F26930;
      z-index: 1;
      box-shadow: 0px 0px 4px 0px #8a8e95 !important;
      padding: 3px 5px;
      border-radius: 5px;
      /* margin-left: -20px !important; */
  }

  .notificationSummaryCardImg{
    background-image: url("../../assets/img/notifiactionSummaryCardImage.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 340px;
    height: 190px;
    
}

@media screen and (max-width: 1115px ){
  .TherapyAdjustmentsdescriptionWrapper{
    color: #3D446F;
    /* font-size: 21px; */
    font-weight: bold;
    margin-top: -25px;
    display: flex;
    justify-content: center;
    margin-bottom: 26px;
  }
  
  .radio_button_notification_summary{
      margin-left : 40px;
      margin-right : 40px;
      margin-top : 32px;
      margin-bottom: 24px;
    }

    /* .descriptionWrapper{
      font-size: 21px;
  } */
}

@media screen and (max-width: 1089px ){
  .TherapyAdjustmentsdescriptionWrapper{
    color: #3D446F;
    /* font-size: 20px; */
    font-weight: bold;
    margin-top: -25px;
    display: flex;
    justify-content: center;
    margin-bottom: 26px;
  }
  
  .radio_button_notification_summary{
      margin-left : 40px;
      margin-right : 40px;
      margin-top: 36px;
    margin-bottom: 20px;
    }

    /* .descriptionWrapper{
      font-size: 20px;
  } */
}

@media screen and (max-width: 1023px ){
  .MuiPaper-root.MuiCard-root.makeStyles-mycard-43.MuiPaper-outlined.MuiPaper-rounded{
    width: 400px;
    margin-left: 100px;
  }
}

@media screen and (max-width: 990px ){
  .MuiPaper-root.MuiCard-root.makeStyles-mycard-43.MuiPaper-outlined.MuiPaper-rounded{
    width: 400px;
    margin-left: 90px;
  }
}

@media screen and (max-width: 965px ){
  .MuiPaper-root.MuiCard-root.makeStyles-mycard-67.MuiPaper-outlined.MuiPaper-rounded{
    width: 400px;
    margin-left: 80px;
  }
}

@media screen and (max-width: 938px ){
  .MuiPaper-root.MuiCard-root.makeStyles-mycard-67.MuiPaper-outlined.MuiPaper-rounded{
    width: 400px;
    margin-left: 60px;
  }
}

@media screen and (max-width: 900px ){
  .MuiPaper-root.MuiCard-root.makeStyles-mycard-67.MuiPaper-outlined.MuiPaper-rounded{
    width: 400px;
    margin-left: 50px;
  }
}

@media screen and (max-width: 890px ){
  .MuiPaper-root.MuiCard-root.makeStyles-mycard-26.MuiPaper-outlined.MuiPaper-rounded{
    width: 400px;
    margin-left: 40px;
  }
}

@media screen and (max-width: 860px ){
  .MuiPaper-root.MuiCard-root.makeStyles-mycard-26.MuiPaper-outlined.MuiPaper-rounded{
    width: 400px;
    margin-left: 20px;
  }
}

@media screen and (max-width: 830px ){
  .MuiPaper-root.MuiCard-root.makeStyles-mycard-26.MuiPaper-outlined.MuiPaper-rounded{
    width: 400px;
    margin-left: 10px;
  }
}

@media screen and (max-width: 815px ){
  .MuiPaper-root.MuiCard-root.makeStyles-mycard-26.MuiPaper-outlined.MuiPaper-rounded{
    width: 400px;
    margin-left: 1px;
  }
}

@media screen and (max-width: 767px ){
  .MuiPaper-root.MuiCard-root.makeStyles-mycard-26.MuiPaper-outlined.MuiPaper-rounded{
    width: 400px;
    margin-left: 100px;
  }
}

@media screen and (max-width: 740px ){
  .MuiPaper-root.MuiCard-root.makeStyles-mycard-26.MuiPaper-outlined.MuiPaper-rounded{
    width: 400px;
    margin-left: 80px;
  }
}

@media screen and (max-width: 720px ){
  .MuiPaper-root.MuiCard-root.makeStyles-mycard-26.MuiPaper-outlined.MuiPaper-rounded{
    width: 400px;
    margin-left: 60px;
  }
}

@media screen and (max-width: 680px ){
  .MuiPaper-root.MuiCard-root.makeStyles-mycard-26.MuiPaper-outlined.MuiPaper-rounded{
    width: 400px;
    margin-left: 40px;
  }
}

@media screen and (max-width: 630px ){
  .MuiPaper-root.MuiCard-root.makeStyles-mycard-26.MuiPaper-outlined.MuiPaper-rounded{
    width: 400px;
    margin-left: 20px;
  }
}

@media screen and (max-width: 590px ){
  .MuiPaper-root.MuiCard-root.makeStyles-mycard-26.MuiPaper-outlined.MuiPaper-rounded{
    width: 400px;
    margin-left: 20px;
  }
}

@media screen and (max-width: 560px ){
  .MuiPaper-root.MuiCard-root.makeStyles-mycard-26.MuiPaper-outlined.MuiPaper-rounded{
    width: 400px;
    margin-left: -10px;
  }
}

@media screen and (max-width: 530px ){
  .MuiPaper-root.MuiCard-root.makeStyles-mycard-26.MuiPaper-outlined.MuiPaper-rounded{
    width: 400px;
    margin-left: -30px;
  }
}

@media screen and (max-width: 500px ){
  .MuiPaper-root.MuiCard-root.makeStyles-mycard-26.MuiPaper-outlined.MuiPaper-rounded{
    width: 400px;
    margin-left: -50px;
  }
}

@media screen and (max-width: 475px ){
  .MuiPaper-root.MuiCard-root.makeStyles-mycard-26.MuiPaper-outlined.MuiPaper-rounded{
    width: 400px;
    margin-left: -50px;
  }
}

@media screen and (max-width: 360px ){
  .MuiPaper-root.MuiCard-root.makeStyles-mycard-26.MuiPaper-outlined.MuiPaper-rounded{
    width: 300px;
    margin-left: -48px;
  }
}

@media screen and (max-width: 411px ){
  .MuiPaper-root.MuiCard-root.makeStyles-mycard-26.MuiPaper-outlined.MuiPaper-rounded{
    width: 340px;
    margin-left: -48px;
  }
}

@media screen and (max-width: 320px ){
  .MuiPaper-root.MuiCard-root.makeStyles-mycard-26.MuiPaper-outlined.MuiPaper-rounded{
    width: 260px;
    margin-left: -48px;
  }
}

@media screen and (max-width: 375px ){
  .MuiPaper-root.MuiCard-root.makeStyles-mycard-26.MuiPaper-outlined.MuiPaper-rounded{
    width: 320px;
    margin-left: -48px;
  }
}

@media screen and (max-width: 414px ){
  .MuiPaper-root.MuiCard-root.makeStyles-mycard-26.MuiPaper-outlined.MuiPaper-rounded{
    width: 345px;
    margin-left: -48px;
  }
}
@media screen and (max-width: 1059px ){
  .TherapyAdjustmentsdescriptionWrapper{
    color: #3D446F;
    /* font-size: 18px; */
    font-weight: bold;
    margin-top: -25px;
    display: flex;
    justify-content: center;
    margin-bottom: 26px;
  }
  
  .radio_button_notification_summary{
    margin-left: 28px;
    margin-right: 28px;
    margin-top: 42px;
    margin-bottom: 20px;
    }

    /* .descriptionWrapper{
      font-size: 18px;
  } */
}

@media screen and (max-width: 320px ){
  .TherapyAdjustmentsdescriptionWrapper{
    color: #3D446F;
    font-size: 16px;
    font-weight: bold;
    margin-top: -25px;
    display: flex;
    justify-content: center;
    margin-bottom: 26px;
  }
  
  .radio_button_notification_summary{
      margin-left : 40px;
      margin-right : 40px;
      margin-top : 61px;
      margin-bottom: 9px;
    }

    .descriptionwrapper{
      font-size: 16px;
  }
}


 @media screen and (max-width: 1023px ){
  .notifySummaryBlueBgImage{
    background-image: url('../../assets/img/leftside blue announcement card.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 631px;
    height: 215px;
  }
  .notifySummaryRedBgImage{
    background-image: url('../../assets/img/leftside red announcement card.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 631px;
    height: 215px;
  }
  .notifySummaryGreenBgImage{
    background-image: url('../../assets/img/leftside green announcement card.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 628px;
    height: 215px;
  }

  .sliderWrapper{
    padding: 0px 30px;
    width: 100%;
    margin-top: 102px;
    margin-bottom: 33px;
  }

  .radio_button_notification_summary{
    margin-left: 150px;
    margin-right: 150px;
    margin-top: 95px;
    margin-bottom: 62px;
  }
 }

 @media screen and (max-width: 935px ){
  .sliderWrapper{
    padding: 0px 30px;
    width: 100%;
  }
 }

 @media screen and (max-width: 855px ){
  .sliderWrapper{
    padding: 0px 30px;
    width: 100%;
  }
 }

 @media screen and (max-width: 805px ){
  .sliderWrapper{
    padding: 0px 30px;
    width: 100%;
  }
  .radio_button_notification_summary{
    margin-left: 120px;
    margin-right: 120px;
    margin-top: 95px;
    margin-bottom: 62px;
  }
 }

 @media screen and (max-width: 767px ){
  .sliderWrapper{
    padding: 0px 30px;
    width: 100%;
  }
 }

 @media screen and (max-width: 681px ){
  .sliderWrapper{
    padding: 0px 30px;
    width: 100%;
  }
 }

 @media screen and (max-width: 605px ){
  .sliderWrapper{
    padding: 0px 30px;
    width: 100%;
  }
 }

 @media screen and (max-width: 535px ){
  .sliderWrapper{
    padding: 0px 30px;
    width: 100%;
  }
 }

 @media screen and (max-width: 520px ){
  .notifySummaryGreenBgImage{
    height: 170px;
  }
  .radio_button_notification_summary{
    margin-left: 120px;
    margin-right: 120px;
    margin-top: 78px;
    margin-bottom: 37px;
  }
 }

 @media screen and (max-width: 490px ){
  .radio_button_notification_summary{
    margin-left: 90px;
    margin-right: 90px;
    margin-top: 85px;
    margin-bottom: 38px;
  }
 }
 @media screen and (max-width: 463px ){
  .sliderWrapper{
    padding: 0px 30px;
    width: 100%;
    margin-top: 75px;
  }
  .notifySummaryBlueBgImage{
    height: 176px;
  }
  .notifySummaryRedBgImage{
    height: 176px;
  }
  .notifySummaryGreenBgImage{
    height: 176px;
  }
 }

 @media screen and (max-width: 450px ){
  .notifySummaryGreenBgImage{
    height: 168px;
  }
  .radio_button_notification_summary{
    margin-left: 72px;
    margin-right: 72px;
    margin-top: 78px;
    margin-bottom: 30px;
  }
 }

 @media screen and (max-width: 440px ){
  .notifySummaryGreenBgImage{
    height: 164px;
  }
  .radio_button_notification_summary{
    margin-left: 72px;
    margin-right: 72px;
    margin-top: 78px;
    margin-bottom: 30px;
  }
 }

 @media screen and (max-width: 420px ){
  .notifySummaryGreenBgImage{
    height: 154px;
  }
  .radio_button_notification_summary{
    margin-left: 72px;
    margin-right: 72px;
    margin-top: 78px;
    margin-bottom: 30px;
  }
 }

 @media screen and (max-width: 400px ){
  .notifySummaryGreenBgImage{
    height: 142px;
  }
  .radio_button_notification_summary{
    margin-left: 60px;
    margin-right: 60px;
    margin-top: 68px;
    margin-bottom: 30px;
  }
 }

 @media screen and (max-width: 385px ){
  .sliderWrapper{
    padding: 0px 30px;
    width: 100%;
    margin-top: 63px;
    margin-bottom: 10px;
  }
  .notifySummaryBlueBgImage{
    height: 150px;
  }
  .notifySummaryRedBgImage{
    height: 150px;
  }
  .notifySummaryGreenBgImage{
    height: 135px;
  }
 }

 @media screen and (max-width: 365px ){
  .notifySummaryGreenBgImage{
    height: 124px;
  }
  .radio_button_notification_summary{
    margin-left: 32px;
    margin-right: 32px;
    margin-top: 46px;
    margin-bottom: 30px;
  }
 }

 @media screen and (max-width: 350px ){
  .notifySummaryGreenBgImage{
    height: 115px;
  }
  .radio_button_notification_summary{
    margin-left: 32px;
    margin-right: 32px;
    margin-top: 47px;
    margin-bottom: 23px;
  }
 }
 @media screen and (max-width: 340px ){
  .sliderWrapper{
    padding: 0px 30px;
    width: 100%;
    margin-top: 56px;
    margin-bottom: 1px;
  }
  .notifySummaryBlueBgImage{
    height: 128px;
  }
  .notifySummaryRedBgImage{
    height: 128px;
  }
  .notifySummaryGreenBgImage{
    height: 100px;
  }
  .radio_button_notification_summary{
    margin-left: 32px;
    margin-right: 32px;
    margin-top: 42px;
    margin-bottom: 10px;
  }
 }
@media screen and (min-width: 1024px ){
  .notifySummaryBlueBgImage{
    background-image: url('../../assets/img/leftside blue announcement card.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 488px;
    height: 103px;
  }

  .notifySummaryRedBgImage{
    background-image: url('../../assets/img/leftside red announcement card.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 488px;
    height: 103px;
  }
  .notifySummaryGreenBgImage{
    background-image: url('../../assets/img/leftside green announcement card.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 488px;
    height: 103px;
  }

  .sliderWrapper{
    padding: 0px 30px;
    width: 100%;
    margin-top: 45px;
  }

  .radio_button_notification_summary{
    margin-left : 32px;
    margin-right : 32px;
    margin-top : 34px;
    margin-bottom:25px;
  }
 }


@media screen and (min-width: 1280px ){
  .notifySummaryBlueBgImage{
    background-image: url('../../assets/img/leftside blue announcement card.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 488px;
    height: 148px;
  }
  .notifySummaryRedBgImage{
    background-image: url('../../assets/img/leftside red announcement card.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 488px;
    height: 148px;
  }
  .notifySummaryGreenBgImage{
    background-image: url('../../assets/img/leftside green announcement card.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 488px;
    height: 148px;
  }

  .sliderWrapper{
    padding: 0px 30px;
    width: 100%;
    margin-top: 64px;   
  }
  .radio_button_notification_summary{
    margin-left : 40px;
    margin-right : 40px;
    margin-top : 56px;
    margin-bottom: 30px;
  }
 }


@media screen and (min-width: 1440px ){
  .TherapyAdjustmentsdescriptionWrapper{
    margin-top: -11px;
  }

  .notifySummaryBlueBgImage{
    background-image: url('../../assets/img/leftside blue announcement card.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 488px;
    height: 177px;
  }
  .notifySummaryRedBgImage{
    background-image: url('../../assets/img/leftside red announcement card.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 488px;
    height: 177px;
  }
  .notifySummaryGreenBgImage{
    background-image: url('../../assets/img/leftside green announcement card.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 488px;
    height: 177px;
  }
  .sliderWrapper{
    padding: 0px 30px;
    width: 100%;
    margin-top: 80px;
  }
  .radio_button_notification_summary{
    margin-left : 68px;
    margin-right : 68px;
    margin-top : 72px;
    margin-bottom: 28px;
  }
 }

@media screen and (min-width: 1920px ){
  .TherapyAdjustmentsdescriptionWrapper{
    margin-top: -8px;
  }
  .notifySummaryBlueBgImage{
    background-image: url('../../assets/img/leftside blue announcement card.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 488px;
    height: 260px;
  }

  .notifySummaryRedBgImage{
    background-image: url('../../assets/img/leftside red announcement card.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 488px;
    height: 260px;
  }

  .notifySummaryGreenBgImage{
    background-image: url('../../assets/img/leftside green announcement card.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 488px;
    height: 260px;
  }
  
  .sliderWrapper{
    padding: 0px 30px;
    width: 100%;
    margin-top: 118px;
  }

  .descriptionwrapper{
    margin-top: 32px;
  }

  .radio_button_notification_summary{
    margin-left : 140px;
    margin-right : 140px;
    margin-top : 106px;
    margin-bottom: 58px;
  }
 }

 

 

 
