.dashboardsubcardwapper{
    display: flex;
    justify-content: space-evenly;
    border-radius: 8px;
    background-image: url('../../assets/img/notifiactionSummaryCardImage.png');
    background-size: cover;
    background-position: center;
    /* width: 513px; */
    height: 160px;
    /* margin-left: 5.5px; */
   /* margin-top: 44px; */
}

.dashboardSubcardimageWrapper{
  
    width: 100px;
        height: 107px;
        margin-bottom: 16px;
}
.dashboardSubcardimageWrapperforaddpatients{
    width: 116px;
    height: 145px;
    margin-left: 177px;
    padding-top: 192px;
}

.dashboardSubcardDescription{
    /* font-size: 16px; */
    color: #6A768F;
    font-weight: 500;
    margin-top: 24px;
    margin-left: 8px;
}

.dashboardSubcardrolecount{
    padding-top: 10px;
    font-size: 50px;
    color: #F26930;
    font-weight: 600;
}


