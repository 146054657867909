.invitationButtonWrpper{
    display:flex;
    flex-direction: column;
    justify-content:space-between;
    margin-top: 12px;
}

.searchbarWrapper{
padding-right: 10px;
width: 50%;
margin-left: 48px;
}
.filterWrapper{
margin-left: "15px";
}

@media screen and (max-width: 463px ){
.searchbarWrapper{
  padding-right: 10px;
   width: 90%;
}
}
.incomingInvitationWrapper{
display: flex;
}


@media screen and (max-width: 406px ){
.searchbarWrapper{
  padding-right: 10px;
   width: 90%;
}
}

@media screen and (max-width: 364px ){
.searchbarWrapper{
  padding-right: 10px;
   width: 90%;
}
}

@media screen and (max-width: 330px ){
.searchbarWrapper{
  padding-right: 10px;
   width: 90%;
}
}

@media screen and (max-width: 767px ){
.invitationButtonWrpper{
  margin-top: 88px;
}

}