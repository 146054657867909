.new-adherence-elements-week-wrapper .progressElementRowWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  /* padding-left: 12px;
    padding-right: 12px; */
  padding: 0px;
}

.new-adherence-elements-week-wrapper .progressElement {
  height: 68px;
  margin-right: 0px;
  /* flex-grow: 1;
    position: relative; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* flex-direction: column; */
}

.new-adherence-elements-week-wrapper .progressElementOne {
  height: 68px;
  margin-right: 0px;
  /* flex-grow: 1;
    position: relative; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* flex-direction: column; */
}

.new-adherence-elements-week-wrapper .progressElement:last-child {
  margin-right: 0;
}

.new-adherence-elements-week-wrapper .progress-outer {
  height: 68px;
  width: 29px;
  text-align: center;
  background-color: #d5d5d5;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  border-radius: 46px 50px 9px;
}

.new-adherence-elements-week-wrapper .progress-inner {
  width: 100%;
  min-height: 0px;
  background: #f26930; /* #ff7154; */
  /* background: linear-gradient(
        180deg
        , #f8ecec 0%, rgb(233 252 255) 1%, #f2682e 100%); */
  position: absolute;
  bottom: 0;
  box-sizing: border-box;
  border-radius: 46px 50px 9px;
}

.new-adherence-elements-week-wrapper .day-date-text-wrapper {
  /* position: absolute;
    width: 32px;
    display: inline-block;
    line-height: 21px;
    margin-top: 8px;
    white-space: pre;
    width: 100%; */
  /* color: rgba(156, 156, 156, 1); */
  color: #a5acbc;
  font-weight: 800;
  font-family: "Roboto";
  text-align: center;
  font-size: 12px;
  margin-top: 6px;
  /* position: absolute;
    bottom: -31px; */
}

.new-adherence-elements-week-wrapper .calendarLayoutWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* overflow-x: auto; */
}

.new-adherence-elements-week-wrapper .calendarLayout {
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 890px) {
  .new-adherence-elements-week-wrapper .progress-outer {
    width: 25px;
  }
}

@media screen and (max-width: 809px) {
  .new-adherence-elements-week-wrapper .progress-outer {
    width: 22px;
  }
}

@media screen and (max-width: 524px) {
  .new-adherence-elements-week-wrapper .progress-outer {
    width: 20px;
  }
}

@media screen and (max-width: 481px) {
  .new-adherence-elements-week-wrapper .progress-outer {
    width: 16px;
  }
}

@media screen and (max-width: 446px) {
  .new-adherence-elements-week-wrapper .progress-outer {
    width: 13px;
  }
}

.todaysSocreDateAndTime{
  font-weight: 500;
  font-family: 'Roboto';
  color: #3d446f;
  font-size: 10px;
}
.ArrowCircleLeftIconWrapper{
  font-size: 10px; 
}

.ArrowCircleLeftIconWrapper:hover{
  cursor: pointer;
}
