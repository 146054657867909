.containers_medic{
    background-color:#F2F7F8;    
    width: 100%;
}
.dialog-permed{
    justify-content: center;
    
}
.medical-response{
    display : flex;
    justify-content: space-between;
    padding-bottom: 40px;
    
}
.Medical-title{
    font-family: 'Roboto';
    color: #3D446F;
    font-size: 24px;
    
    font-weight: 800;
    /* margin-right: 500px; */
    margin-left: 80px;
    margin-top: 40px;
    
}

.iconForCancel{
    margin-right: 80px;
    cursor: pointer;
    margin-top: 40px;
}
.medic-back{
    background-image: url('../../assets/img/userprofilediv/newBlueDesign.png');
    background-repeat: no-repeat;
    background-size: 500px 500px;
    background-position: right top;   
}
.MedicalConditionInput{
    margin-left: 80px;
    margin-right: 80px;
    margin-bottom: 80px;
    
}
.medicalname{
   
        border: none;
    
        border-bottom: 1px solid;
        outline: none !important;
        font-family: 'Roboto';
        font-size: large;
        margin-right: 7px !important;
        width: 85%;
      
        border-color: #aaaaac;
    
        box-shadow: none;
        background-color: #f4f4fc !important;
        outline-offset: unset;
   
}
.medicalinformation_name{
    
        border: none;
         border-bottom: 1px solid;
         font-family: 'Roboto';
     
       outline: none !important;
        font-size: large;
        margin-right: -200px !important;
         width: 100%;
         padding-left: -20px !important;
        border-color: #aaaaac;
         box-shadow: none;
        background-color: #f4f4fc !important;
        outline-offset: unset;
    }
    .GenMedicalInfoDivs{
        font-size: 16px;
        font-family: "Roboto";
        font-weight: 800;
        color: #0c0c0c;
        border-bottom: 1px solid black;
        padding-top: 16px;        
    }

    @media screen  and (max-width:1024px){
        /* .Medical-title{
            margin-right: 100px;
        } */
        .SomeInput{
            width: 100%;
        }
        
    }
    .MuiDialog-paperWidthSm{
        max-width: 900px !important;
    }
